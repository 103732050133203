import {ComponentPropsWithoutRef} from 'react';

import {FlexBox, SxProp, Theme, Typography} from '@wanteddev/wds';

const headStyles: SxProp = (theme: Theme) => ({
  height: 62,
  backgroundColor: theme.palette.fill.alternative,
  padding: '12px 16px',
});

type MentoringViewMode = 'comments' | 'logs';

export interface MentoringCommentsHeaderProps {
  viewMode: MentoringViewMode;
  onChangeViewMode: (mode: MentoringViewMode) => void;
}

const activeStyle: ComponentPropsWithoutRef<typeof Typography> = {
  weight: 'bold',
  color: 'palette.label.normal',
};

const MentoringCommentsHeader = ({
  viewMode,
  onChangeViewMode,
}: MentoringCommentsHeaderProps) => {
  const onCommentsClick = () => onChangeViewMode('comments');
  const onLogsClick = () => onChangeViewMode('logs');

  return (
    <FlexBox gap="8px" sx={headStyles}>
      <Typography
        as="a"
        variant="label1_normal"
        weight="bold"
        color="palette.label.alternative"
        onClick={onCommentsClick}
        {...(viewMode === 'comments' && activeStyle)}
      >
        멘토링 코멘트
      </Typography>

      <Typography
        as="a"
        variant="label1_normal"
        weight="medium"
        color="palette.label.alternative"
        onClick={onLogsClick}
        {...(viewMode === 'logs' && activeStyle)}
      >
        로그
      </Typography>
    </FlexBox>
  );
};

export default MentoringCommentsHeader;

import React, {useEffect} from 'react';

function useClickOutside<T extends HTMLElement>(
  ref: React.MutableRefObject<T>,
  callback: () => void
) {
  useEffect(() => {
    if (ref.current === null) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useClickOutside;

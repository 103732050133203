import {Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Card} from '@/shared/ui/card';
import {ErrorMessage} from '@/shared/ui/error-message';
import {Loading} from '@/shared/ui/loading';
import {useQueryClient} from '@tanstack/react-query';

import {getPassnoteApplyHirePerPersonQueryKeys} from '../queries/usePassnoteApplyPerPersonQuery';

import PassnoteInfo from './PassnoteInfo';

import styles from './PassnoteInfo.module.scss';

const PassnoteInfoSuspense = () => {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();

  const onReset = () => {
    queryClient.resetQueries({
      queryKey: getPassnoteApplyHirePerPersonQueryKeys(),
    });
  };

  return (
    <Card className={styles.PassnoteInfo}>
      <ApiErrorBoundary fallback={ErrorMessage} onReset={onReset}>
        <Suspense fallback={<Loading />}>
          <PassnoteInfo key={user.institutionId} />
        </Suspense>
      </ApiErrorBoundary>
    </Card>
  );
};

export default PassnoteInfoSuspense;

import {AnchorHTMLAttributes, PropsWithChildren} from 'react';

import {SERVER_NAME} from '@/shared/constant';
import classNames from 'classnames';

import styles from './JobAnnouncementLink.module.scss';

interface JobAnnouncementLinkProps
  extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  wdId?: string | number;
}

const JobAnnouncementLink = ({
  wdId,
  onClick,
  children,
}: JobAnnouncementLinkProps) => {
  const link = `https://${SERVER_NAME}.wanted.co.kr/wd/${wdId}`;
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      aria-label="채용 공고 페이지로 이동"
      onClick={onClick}
      className={classNames({
        [styles.JobAnnouncementLink]: true,
        [styles.disabled]: !wdId,
      })}
    >
      {children}
    </a>
  );
};

export default JobAnnouncementLink;

import React, {MouseEventHandler, useEffect, useState} from 'react';

import {useIsPaidSubscriptionFee} from '@/shared/hooks/usePaidSubscriptionsFee';
import {weaver} from '@/shared/lib/weaver';
import {PaidGuideSimpleModal} from '@/widgets/paid-guide-modal';
import {
  FlexBox,
  Modal,
  ModalContainer,
  ModalContent,
  ModalContentItem,
  ModalNavigation,
} from '@wanteddev/wds';
import {useNavigate} from 'react-router-dom';

import GraduateHistoryTable from './components/GraduateHistoryTable';
import GraduateInfo from './components/GraduateInfo';
import MentoringContainer from './components/MentoringContainer';
import {useGetGraduateDetailSuspenseQuery} from './hooks/useGetGraduateDetailQuery';
import useGraduateDetailParams from './hooks/useGraduateDetailParams';

const GraduateDetailModal: React.FC = () => {
  const navigate = useNavigate();
  const isPaidSubscriptionFee = useIsPaidSubscriptionFee();
  const {graduateId, institutionId, courseId} = useGraduateDetailParams();
  const [openPaidGuideModal, setOpenPaidGuideModal] = useState(false);

  const handleClose = (open: boolean) => {
    if (!open) navigate(-1);
  };

  const handleOpenPaidGuideModal: MouseEventHandler = event => {
    if (!isPaidSubscriptionFee) {
      event.preventDefault();
      setOpenPaidGuideModal(true);
    }
  };

  const {data} = useGetGraduateDetailSuspenseQuery(
    institutionId,
    courseId,
    graduateId
  );

  useEffect(() => {
    weaver.sendEvent('eas__graduate__detailPopup__view');
  }, [graduateId, institutionId, courseId]);

  return (
    <>
      <Modal open onOpenChange={handleClose}>
        <ModalContainer
          size="huge"
          variant="popup"
          sx={{
            minWidth: '1180px',
            minHeight: '636px',
            '--wds-modal-navigation-padding': '41px 40px 18px 27px',
            '--wds-modal-content-margin': '40px',
          }}
        >
          <ModalNavigation variant="emphasized">
            취업활동 상세내역
          </ModalNavigation>

          <ModalContent sx={{paddingTop: 16, gap: 24}}>
            <GraduateInfo graduate={data} />

            <ModalContentItem>
              <FlexBox flexDirection="row" justifyContent="space-between">
                <GraduateHistoryTable
                  institutionId={institutionId}
                  graduate={data}
                  isPaidSubscriptionFee={isPaidSubscriptionFee}
                  openPaidGuideModal={handleOpenPaidGuideModal}
                />

                <MentoringContainer />
              </FlexBox>
            </ModalContentItem>
          </ModalContent>
        </ModalContainer>
      </Modal>

      <PaidGuideSimpleModal
        open={openPaidGuideModal}
        onClose={() => setOpenPaidGuideModal(false)}
      />
    </>
  );
};

export default GraduateDetailModal;

export const RouteUrl = {
  HOME: '/', // HOME
  LOGIN: '/login', // 로그인
  TERMS_AGREE: '/terms/agree', // 기업담당자 약관동의
  TERMS_AGREE_GRADUATE: '/graduate/terms/agree', // 수료생 약관동의

  // 수료생 교육 신청 페이지(URL 절대 변경되면 안됨)
  GRADUATE_COURSE: '/graduate/course/:code', // 수료생 교육 신청 페이지
  GRADUATE_COURSE_APPLY: '/graduate/course/:code/apply', // 수료생 교육 신청 페이지

  // 교육기관 대시보드
  DASHBOARD: '/dashboard', // 대시보드
  SUPREME_HOME: '/dashboard/supremehome', // 최상위 대시보드
  PASSNOTE: '/dashboard/passnote', // 기관별 대시보드
  BY_INSTITUTION: '/dashboard/institution', // 기관별 대시보드
  BY_COURSE: '/dashboard/course', // 코스별 대시보드
  BY_GRADUATES: '/dashboard/graduate', // 수료생별 대시보드
  BY_GRADUATES_DETAIL: '/dashboard/graduate/:graduateId', // 수료생 상세화면
  COURSE_MANAGE: '/dashboard/courses/manage', // 코스 생성 및 관리 페이지
};

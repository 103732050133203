import getCourseGraduateDetail from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {useSuspenseQuery} from '@tanstack/react-query';

export const getGraduateDetailQueryKey = (
  institutionId?: string,
  courseId?: string,
  graduateId?: string
) => {
  if (!institutionId || !courseId || !graduateId) {
    return ['GET_GRADUATE_DETAIL'];
  }
  return ['GET_GRADUATE_DETAIL', institutionId, courseId, graduateId];
};

export const useGetGraduateDetailSuspenseQuery = (
  institutionId: string,
  courseId: string,
  graduateId: string
) =>
  useSuspenseQuery({
    queryKey: getGraduateDetailQueryKey(institutionId, courseId, graduateId),
    queryFn: ({signal}) =>
      getCourseGraduateDetail({institutionId, courseId, graduateId}, {signal}),
  });

import classNames from 'classnames';

import {Loading} from '../loading';

import styles from './card.module.scss';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
}

export const Card: React.FC<CardProps> = ({
  children,
  className,
  loading = false,
}) => {
  return (
    <div className={classNames(styles.Card, className)}>
      {loading ? <Loading /> : children}
    </div>
  );
};

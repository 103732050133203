import React, {PropsWithChildren} from 'react';

import Course from '@/pages/Dashboard/Course';
import CoursesManage from '@/pages/Dashboard/CoursesManage';
import Graduate from '@/pages/Dashboard/Graduate';
import GraduateDetailModal from '@/pages/Dashboard/GraduateDetail';
import Institution from '@/pages/Dashboard/Institution';
import PassNote from '@/pages/Dashboard/Passnote/Passnote';
import SupremeHome from '@/pages/Dashboard/SupremeHome';
import GraduateCourseApply from '@/pages/GraduateCourseApply/CourseApply';
import CourseApplyCompleteModal from '@/pages/GraduateCourseApply/CourseApplyCompleteModal';
import CourseApplyModal from '@/pages/GraduateCourseApply/CourseApplyModal';
import Home from '@/pages/Home';
import RegistrationInstitutionModal from '@/pages/Home/RegistrationInstitutionModal';
import Login from '@/pages/Login';
import NotFound from '@/pages/NotFound';
import TermsAgree from '@/pages/TermsAgree';
import TermsAgreeTrainee from '@/pages/TermsAgreeTrainee';
import {RouteUrl} from '@/shared/constant';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {InstitutionType} from '@/shared/queries/useGetManagerUserSuspenseQuery';
import Message from '@/shared/ui/message';
import {DefaultLayout, DashboardLayout} from '@/widgets/layout';
import * as Sentry from '@sentry/react';
import {Navigate, Route, Routes} from 'react-router-dom';

function hasAllUserRoles(
  userRoles: string[],
  requiredRoles: string[]
): boolean {
  return requiredRoles.every(role => userRoles.includes(role));
}

function ProtectedRoute({
  children,
  roles,
}: PropsWithChildren<{roles: InstitutionType[]}>): React.ReactElement {
  const {user} = useCurrentUserQuery();

  return hasAllUserRoles(user.roles, roles) ? (
    <>{children}</>
  ) : (
    <Message
      message={
        <div>
          <p>해당 페이지에 접근 권한이 없습니다.</p>
          <p>로그인한 계정을 확인해 주세요. </p>
          <p>지속해서 문제가 있을 시 원티드 담당자에게 연락해 주세요.</p>
        </div>
      }
    />
  );
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      {/* Default Layout Pages */}
      <Route path="/" element={<DefaultLayout />}>
        <Route path={RouteUrl.HOME} element={<Home />}>
          <Route
            path="submit/institution"
            element={<RegistrationInstitutionModal />}
          />
        </Route>
      </Route>

      {/* No Layout Pages */}
      <Route path={RouteUrl.LOGIN} element={<Login />} />
      <Route path={RouteUrl.TERMS_AGREE} element={<TermsAgree />} />
      <Route
        path={RouteUrl.TERMS_AGREE_GRADUATE}
        element={<TermsAgreeTrainee />}
      />
      <Route path={RouteUrl.GRADUATE_COURSE} element={<GraduateCourseApply />}>
        <Route path="apply" element={<CourseApplyModal />} />
        <Route path="complete" element={<CourseApplyCompleteModal />} />
      </Route>

      {/* Dashboard Layout Pages */}
      <Route path={RouteUrl.DASHBOARD} element={<DashboardLayout />}>
        <Route
          path={RouteUrl.SUPREME_HOME}
          element={
            <ProtectedRoute roles={[InstitutionType.TOP_LEVEL]}>
              <SupremeHome />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteUrl.PASSNOTE}
          element={
            <ProtectedRoute roles={[InstitutionType.NORMAL]}>
              <PassNote />
            </ProtectedRoute>
          }
        >
          <Route path=":graduateId" element={<GraduateDetailModal />} />
        </Route>
        <Route
          path={RouteUrl.BY_INSTITUTION}
          element={
            <ProtectedRoute roles={[InstitutionType.NORMAL]}>
              <Institution />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteUrl.BY_COURSE}
          element={
            <ProtectedRoute roles={[InstitutionType.NORMAL]}>
              <Course />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteUrl.BY_GRADUATES}
          element={
            <ProtectedRoute roles={[InstitutionType.NORMAL]}>
              <Graduate />
            </ProtectedRoute>
          }
        >
          <Route path=":graduateId" element={<GraduateDetailModal />} />
        </Route>
        <Route
          path={RouteUrl.COURSE_MANAGE}
          element={
            <ProtectedRoute roles={[InstitutionType.NORMAL]}>
              <CoursesManage />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* Error Pages */}
      <Route path="*" element={<NotFound />} />

      {/* Legacy Pages Redirect */}
      <Route
        path="/supremehome"
        element={<Navigate to={RouteUrl.SUPREME_HOME} replace />}
      />
      <Route
        path="/home"
        element={<Navigate to={RouteUrl.BY_INSTITUTION} replace />}
      />
      <Route
        path="/courses"
        element={<Navigate to={RouteUrl.BY_COURSE} replace />}
      />
      <Route
        path="/users"
        element={<Navigate to={RouteUrl.BY_GRADUATES} replace />}
      />
      <Route
        path="/course/create"
        element={<Navigate to={RouteUrl.COURSE_MANAGE} replace />}
      />
    </SentryRoutes>
  );
};

export default AppRoutes;

import {lazy, Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Loading} from '@/shared/ui/loading';

const CourseFilter = lazy(() => import('./CourseFilter'));

export default function SuspensedCourseFilter() {
  const {user} = useCurrentUserQuery();

  return (
    <Suspense fallback={<Loading />}>
      <CourseFilter key={user.institutionId} />
    </Suspense>
  );
}

import {getData} from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';

export type MonthlyCounts = {
  /** 년월(YYYYMM) */
  yearMonth: number;
  /** 수료생 수 */
  graduateCount: number;
  /** 이력서 지원 수 */
  applyCount: number;
  /** 이력서 합격 */
  applyPassCount: number;
  /** 면접 합격 수 */
  hiredCount: number;

  courseId: number;
};

export type CourseMonthlyAggregate = {
  /** 코스 ID */
  courseId: number;
  /** 코스명 */
  courseName: string;
  /** 코스 시작일 */
  startDate: string;
  /** 코스 종료일 */
  endDate: string;
  /** 코스 취업 모니터링 종료일 */
  trackingEndDate: string;
  /** 코스 수료생 수 */
  graduateCount: number;
  /** 전체 이력서 지원 수 */
  applyCount: number;
  /** 전체 이력서 합격 */
  applyPassCount: number;
  /** 전체 면접 합격 수 */
  hiredCount: number;
  /** 코스 종료 후 주차수 */
  weeksAfterEnd: number;
  /** 최종합격까지 소요된 평균일수  */
  averageDaysToHired: number;
  /** 코스 월별 통계 데이터 */
  monthlyCounts: MonthlyCounts[];
  /** 구직자 수  */
  jobSeekerCount: number;
};

export function getCourseMonthlyAggregateQueryKeys(
  institutionId: number,
  courseIds?: string[]
) {
  const baseKeys = [
    RouteUrl.BY_COURSE,
    'COMPARISON_MONTHLY_AGGREGATE',
    institutionId,
  ];
  if (courseIds?.length) {
    return [
      ...baseKeys,
      ...[...courseIds].sort((a, b) => parseInt(a) - parseInt(b)),
    ];
  }
  return baseKeys;
}

export default function useGetCourseMonthlyAggregateQuery(
  institutionId: number,
  courseIds?: string[],
  options?: Omit<
    QueryObserverOptions<CourseMonthlyAggregate[]>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery({
    queryKey: getCourseMonthlyAggregateQueryKeys(institutionId, courseIds),
    queryFn: ({signal}) => {
      return getData(
        `/eas/dashboard/institution/${institutionId}/course/monthly`,
        {
          params: {courseIds},
          signal,
        }
      );
    },
    ...options,
  });
}

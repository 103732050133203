export const formatPhoneNumber = (phoneNumber: string) => {
  const results = phoneNumber?.replaceAll(' ', '')?.trim();
  if (!results) return results || '';

  // +821012345678 -> 010-1234-5678
  if (results.startsWith('+82') && /^\+82\d{10}$/.test(results)) {
    return results.replace(/(?:\+82)(\d{2})(\d{4})(\d{4})/, '0$1-$2-$3');
  }
  // +82 10-6599-3200 -> 010-6599-3200
  if (results.startsWith('+82')) {
    return results?.replace(/\+82\s*/, '0')?.trim();
  }
  // 01012345678 -> 010-1234-5678
  if (/^\d{10,11}/.test(results)) {
    return results.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  }
  return results;
};

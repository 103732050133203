import {useParams, useSearchParams} from 'react-router-dom';

interface GraduateDetailParams {
  graduateId: string;
  institutionId: string;
  courseId: string;
}

export default function useGraduateDetailParams(): GraduateDetailParams {
  const {graduateId} = useParams() || {};
  const [searchParams] = useSearchParams();
  const institutionId = searchParams.get('institutionId') || '';
  const courseId = searchParams.get('courseId') || '';

  return {graduateId, institutionId, courseId};
}

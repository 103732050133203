import classNames from 'classnames';

import styles from './table-body.module.scss';

type TableBodyProps = React.HTMLAttributes<HTMLTableSectionElement>;

export const TableBody: React.FC<TableBodyProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <tbody className={classNames(styles.TableBody, className)} {...rest}>
      {children}
    </tbody>
  );
};

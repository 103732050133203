import {lazy, Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Card} from '@/shared/ui/card';
import {ErrorMessage} from '@/shared/ui/error-message';
import {Loading} from '@/shared/ui/loading';
import {FallbackRender} from '@sentry/react';
import {useQueryClient} from '@tanstack/react-query';

import {useSelectedCourseIds} from '../../context';
import {getCourseMonthlyAggregateQueryKeys} from '../../queries/useGetCourseMonthlyAggregateQuery';

import styles from './CourseMonthlyAggregateChart.module.scss';

const LazyCourseMonthlyAggregateChart = lazy(
  () => import('./CourseMonthlyAggregateChart')
);

const FallbackLoading = ({size}: {size: number}) => (
  <>
    {Array.from({length: size}, (_, i) => (
      <Card key={i} className={styles.CourseMonthlyAggregateChart}>
        <Loading />
      </Card>
    ))}
  </>
);

const FallbackError: FallbackRender = props => (
  <Card className={styles.CourseMonthlyAggregateChart}>
    <ErrorMessage {...props} />
  </Card>
);

export default function CourseMonthlyAggregateChart() {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();
  const selectedCourseIds = useSelectedCourseIds();

  const onReset = () => {
    queryClient.resetQueries({
      queryKey: getCourseMonthlyAggregateQueryKeys(user.institutionId),
    });
  };

  return (
    <ApiErrorBoundary fallback={FallbackError} onReset={onReset}>
      <Suspense fallback={<FallbackLoading size={selectedCourseIds.length} />}>
        <LazyCourseMonthlyAggregateChart courseIds={selectedCourseIds} />
      </Suspense>
    </ApiErrorBoundary>
  );
}

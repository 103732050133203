import {CourseGraduateDetail} from '@/shared/api/courseGraduate/getCourseGraduateDetail';

export function generateGraduateApplicationStatusLists(
  data: CourseGraduateDetail
) {
  // 최종 취업 리스트
  const employedList = data.applyHistory.filter(
    item => item.isEmployed && item.companyName
  );

  // 면접 합격 리스트
  const interviewPassedList = data.applyHistory.filter(
    item => item.interviewPassTime !== null && item.companyName
  );

  // 이력서(서류) 합격 리스트
  const applyPassedList = data.applyHistory.filter(
    item => item.applyPassTime !== null && item.companyName
  );

  // 이력서 지원 리스트
  const applyList = data.applyHistory.filter(
    item => item.applyTime !== null && item.companyName
  );

  return {
    employedList,
    interviewPassedList,
    applyPassedList,
    applyList,
  };
}

import React from 'react';

import {Button} from '@/shared/ui/button';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {ModalTitle} from '@/shared/ui/modal-title';

import styles from './CompleteModal.module.scss';

type CompleteModalProps = React.ComponentProps<typeof Modal> & {
  title?: React.ReactNode;
  content?: React.ReactNode;
  onOk?: () => void;
};

const CompleteModal: React.FC<CompleteModalProps> = ({
  title,
  content,
  children,
  onClose,
  onOk,
  ...props
}) => {
  return (
    <Modal onClose={onClose} bodyClassName={styles.CompleteModal} {...props}>
      {title && <ModalTitle className={styles.title}>{title}</ModalTitle>}
      <ModalContent className={styles.content}>
        {content || children}
      </ModalContent>
      {(onOk || onClose) && (
        <ModalActions className={styles.actions}>
          <Button type="button" size="large" onClick={onOk || onClose}>
            확인
          </Button>
        </ModalActions>
      )}
    </Modal>
  );
};

export default CompleteModal;

import {useEffect} from 'react';

import {weaver} from '@/shared/lib/weaver';

import BriefStatistics from './components/BriefStatistics';
import CourseComparisonTable from './components/CourseComparisonTable';

const SupremeHome: React.FC = () => {
  useEffect(() => {
    weaver.sendEvent('eas__dashboard__view');
  }, []);

  return (
    <>
      <BriefStatistics />
      <CourseComparisonTable />
    </>
  );
};

export default SupremeHome;

import {Box, FlexBox, Theme, Typography} from '@wanteddev/wds';

interface TableHeaderColumnProps {
  title: string;
  count: number | null | undefined;
  subtitle?: string;
}

const thStyles = (theme: Theme) => ({
  height: 62,
  width: 200,
  padding: '12px 16px',
  backgroundColor: theme.palette.fill.alternative,
  '&:nth-of-type(3),&:nth-of-type(4)': {
    backgroundColor: theme.palette.blue[99],
  },
  '&:nth-child(n+2)': {
    borderLeftWidth: 1,
    borderColor: theme.palette.line.solid.neutral,
    borderStyle: 'solid',
  },
});

const subtitleStyles = (theme: Theme) => ({
  color: theme.palette.primary.normal,
});

export const TableHeaderColumn = ({
  title,
  count = 0,
  subtitle,
}: TableHeaderColumnProps) => {
  return (
    <Box as="th" role="columnheader" align="left" sx={thStyles}>
      <FlexBox justifyContent="space-between">
        <Typography variant="label1_normal" weight="bold">
          {title}
        </Typography>
        <Typography variant="label1_normal" weight="regular">
          {count}
        </Typography>
      </FlexBox>
      {subtitle && (
        <Typography variant="caption2" weight="medium" sx={subtitleStyles}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

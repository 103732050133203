import {Suspense, lazy} from 'react';

import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';

const Home = lazy(() => import('./Home'));

const SuspenseHome: React.FC = () => {
  return (
    <ApiErrorBoundary>
      <Suspense fallback={<Loading absoluteCenter />}>
        <Home />
      </Suspense>
    </ApiErrorBoundary>
  );
};

export default SuspenseHome;

import {AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef} from 'react';

import capitalize from '@/shared/utils/capitalize';
import classNames from 'classnames';

import styles from './button.module.scss';

type ElementType = HTMLButtonElement | HTMLAnchorElement;

interface BaseProps {
  children: React.ReactNode;
  className?: string;
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined' | 'text' | 'icon';
  component?: React.ElementType | string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  startIconClassName?: string;
  endIconClassName?: string;
  href?: string;
}

type ButtonAsButtonProps = BaseProps & ButtonHTMLAttributes<HTMLButtonElement>;
type ButtonAsAnchorProps = BaseProps & AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = ButtonAsButtonProps | ButtonAsAnchorProps;

export const Button = forwardRef<ElementType, ButtonProps>((props, ref) => {
  const {
    children,
    className,
    color,
    size = 'medium',
    component = 'button',
    disabled = false,
    fullWidth = false,
    variant = 'contained',
    startIconClassName,
    endIconClassName,
    startIcon: startIconProp,
    endIcon: endIconProp,
    ...other
  } = props;

  let ComponentProp = component ?? 'button';

  if (ComponentProp === 'button' && other.href) {
    ComponentProp = 'a';
  }

  const startIcon = startIconProp && (
    <span className={classNames(styles.Button__startIcon, startIconClassName)}>
      {startIconProp}
    </span>
  );
  const endIcon = endIconProp && (
    <span className={classNames(styles.Button__endIcon, endIconClassName)}>
      {endIconProp}
    </span>
  );

  const rootClasses = classNames(
    {
      [styles.Button__root]: true,
      [styles[`Button__${variant}`]]: true,
      [styles[`Button__${variant}${capitalize(color)}`] ?? '']: true,
      [styles[`Button__size${capitalize(size)}`]]: true,
      [styles.Button__fullWidth]: fullWidth,
    },
    className
  );

  return (
    <ComponentProp
      className={rootClasses}
      {...other}
      disabled={disabled}
      ref={ref}
    >
      <span className={styles.Button__label}>
        {startIcon}
        {children}
        {endIcon}
      </span>
    </ComponentProp>
  );
});

Button.displayName = 'Button';

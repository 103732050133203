import {getData} from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {useQuery, QueryObserverOptions} from '@tanstack/react-query';

export interface InstitutionBrief {
  /** 등록 수료생 수 */
  graduateCount: number;

  /** 구직자 수 */
  jobSeekerCount: number;

  /** 구직자 비율 */
  jobSeekerRate: number;

  /** 서류 지원수 */
  applyCount: number;

  /** 인당 평균 지원수 */
  averageApplyCountPerPerson: number;

  /** 이력서 합격수 */
  applyPassCount: number;

  /** 이력서 합격률 */
  applyPassRate: number;

  /** 면접 합격수 */
  hiredCount: number;

  /** 면접 합격률 */
  interviewPassRate: number;

  /** 취업자 수 */
  employedCount: number;

  /** 취업률 */
  employedRate: number;
}

export function getBriefQueryKeys(institutionId: number) {
  return [RouteUrl.BY_COURSE, 'BRIEF', institutionId];
}

/** 전체 대시보드 > 기관 통계 요약 조회 */
export default function useGetBriefQuery(
  institutionId: number,
  options?: Omit<QueryObserverOptions<InstitutionBrief>, 'queryKey'>
) {
  return useQuery({
    queryKey: getBriefQueryKeys(institutionId),
    queryFn: ({signal}) =>
      getData(`/eas/dashboard/institution/${institutionId}/brief`, {signal}),
    ...options,
  });
}

import {useState} from 'react';

import {IconSearch} from '@/shared/ui/icon';
import {Typography} from '@/shared/ui/typography';
import {FlexBox} from '@wanteddev/wds';
import classNames from 'classnames';

import {PaidGuideSimpleModal} from '../paid-guide-modal';

interface NopaidCompanyAccessButtonProps {
  companyName: string;
  className?: string;
}

export const NopaidCompanyAccessButton: React.FC<
  NopaidCompanyAccessButtonProps
> = ({companyName = '', className}) => {
  const [openPaidGuideModal, setOpenPaidGuideModal] = useState(false);

  const handleOpenPaidGuideModal = () => setOpenPaidGuideModal(true);
  const handleClosePaidGuideModal = () => setOpenPaidGuideModal(false);

  return (
    <>
      <button type="button" onClick={handleOpenPaidGuideModal}>
        <FlexBox>
          <IconSearch />
          <Typography
            variant="caption1"
            size="small"
            weight="bold"
            className={classNames('ml-1.5 underline', className)}
          >
            {companyName.replace(/(?<=.)./g, '*')}
          </Typography>
        </FlexBox>
      </button>
      <PaidGuideSimpleModal
        open={openPaidGuideModal}
        onClose={handleClosePaidGuideModal}
      />
    </>
  );
};

import {ReactElement, Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';

import {CourseProvider} from './context';
import {Courses} from './Courses';

export default function SuspenseCourses(): ReactElement {
  const {user} = useCurrentUserQuery();

  return (
    <ApiErrorBoundary>
      <Suspense fallback={<Loading absoluteCenter />}>
        <CourseProvider key={user?.institutionId}>
          <Courses />
        </CourseProvider>
      </Suspense>
    </ApiErrorBoundary>
  );
}

import {PaidGuideModal} from './paid-guide-modal';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const PaidGuideSimpleModal: React.FC<Props> = ({open, onClose}) => {
  return (
    <PaidGuideModal
      open={open}
      onClose={onClose}
      title={
        <>
          수료생 취업률 향상의 비결,
          <br />
          EAS PRO 버전에서 찾아보세요!
        </>
      }
      subtitle="EAS PRO버전을 통해 제공되는 상세 분석과 개인별 맞춤 취업 지원 서비스로 수료생의 취업 성공을 가속화하세요."
      messages={null}
    />
  );
};

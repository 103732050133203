import {Suspense} from 'react';

import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';
import {useQueryClient} from '@tanstack/react-query';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalContentItem,
  ModalNavigation,
} from '@wanteddev/wds';
import {useToast} from '@wanteddev/wds';
import {useNavigate} from 'react-router-dom';

import GraduateDetailModal from './GraduateDetailModal';
import {getGraduateDetailQueryKey} from './hooks/useGetGraduateDetailQuery';

const SuspenseUserDetailModal = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onError = () => {
    queryClient.removeQueries({queryKey: getGraduateDetailQueryKey()});
    toast({
      variant: 'warning',
      content: '조회에 실패하였습니다. 다시 시도해보세요.',
    });
    navigate(-1);
  };

  const fallback = (
    <Modal open>
      <ModalContainer
        size="huge"
        sx={{
          minWidth: '1180px',
          minHeight: '636px',
        }}
      >
        <ModalNavigation variant="emphasized">
          취업활동 상세내역
        </ModalNavigation>
        <ModalContent>
          <ModalContentItem>
            <Loading absoluteCenter />
          </ModalContentItem>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );

  return (
    <ApiErrorBoundary onError={onError}>
      <Suspense fallback={fallback}>
        <GraduateDetailModal />
      </Suspense>
    </ApiErrorBoundary>
  );
};

export default SuspenseUserDetailModal;

const GhostImage: React.FC = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="ghost-image"
    >
      <g opacity="0.5" clipPath="url(#clip0_56_870)">
        <ellipse
          cx="99.9988"
          cy="167.093"
          rx="41.4363"
          ry="7.34314"
          fill="#BAC3D2"
        />
        <g clipPath="url(#clip1_56_870)">
          <path
            d="M152.632 114.222C155.053 101.15 151.646 88.4389 150.958 81.6523C150.091 73.03 152.124 66.8553 148.986 53.5324C145.399 38.2347 133.982 25.9688 116.617 25.9688C116.557 25.9688 116.497 25.9688 116.407 25.9688C116.348 25.9688 116.288 25.9688 116.198 25.9688C98.8331 25.9688 85.5627 37.9566 81.7669 53.2264C80.5115 58.2886 79.8839 66.3546 78.6884 72.4737C76.1777 85.3238 72.5613 93.3342 66.4043 102.958C62.9671 108.354 61.0842 111.274 56.9297 115.808C51.9384 121.287 44.1375 128.63 47.0965 135.528C49.6669 141.508 59.2909 143.956 65.8364 143.956C72.3819 143.956 76.6261 141.87 79.854 140.006C81.3783 139.116 84.2177 137.67 87.0272 140.089C89.3585 142.092 92.1381 143.956 99.0722 143.956C106.006 143.956 108.188 141.647 111.805 139.06C114.106 137.447 116.975 137.197 119.097 139.311C121.399 141.591 124.985 143.594 128.751 143.594C134.281 143.594 139.511 140.98 142.47 138.143C148.896 131.94 150.869 123.54 152.602 114.167L152.632 114.222Z"
            fill="url(#paint0_radial_56_870)"
            fillOpacity="0.5"
          />
          <path
            d="M118.647 50.8463C129.918 103.319 94.6675 142.314 72.3628 154.895C39.3358 169.511 53.7841 137.929 68.4066 121.555C83.0292 105.181 89.113 88.7958 96.1513 64.5305C101.782 45.1184 113.494 47.3193 118.647 50.8463Z"
            fill="url(#paint1_radial_56_870)"
            fillOpacity="0.2"
          />
          <path
            d="M151.006 50.8463C162.277 103.319 127.027 142.314 104.722 154.895C71.6952 169.511 86.1434 137.929 100.766 121.555C115.389 105.181 121.472 88.7958 128.511 64.5305C134.141 45.1184 145.854 47.3193 151.006 50.8463Z"
            fill="url(#paint2_radial_56_870)"
            fillOpacity="0.2"
          />
          <path
            d="M108.49 56.0058C109.865 55.8946 110.881 54.6986 110.761 53.3357C110.642 51.9728 109.386 50.9715 108.012 51.1106C106.637 51.2218 105.62 52.4178 105.74 53.7807C105.86 55.1436 107.115 56.1449 108.49 56.0058Z"
            fill="#25282D"
          />
          <path
            d="M123.431 56.0058C124.806 55.8946 125.822 54.6986 125.703 53.3357C125.583 51.9728 124.328 50.9715 122.953 51.1106C121.578 51.2218 120.562 52.4178 120.681 53.7807C120.801 55.1436 122.056 56.1449 123.431 56.0058Z"
            fill="#25282D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M120.652 61.6757H110.789V60.1447H120.652V61.6757Z"
            fill="#25282D"
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_56_870"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(111.538 84.7446) rotate(107.571) scale(66.0216 92.2021)"
        >
          <stop offset="0.148574" stopColor="#60C6FF" stopOpacity="0" />
          <stop offset="0.80611" stopColor="#60C6FF" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_56_870"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(93.6722 108.51) rotate(-75.0628) scale(32.7365 10.7476)"
        >
          <stop stopColor="#60C6FF" />
          <stop offset="1" stopColor="#60C6FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_56_870"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(126.032 108.51) rotate(-75.0628) scale(32.7365 10.7476)"
        >
          <stop stopColor="#60C6FF" />
          <stop offset="1" stopColor="#60C6FF" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_56_870">
          <rect width="200" height="200" fill="white" />
        </clipPath>
        <clipPath id="clip1_56_870">
          <rect
            width="107"
            height="118.015"
            fill="white"
            transform="translate(46.5 26)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GhostImage;

import {CourseGraduateDetail} from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {useQueryClient} from '@tanstack/react-query';
import {Box, FlexBox, SxProp, Typography, css} from '@wanteddev/wds';
import dayjs from 'dayjs';

import {getGraduateDetailQueryKey} from '../../hooks/useGetGraduateDetailQuery';
import useGraduateDetailParams from '../../hooks/useGraduateDetailParams';

const actionTypeMap: ReadonlyMap<string, string> = new Map([
  ['WRITE', '작성'],
  ['EDIT', '수정'],
  ['DELETE', '삭제'],
]);

const boxStyles: SxProp = {
  flex: 1,
  padding: '7px 0',
  overflowY: 'auto',
};

const MentoringLogs = () => {
  const queryClient = useQueryClient();
  const {graduateId, institutionId, courseId} = useGraduateDetailParams();
  const graduate = queryClient.getQueryData<CourseGraduateDetail>(
    getGraduateDetailQueryKey(institutionId, courseId, graduateId)
  );

  return (
    <Box sx={boxStyles}>
      {graduate?.commentActionLogs?.map(log => {
        return (
          <FlexBox
            key={log.actionType + log.actorName + log.createdAt}
            flexDirection="column"
            gap="2px"
            sx={{padding: '8px 16px'}}
          >
            <Typography
              variant="caption2"
              weight="regular"
              color="palette.label.neutral"
            >
              {dayjs(`${log.createdAt}Z`).format('YYYY-MM-DD HH:mm')}
            </Typography>
            <Typography
              variant="caption1"
              weight="medium"
              color="palette.label.neutral"
            >
              {log.actorName}님이 코멘트를{' '}
              {actionTypeMap.get(log.actionType) || ''}
              하였습니다.
            </Typography>
            <FlexBox
              alignItems="center"
              sx={theme => css`
                position: relative;
                padding-left: 8px;
                margin-top: 4px;

                &:before {
                  display: block;
                  content: '';
                  position: absolute;
                  left: 0;
                  width: 3px;
                  height: 100%;
                  border-radius: 9px;
                  background-color: ${theme.palette.fill.strong};
                }
              `}
            >
              <Typography
                variant="caption1"
                weight="regular"
                color="palette.label.alternative"
              >
                {log.comment}
              </Typography>
            </FlexBox>
          </FlexBox>
        );
      })}
    </Box>
  );
};

export default MentoringLogs;

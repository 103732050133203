import {useState} from 'react';

type SortOrder = 'asc' | 'desc';
type Comparable = string | number | Date;

function useSortedData<T>(sortKey: keyof T, initialSort: SortOrder) {
  const [sort, setSort] = useState<SortOrder>(initialSort);

  const toggleSort = () => {
    setSort(prev => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const compareValues = (a: Comparable, b: Comparable): number => {
    if (a instanceof Date && b instanceof Date) {
      return a.getTime() - b.getTime();
    }
    return a < b ? -1 : 1;
  };

  const getSortedData = (data: T[]): T[] => {
    return [...data].sort((a, b) => {
      const aValue = a[sortKey] as Comparable;
      const bValue = b[sortKey] as Comparable;

      const comparison = compareValues(aValue, bValue);
      return sort === 'asc' ? comparison : -comparison;
    });
  };

  return {sort, toggleSort, getSortedData};
}

export default useSortedData;

export const IconSearch: React.FC = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66658 1.40002C3.75787 1.40002 1.3999 3.75799 1.3999 6.66669C1.3999 9.57539 3.75787 11.9334 6.66658 11.9334C7.90478 11.9334 9.04318 11.5061 9.94238 10.7909L13.2424 14.0909C13.4767 14.3252 13.8566 14.3252 14.0909 14.0909C14.3253 13.8566 14.3253 13.4767 14.0909 13.2424L10.7909 9.94235C11.506 9.04318 11.9332 7.90483 11.9332 6.66669C11.9332 3.75799 9.57528 1.40002 6.66658 1.40002ZM2.5999 6.66669C2.5999 4.42073 4.42062 2.60002 6.66658 2.60002C8.91254 2.60002 10.7332 4.42073 10.7332 6.66669C10.7332 8.91265 8.91254 10.7334 6.66658 10.7334C4.42062 10.7334 2.5999 8.91265 2.5999 6.66669Z"
        fill="#474747"
      />
    </svg>
  );
};

import React from 'react';

import classNames from 'classnames';

import styles from './input.module.scss';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Input: React.FC<InputProps> = React.forwardRef<
  HTMLInputElement,
  InputProps
>(({className, label, type, id, ...rest}, ref) => {
  return (
    <div className={styles.Input}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        ref={ref}
        id={id}
        type={type}
        className={classNames(styles.input, className)}
        {...rest}
      />
    </div>
  );
});

Input.displayName = 'Input';

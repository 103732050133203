import client from '@/shared/api/client';
import calculateTrackingEndDate from '@/shared/utils/calculateTrackingEndDate';
import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import {CreateCourseData} from './useCreateCourse';

const UPDATE_COURSE_ENDPOINT = (institutionId: number, courseId: number) =>
  `/eas/dashboard/institution/${institutionId}/course/${courseId}`;

export type UpdateCourseData = CreateCourseData;

const useUpdateCourse = (
  institutionId: number,
  courseId: number,
  options?: UseMutationOptions<
    AxiosResponse,
    unknown,
    UpdateCourseData,
    unknown
  >
) => {
  return useMutation({
    mutationFn: data =>
      client.put(UPDATE_COURSE_ENDPOINT(institutionId, courseId), {
        ...data,
        ...(data.endDate && {
          trackingEndDate: calculateTrackingEndDate(data.endDate),
        }),
      }),
    ...options,
  });
};

export default useUpdateCourse;

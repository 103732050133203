import styles from './PrivacySection.module.scss';

const PrivacySection: React.FC = () => {
  return (
    <section className={styles.PrivacySection}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h3 className={styles.title}>개인정보 제3자 제공 동의</h3>
          <h3 className={styles.title}>교육 및 취업 활동 정보 제공</h3>
          <div className={styles.text}>
            신청 완료 시 개인정보 제3자 제공 동의한 것으로 여깁니다.
          </div>
        </div>
        <div className={styles.contents}>
          <div>
            개인정보 제공 기관 : 소속 정부 및 교육 기관
            <br />
            개인정보 이용 목적 : 교육생의 취업 활동 현황 모니터링 및 취업 활동
            지원
            <br />
            개인정보 항목 : 이름, 연락처, 이메일, 취업 활동 현황(이력서 지원 ~
            최종 합격), 취업 회사명
            <br />
            개인정보 보유 및 이용 기간 : 교육생이 참여한 교육 종료일로부터 1년
            뒤 파기
          </div>
          <div>
            * 위와 같은 개인정보 제3자 제공 동의에 거부하실 수 있으나 거부하실
            경우 취업지원시스템 등록이 어렵습니다.
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacySection;

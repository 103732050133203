import React from 'react';

import {BASE_URL} from '@/shared/constant';

import {Button} from '../button';

import styles from './unknown-error.module.scss';

interface Props {
  resetError?: () => void;
}

export const UnknownError: React.FC<Props> = ({resetError}) => {
  const handleRetry = () => {
    if (resetError) {
      resetError();
    } else {
      window.location.reload();
    }
  };

  return (
    <div className={styles.UnknownError}>
      <div className={styles.contents}>
        <img
          src={`${BASE_URL}images/img-500-error-cup.svg`}
          height={210}
          width={340}
          alt="Error"
        />
        <img
          src={`${BASE_URL}images/img-500-error-text_s.svg`}
          height={36}
          width={86}
          alt="Sorry"
          className={styles.warningTitle}
        />
        <p className={styles.message}>
          기술적인 이유로 서비스를 일시적으로 사용할 수 없습니다.
          <br />
          잠시 후 다시 시도 해주십시오
        </p>
        <Button
          className={styles.button}
          size="large"
          onClick={handleRetry}
          fullWidth
        >
          재시도
        </Button>
      </div>
    </div>
  );
};

/* eslint-disable react-hooks/rules-of-hooks */
import {SERVER_API_URL} from '@/shared/constant';
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import qs from 'qs';

import {useInterceptor} from './helper/useInterceptor';

const instance: AxiosInstance = axios.create({
  baseURL: SERVER_API_URL,
  timeout: 5 * 60 * 1000, // 5 minutes
  withCredentials: true,
  headers: {
    'X-WANTED-CLIENT': 'EAS_WEB',
  },
  paramsSerializer: {
    serialize: (params: Record<string, string | string[]>) => {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  },
});

export default useInterceptor(instance);

export function getData<T>(url: string, config?: AxiosRequestConfig) {
  return instance.get<T, AxiosResponse<T>>(url, config).then(r => r.data);
}

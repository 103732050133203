/* eslint-disable @typescript-eslint/no-explicit-any */
import {Checkbox} from '@wanteddev/wds';
import classNames from 'classnames';

import {Loading} from '../loading';

import {TableBody} from './table-body';
import {TableHead} from './table-head';

import styles from './table.module.scss';

export type Key = React.Key;

interface Column<RecordType = any> {
  key: string;
  name: string;
  fontWeight?: 'bold' | 'normal';
  render?: (value: any, record: RecordType, index: number) => React.ReactNode;
  align?: 'left' | 'center' | 'right';
}
interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  onChange?: (page: number) => void;
}

export interface TableRowSelection {
  selectedRowKeys?: Key[];
  onChange?: (selectedRowKeys: Key[]) => void;
}

export type DefaultRecordType = Record<string, any>;

interface TableProps<RecordType = any> {
  columns: Column<RecordType>[];
  data?: RecordType[];
  loading?: boolean;
  pagination?: Pagination;
  rowKey: string;
  rowSelection?: TableRowSelection;
  emptyText?: string;
}

export function Table<RecordType extends DefaultRecordType>({
  columns,
  data,
  pagination,
  rowKey,
  rowSelection,
  loading,
  emptyText,
}: Readonly<TableProps<RecordType>>): React.ReactElement {
  const isEmpty = !loading && !data?.length;
  const totalPages = Math.ceil(
    (pagination?.total || 1) / (pagination?.pageSize || 1)
  );

  const isAllChecked =
    Boolean(data?.length) &&
    rowSelection?.selectedRowKeys?.length === data?.length;
  const onCheckAllChange = (checked: boolean) => {
    if (checked) {
      rowSelection?.onChange?.(data?.map(d => d[rowKey]));
    } else {
      rowSelection?.onChange?.([]);
    }
  };

  return (
    <div className={styles.Table}>
      <table>
        <TableHead>
          <th className={styles.checkboxColumn}>
            <Checkbox
              checked={isAllChecked}
              disabled={!data?.length}
              onCheckedChange={onCheckAllChange}
            />
          </th>
          {columns.map(column => (
            <th key={column.key} align={column.align}>
              {column.name}
            </th>
          ))}
        </TableHead>
        <TableBody>
          {loading && (
            <tr>
              <td colSpan={columns.length + 1} className={styles.loading}>
                <Loading />
              </td>
            </tr>
          )}
          {isEmpty && (
            <tr>
              <td colSpan={columns.length + 1} className={styles.empty}>
                {emptyText}
              </td>
            </tr>
          )}
          {!loading &&
            data?.map(item => (
              <tr key={item[rowKey]}>
                <td className={styles.checkboxColumn}>
                  <Checkbox
                    key={item[rowKey]}
                    checked={rowSelection?.selectedRowKeys?.includes(
                      item[rowKey]
                    )}
                    onCheckedChange={checked => {
                      if (checked) {
                        rowSelection?.onChange?.([
                          ...(rowSelection?.selectedRowKeys ?? []),
                          item[rowKey],
                        ]);
                      } else {
                        rowSelection?.onChange?.(
                          rowSelection?.selectedRowKeys?.filter(
                            key => key !== item[rowKey]
                          )
                        );
                      }
                    }}
                  />
                </td>
                {columns.map((column, index) => (
                  <td
                    key={column.key}
                    align={column.align}
                    className={classNames({
                      [styles.bold]: column.fontWeight === 'bold',
                    })}
                  >
                    {column.render
                      ? column.render(item[column.key], item, index)
                      : item?.[column.key]}
                  </td>
                ))}
              </tr>
            ))}
        </TableBody>
      </table>
      {pagination && (
        <div className={styles.pagination}>
          <button
            aria-label="이전 페이지"
            disabled={pagination.current === 1}
            className={styles.prev}
            onClick={() => {
              pagination.onChange?.(pagination.current - 1);
            }}
          >
            <svg width="12" height="12">
              <path
                fill="currentColor"
                d="M4.94067 6L8.66034 2.28033C8.95323 1.98744 8.95323 1.51256 8.66034 1.21967C8.36744 0.926777 7.89257 0.926777 7.59967 1.21967L3.34967 5.46967C3.05678 5.76256 3.05678 6.23744 3.34967 6.53033L7.59967 10.7803C7.89257 11.0732 8.36744 11.0732 8.66034 10.7803C8.95323 10.4874 8.95323 10.0126 8.66034 9.71967L4.94067 6Z"
              />
            </svg>
          </button>
          {Array.from({length: totalPages}, (_, index) => index + 1).map(
            page => (
              <button
                aria-label={`${page} 페이지`}
                key={page}
                className={pagination.current === page ? styles.active : ''}
                onClick={() => {
                  pagination.onChange?.(page);
                }}
              >
                {page}
              </button>
            )
          )}
          <button
            aria-label="다음 페이지"
            disabled={pagination.current === totalPages}
            className={styles.next}
            onClick={() => {
              pagination.onChange?.(pagination.current + 1);
            }}
          >
            <svg width="12" height="12">
              <path
                fill="currentColor"
                d="M3.34467 9.71967C3.05178 10.0126 3.05178 10.4874 3.34467 10.7803C3.63756 11.0732 4.11244 11.0732 4.40533 10.7803L8.65533 6.53033C8.94822 6.23744 8.94822 5.76256 8.65533 5.46967L4.40533 1.21967C4.11244 0.926777 3.63756 0.926777 3.34467 1.21967C3.05178 1.51256 3.05178 1.98744 3.34467 2.28033L7.06434 6L3.34467 9.71967Z"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}

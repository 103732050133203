import {useMemo, useRef, useState} from 'react';

import useClickOutside from '@/shared/hooks/useClickOutside';
import useParentScroll from '@/shared/hooks/useParentScroll';
import {weaver} from '@/shared/lib/weaver';
import useSearchCompanyNamesQuery from '@/shared/queries/useSearchCompanyNamesQuery';
import classNames from 'classnames';

import {EllipsisTooltip} from '../ellipsis-tooltip';
import {IconSearch} from '../icon';
import {Loading} from '../loading';

import styles from './search-compnay-names-button.module.scss';

type SearchCompanyNamesButtonProps = {
  wrapperClassName?: string;
  className?: string;
  institutionId: number;
  kreditjobId?: string;
  companyName: string;
  style?: React.CSSProperties;
};

export const SearchCompanyNamesButton: React.FC<
  SearchCompanyNamesButtonProps
> = ({
  wrapperClassName,
  className,
  institutionId,
  kreditjobId,
  companyName,
  style,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const {data, isLoading} = useSearchCompanyNamesQuery(
    institutionId,
    companyName,
    {
      enabled: isOpen,
    }
  );

  useClickOutside(ref, () => setIsOpen(false));
  useParentScroll(ref, () => setIsOpen(false));

  const onClickCompnayName = (open: boolean) => {
    setIsOpen(open);
    weaver.sendEvent('eas__graduate__hiredcompany__click');
  };

  const positionModalStyles = useMemo(() => {
    if (!isOpen || !ref.current) return {};

    const position = window
      .getComputedStyle(ref.current as Element)
      .getPropertyValue('position');
    if (position === 'relative') return {};

    // parents node의 overflow가 auto이면 안보이는 문제가 있어서
    return {
      top: ref.current.getBoundingClientRect().bottom + 2,
      left: ref.current.getBoundingClientRect().left + 22,
    };
  }, [isOpen]);

  if (kreditjobId) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://insight.wanted.co.kr/company/${kreditjobId}`}
        onClick={() => onClickCompnayName(false)}
        className={classNames(styles.SearchCompanyNamesButton, className)}
        style={style}
      >
        <IconSearch />
        <EllipsisTooltip
          className={styles.SearchCompanyNamesButton__tooltip}
          content={companyName}
        />
      </a>
    );
  }

  return (
    <div ref={ref} className={wrapperClassName} style={style}>
      <button
        type="button"
        aria-label="회사명 검색"
        onClick={() => onClickCompnayName(true)}
        className={classNames(styles.SearchCompanyNamesButton, className)}
        style={style}
      >
        <IconSearch />
        <EllipsisTooltip
          className={styles.SearchCompanyNamesButton__tooltip}
          content={companyName}
        />
      </button>
      {isOpen && (
        <div
          className={styles.SearchCompanyNamesButton__modal}
          style={positionModalStyles}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <ul className={styles.searchedCompanyNameList}>
              {data?.length === 0 && (
                <li className={styles.empty}>검색 결과가 없습니다.</li>
              )}
              {data?.map(({companyName: name, kreditjobId: id}) => (
                <li key={id}>
                  <a
                    href={`https://insight.wanted.co.kr/company/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

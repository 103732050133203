import {Suspense} from 'react';

import UnauthorizedError from '@/shared/api/errors/UnauthorizedError';
import {doOneIDlogin} from '@/shared/lib/oneId/oneId';
import {Button} from '@/shared/ui/button';
import {Loading} from '@/shared/ui/loading';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {ModalTitle} from '@/shared/ui/modal-title';
import {ErrorBoundary, FallbackRender} from '@sentry/react';
import {useNavigate} from 'react-router-dom';

import CourseApplyCompleteModal from './CourseApplyCompleteModal';

export default function SuspenseCourseApplyCompleteModal() {
  const navigate = useNavigate();
  const handleFallback: FallbackRender = ({error}) => {
    if (error instanceof UnauthorizedError) {
      doOneIDlogin('graduate'); // 로그인 페이지로 이동
      return null;
    }
    return (
      <Modal open>
        <ModalTitle>
          <h4>시스템 에러</h4>
        </ModalTitle>
        <ModalContent>
          <p>알 수 없는 오류가 발생했습니다.</p>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => navigate(-1)}>확인</Button>
        </ModalActions>
      </Modal>
    );
  };

  return (
    <ErrorBoundary fallback={handleFallback}>
      <Suspense fallback={<Loading />}>
        <CourseApplyCompleteModal />
      </Suspense>
    </ErrorBoundary>
  );
}

import {PropsWithChildren} from 'react';

import {FlexBox} from '@wanteddev/wds';

import NoteImage from '../../components/imageComponents/NoteImage';

import styles from './PassnoteInfoMessage.module.scss';

const PassnoteInfoMessage: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <>
      <NoteImage className={styles.image} />
      <FlexBox flexDirection="column">{children}</FlexBox>
    </>
  );
};

export default PassnoteInfoMessage;

import React, {useEffect} from 'react';

import {Box, SxProp} from '@wanteddev/wds';
import classNames from 'classnames';

import {Tooltip, type TooltipProps} from '../tooltip';

import styles from './ellipsis-tooltip.module.scss';

interface EllipsisTooltip extends TooltipProps {
  className?: string;
  style?: React.CSSProperties;
  sx?: SxProp;
}

export const EllipsisTooltip: React.FC<EllipsisTooltip> = ({
  content,
  placement,
  className,
  style,
  sx,
}) => {
  const ref = React.useRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = React.useState(false);

  useEffect(() => {
    const elementWidth = ref.current?.offsetWidth ?? 0; // 요소의 너비
    const textWidth = ref.current?.scrollWidth ?? 0; // 텍스트의 너비
    setIsOverflow(textWidth > elementWidth);

    // 텍스트가 너비를 넘어갔는지 확인
    if (textWidth > elementWidth) {
      ref.current.style.textOverflow = 'ellipsis';
      ref.current.style.whiteSpace = 'nowrap';
      ref.current.style.overflow = 'hidden';
    }
  }, [ref]);

  return (
    <Box
      as="div"
      ref={ref}
      className={classNames(styles.EllipsisTooltip, className)}
      style={style}
      sx={sx}
    >
      {isOverflow && (
        <Tooltip content={content} placement={placement}>
          <span>{content}</span>
        </Tooltip>
      )}
      {!isOverflow && <span>{content}</span>}
    </Box>
  );
};

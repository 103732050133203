import {type GraduateBrief} from '../../queries/useGetGraduateBriefQuery';

import {SortKey, SortValue} from './types';

export const sortOrder: Record<SortValue, (a: number, b: number) => number> = {
  desc: (a, b) => b - a,
  asc: (a, b) => a - b,
};

export const sortDirectionsInitialState: Record<SortKey, SortValue> = {
  applyCount: null,
  applyPassCount: null,
  applyPassRate: null,
  hiredCount: null,
  hiredRate: null,
  latestUpdatedDate: null,
};

export function getNoInfoMessage(graduate: GraduateBrief) {
  const isWithdrawalUser =
    !graduate.graduateName &&
    !graduate.graduateMobile &&
    !graduate.graduateEmail;

  if (isWithdrawalUser) {
    return '탈퇴, 휴면상태 유저로 정보 제공 불가합니다.';
  }

  if (!graduate.isWantedUser) {
    return '원티드 비회원으로 정보 제공 불가합니다.';
  }

  if (!graduate.isAgreePrivacy) {
    return '취업 활동 내역 제3자(기관) 제공 동의를 하지 않아 정보 제공이 불가합니다.';
  }

  return null;
}

import {AxiosRequestConfig} from 'axios';

import {getData} from '../client';

/** 코스 수료생 지원 이력 */
export interface CourseGraduateApplyHistory {
  /** WANTED 채용공고 아이디 */
  wdId: number | null;
  /** WANTED 서류 지원 고유 아이디 */
  applyId: number | null;
  /** WANTED를 통한 서류 제출시간 */
  applyTime: string | null;
  /** WANTED를 통한 서류 합격시간 */
  applyPassTime: string | null;
  /** WANTED를 통한 면접 합격시간 */
  interviewPassTime: string | null;
  /** 지원한 회사명 */
  companyName: string;
  /** 지원한 공고 */
  position: string | null;
  /** 취업여부 */
  isEmployed: boolean;
  /** KreditJob 고유 아이디 */
  kreditjobId: string | null;
}

/** 코스 수료생 멘토링 코멘트 */
export interface CourseGraduateComment {
  commentId: number;
  authorName: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
}

/** 코스 수료생 멘토링 코멘트 변경 이력 */
export interface CourseGraduateCommentActionLog {
  comment: string;
  actorName: string;
  actionType: string;
  createdAt: string;
}

/** 코스 수료생 취업활동 상세 */
export interface CourseGraduateDetail {
  /** 수료생 이름 */
  graduateName: string;
  /** 수료생 이메일주소 */
  graduateEmail: string;
  /** 수료생 연락처 */
  graduateMobile: string;
  /** 수료생 이력서 지원 횟수 */
  applyCount: number;
  /** 수료생 이력서 합격 횟수 */
  applyPassCount: number;
  /** 수료생 이력서 합격률 */
  applyPassRate: number;
  /** 수료생 면접 합격 횟수 */
  interviewPassCount: number;
  /** 수료생 면접 합격률 */
  interviewPassRate: number;
  /** 수료생 지원 이력 */
  applyHistory: CourseGraduateApplyHistory[];
  /** 수료행 취업 플랫폼(WANTED_NORMAL , ETC , UNKNOWN) */
  usedHrPlatform: string;
  /** 수료생 멘토링 코멘트 */
  comments: CourseGraduateComment[];
  /** 수료생 멘토링 코멘트 변경 이력 */
  commentActionLogs: CourseGraduateCommentActionLog[];
}

export type GetCourseGraduateDetailResponse = CourseGraduateDetail;

export interface GetCourseGraduateDetailParams {
  institutionId: string;
  courseId: string;
  graduateId: string;
}

/** 코스 수료생 취업활동 상세 조회 API */
const getCourseGraduateDetail = async (
  {institutionId, courseId, graduateId}: GetCourseGraduateDetailParams,
  config?: AxiosRequestConfig
) => {
  return getData<GetCourseGraduateDetailResponse>(
    `/eas/dashboard/institution/${institutionId}/course/${courseId}/graduate/${graduateId}/detail`,
    config
  );
};

export default getCourseGraduateDetail;

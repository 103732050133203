import {useState} from 'react';

import {FlexBox, SxProp, Theme} from '@wanteddev/wds';

import MentoringComments from '../MentoringComments';
import MentoringLogs from '../MentoringLogs';

import MentoringCommentsHeader from './MentoringHeader';

const boxStyles: SxProp = (theme: Theme) => ({
  width: 280,
  height: 400,
  borderWidth: 1,
  borderColor: theme.palette.line.solid.neutral,
  borderStyle: 'solid',
  borderRadius: 12,
  overflow: 'hidden',
  position: 'relative',
});

type MentoringViewMode = 'comments' | 'logs';

const MentoringContainer = () => {
  const [viewMode, setViewMode] = useState<MentoringViewMode>('comments');

  return (
    <FlexBox flexDirection="column" sx={boxStyles}>
      <MentoringCommentsHeader
        viewMode={viewMode}
        onChangeViewMode={setViewMode}
      />

      {viewMode === 'comments' && <MentoringComments />}

      {viewMode === 'logs' && <MentoringLogs />}
    </FlexBox>
  );
};

export default MentoringContainer;

import {Button} from '@/shared/ui/button';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {useQueryClient} from '@tanstack/react-query';

import useDeleteCourse from '../../queries/useDeleteCourse';
import {getCourseListQueryKey} from '../../queries/useGetCourseListQuery';

import styles from './CourseDeleteModal.module.scss';

type CourseDeleteModalProps = React.ComponentProps<typeof Modal> & {
  institutionId: number;
  courseIds: number[];
  onSuccess: () => void;
};

const CourseDeleteModal: React.FC<CourseDeleteModalProps> = ({
  open,
  institutionId,
  courseIds,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const deleteCourse = useDeleteCourse(institutionId);

  const handleDeleteCourse = () => {
    deleteCourse.mutate(courseIds, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getCourseListQueryKey(institutionId),
        });
        onClose(null);
        onSuccess();
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      bodyClassName={styles.CourseDeleteModal}
      disableBackdropClick={true}
    >
      <ModalContent className={styles.modalContent}>
        <div>
          코스 삭제 시 복구가 불가능하며,
          <br />
          해당 코스에 신청 완료한 수료생들의 취업 활동 내역
          <br />
          트레킹이 중단됩니다.
        </div>
        <div>
          <b>그래도 삭제하시겠습니까?</b>
        </div>
      </ModalContent>
      <ModalActions className={styles.modalActions}>
        <Button size="large" variant="outlined" onClick={onClose}>
          취소
        </Button>
        <Button
          size="large"
          onClick={handleDeleteCourse}
          disabled={deleteCourse.isPending || deleteCourse.isSuccess}
        >
          삭제
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default CourseDeleteModal;

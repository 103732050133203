import {getData} from '@/shared/api/client';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';

type CompanyData = {
  companyName: string;
  location: string;
  kreditjobId: string;
};

const useSearchCompanyNamesQuery = (
  institutionId: number,
  companyName: string = '',
  options?: Omit<UseQueryOptions<CompanyData[]>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: ['COMPANY_SEARCH', institutionId, companyName],
    queryFn: ({signal}) =>
      getData<CompanyData[]>(
        `/eas/dashboard/institution/${institutionId}/course/graduate/company/search?company_name=${companyName}`,
        {signal}
      ),
    ...options,
  });
};

export default useSearchCompanyNamesQuery;

export const IconChevronDown: React.FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.04023 4.04039C2.29408 3.78655 2.70563 3.78655 2.95948 4.04039L5.99987 7.08077L9.04025 4.04039C9.2941 3.78655 9.70565 3.78655 9.9595 4.04039C10.2133 4.29423 10.2133 4.70578 9.9595 4.95963L6.45949 8.45962C6.20564 8.71347 5.79409 8.71347 5.54024 8.45962L2.04023 4.95963C1.78639 4.70578 1.78639 4.29423 2.04023 4.04039Z"
        fill="#474747"
      />
    </svg>
  );
};

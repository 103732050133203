import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Typography} from '@wanteddev/wds';

import usePassnoteApplyHireListPerPersonQuery from '../queries/usePassnoteApplyPerPersonQuery';

import PassnoteInfoMessage from './components/PassnoteInfoMessage';

const PassnoteInfo = () => {
  const {user} = useCurrentUserQuery();
  const institutionId = user.institutionId;
  const institutionName = user.institutionNames.find(
    e => e.institutionId === institutionId
  ).name;

  const {data} = usePassnoteApplyHireListPerPersonQuery(institutionId, {
    suspense: true,
  });

  if (!data?.isThisInstitutionGraduateExist) {
    // case 2 : ‘해당 기관의 등록 수료생’이 없는 경우 : 수료생 등록 문구를 노출한다.
    return (
      <PassnoteInfoMessage>
        <Typography as="p" variant="headline1">
          등록된 수료생이 없습니다.
          <br />
          수료생을 등록시켜 취업 활동 내역을 쉽게 트래킹 해보세요.
        </Typography>
      </PassnoteInfoMessage>
    );
  }

  // case 1 : ‘1인당 평균 지원수’ 0인 경우 : 이력서 지원을 유도하는 문구를 노출한다.
  if (data?.thisInstitutionApplyCountPerPerson === 0) {
    return (
      <PassnoteInfoMessage>
        <Typography as="p" variant="headline1">
          이력서를 지원한 수료생이 없습니다.
          <br />
          수료생들에게 이력서 지원을 유도해 보세요.
        </Typography>
      </PassnoteInfoMessage>
    );
  }

  // case 3 : ‘해당 기관의 1인당 평균 지원수’가 ‘EAS에 등록된 기관들의 평균 1인당 지원수’ 미만인 경우 : 평균 미만임을 안내하고, 1인당 지원을 유도한다.
  if (
    data?.thisInstitutionApplyCountPerPerson <
    data?.allInstitutionApplyCountPerPerson
  ) {
    return (
      <PassnoteInfoMessage>
        <Typography as="p" variant="headline1" weight="bold">
          평균 1인당 지원 수가 낮은 편입니다. 수료생 1인당 지원 수를 올려보세요.
        </Typography>
        <Typography as="p" variant="headline1">
          👉 {institutionName}의 평균 1인당 지원 수는{' '}
          {data.thisInstitutionApplyCountPerPerson}건입니다.
        </Typography>
        <Typography as="p" variant="headline1">
          👉 원티드 취업지원시스템 등록된 기관들의 평균 1인당 지원수는{' '}
          {data.allInstitutionApplyCountPerPerson}
          건입니다.
        </Typography>
        <Typography as="p" variant="headline1">
          👉 원티드 데이터상 이력서 합격을 위해서는 평균 47.6건의 이력서를
          지원해야 합니다.
        </Typography>
      </PassnoteInfoMessage>
    );
  }

  // case 4 : ‘해당 기관의 1인당 평균 지원수’가 ‘EAS에 등록된 기관들의 평균 1인당 지원수’ 이상인 경우 : 평균 이상임을 안내하고, 면접 코칭을 유도한다.
  return (
    <PassnoteInfoMessage>
      <Typography as="p" variant="headline1" weight="bold">
        평균 1인당 지원 수가 높은 편입니다. 이력서 합격한 수료생을 대상으로 면접
        코칭을 진행해 보세요.
      </Typography>
      <Typography as="p" variant="headline1">
        👉 {institutionName}의 평균 1인당 지원 수는{' '}
        {data.thisInstitutionApplyCountPerPerson}건입니다.
      </Typography>
      <Typography as="p" variant="headline1">
        👉 원티드 취업지원시스템 등록된 기관들의 평균 1인당 지원수는{' '}
        {data.allInstitutionApplyCountPerPerson}건입니다.
      </Typography>
      <p>👉 면접 코칭을 통해 더 많은 최종 취업자를 배출해 보세요.</p>
    </PassnoteInfoMessage>
  );
};

export default PassnoteInfo;

import {useEffect} from 'react';

function getRootParent(element: HTMLElement | null): HTMLElement | null {
  if (window.getComputedStyle(element).overflowY === 'auto') return element;
  if (element?.parentElement) {
    return getRootParent(element.parentElement);
  }
  return element;
}

function useParentScroll<T>(
  ref: React.MutableRefObject<T>,
  callback: () => void
) {
  useEffect(() => {
    if (ref.current === null) return;
    const parent = getRootParent(ref.current as HTMLElement);
    if (parent) {
      parent.addEventListener('scroll', callback);
      return () => parent.removeEventListener('scroll', callback);
    }
  }, [ref, callback]);

  // Define getRootParent function or import it if it's already defined elsewhere
}

export default useParentScroll;

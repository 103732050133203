/* eslint-disable import/order */
import React, {useEffect} from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import '@wanteddev/wds/global.css';

import {App} from './app/App';

Sentry.init({
  dsn: 'https://7fe1546c7cbb463fb70c138bed8d350c@o40591.ingest.sentry.io/4504591042215936',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/eas-api\.wanted\.co\.kr/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Enable native crash handling
  debug: false,
  enabled: import.meta.env.MODE === 'www',
  environment: import.meta.env.MODE,
  ignoreErrors: ['UnauthorizedError'],
});

const Root =
  import.meta.env.MODE === 'development' ? React.StrictMode : React.Fragment;

ReactDOM.createRoot(document.getElementById('root')).render(
  <Root>
    <App />
  </Root>
);

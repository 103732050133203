import {AxiosRequestConfig} from 'axios';

import client from '../client';

export interface CreateCourseGraduateCommentParams {
  institutionId: string;
  courseId: string;
  graduateId: string;
  comment: string;
}

const createCourseGraduateComment = async (
  {
    institutionId,
    courseId,
    graduateId,
    comment,
  }: CreateCourseGraduateCommentParams,
  config?: AxiosRequestConfig
) => {
  await client.post(
    `/eas/dashboard/institution/${institutionId}/course/${courseId}/graduate/${graduateId}/detail/comment`,
    {
      comment,
    },
    config
  );
};

export default createCourseGraduateComment;

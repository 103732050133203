import {ReactNode} from 'react';

import styles from './PassnoteApplyEmpty.module.scss';

type Props = {
  image: ReactNode;
  message: string;
};

const PassnoteApplyEmpty: React.FC<Props> = ({
  image: emptyImage,
  message: emptyMessage,
}) => {
  return (
    <div className={styles.PassnoteApplyEmpty}>
      {emptyImage}
      <p>{emptyMessage}</p>
    </div>
  );
};

export default PassnoteApplyEmpty;

import {forwardRef} from 'react';

import classNames from 'classnames';

import styles from './modal-title.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const ModalTitle = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {children, className, ...other} = props;

  const rootClasses = classNames({
    [styles.ModalTitle__root]: true,
    [className || '']: className !== undefined,
  });

  return (
    <div className={rootClasses} ref={ref} {...other}>
      {children}
    </div>
  );
});

ModalTitle.displayName = 'ModalTitle';

export const IconShare: React.FC = () => (
  <svg width="80" height="80" viewBox="0 0 50 50" fill="#fff">
    <circle cx="25" cy="25" r="24.5" stroke="#D6D6D6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.98 17.845c0-1.861-1.472-3.371-3.287-3.371s-3.288 1.51-3.288 3.371l.01.259c.006.085.016.17.028.254l.01.052-8.057 4.361-.072-.083a3.395 3.395 0 00-2.48-1.073 3.385 3.385 0 00-3.39 3.378 3.384 3.384 0 003.39 3.378l.236-.008a3.393 3.393 0 002.22-1.04l.064-.074 8.761 4.74c.186.102.4.137.608.101l.027-.004.13-.032a.97.97 0 00.578-.512l.074-.138c.082-.133.186-.25.31-.35a1.34 1.34 0 011.915.24c.463.61.358 1.488-.234 1.962a1.34 1.34 0 01-1.914-.24l-.096-.11a.944.944 0 00-1.255-.062 1.004 1.004 0 00-.166 1.386 3.233 3.233 0 004.615.582c1.43-1.145 1.684-3.265.566-4.733a3.232 3.232 0 00-4.614-.581l-.193.166a3.542 3.542 0 00-.183.185l-8.678-4.695-.024-.02a.936.936 0 00-.228-.11l-.033-.01.076-.024a1.09 1.09 0 00.116-.055l9.501-5.141.17-.118.031-.026.104-.104c.253-.296.313-.723.141-1.085l-.06-.147a1.422 1.422 0 01-.08-.469c0-.771.611-1.398 1.364-1.398.752 0 1.363.627 1.363 1.398 0 .773-.61 1.398-1.363 1.398l-.13.01a.979.979 0 00-.832.977c0 .545.43.987.962.987 1.816 0 3.287-1.509 3.287-3.372zm-18.602 7.148c0-.766.649-1.404 1.466-1.404l.179.01a1.47 1.47 0 011.1.707l.033.064.021.059c.106.26.317.476.579.573l.043.014-.045.012a.956.956 0 00-.563.52l-.077.148a1.478 1.478 0 01-1.27.701c-.818 0-1.466-.637-1.466-1.404z"
      fill="#36F"
    />
  </svg>
);

import React from 'react';

import {Button} from '@/shared/ui/button';
import {Typography} from '@/shared/ui/typography';

import PassnoteApplyHireListDummy from '../PassnoteApplyHireList/PassnoteApplyHireListDummy';
import PassnoteApplyPassListDummy from '../PassnoteApplyPassList/PassnoteApplyPassListDummy';
import PassnoteInfoDummy from '../PassnoteInfo/PassnoteInfoDummy';

import styles from './PassnotePaidGuide.module.scss';

const PassnotePaidGuide: React.FC = () => {
  return (
    <div className={styles.PassnotePaidGuide}>
      <PassnoteInfoDummy />

      <div className={styles.PassnotePaidGuide__list}>
        <PassnoteApplyPassListDummy />
        <PassnoteApplyHireListDummy />
      </div>

      <div className={styles.PassnotePaidGuide__dimmed}>
        <div className={styles.PassnotePaidGuide__box}>
          <Typography variant="heading1" size="large">
            PRO(유료) 기능 안내
          </Typography>
          <div className={styles.PassnotePaidGuide__message}>
            <Typography variant="body1" size="small">
              합격 노트에서는 이런 정보를 제공해요.
            </Typography>
            <ul>
              <li>타 기관과 내 기관 수료생의 1인당 지원수 비교</li>
              <li>최근 7일 이력서 합격자</li>
              <li>최근 7일 최종 취업자</li>
            </ul>
            <Typography variant="body1" size="small">
              합격 노트 기능을 통해 수료생들의 취업 현황을 쉽게 관리해 보세요.
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            href="https://forms.gle/6joBhnmxdcWwk28C9"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.PassnotePaidGuide__box__button}
          >
            유료 전환 문의하기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PassnotePaidGuide;

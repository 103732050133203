import {CourseGraduateComment} from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {Box, SxProp} from '@wanteddev/wds';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useGraduateDetailParams from '../../../hooks/useGraduateDetailParams';

import MentoringCommentsListItem from './MentoringCommentsListItem';

dayjs.extend(utc);

const boxStyles: SxProp = {
  flex: 1,
  padding: '7px 0',
  overflowY: 'auto',
};

export interface MentoringCommentsProps {
  commentList: CourseGraduateComment[];
}

const MentoringCommentsList = ({commentList = []}: MentoringCommentsProps) => {
  const {graduateId, institutionId, courseId} = useGraduateDetailParams();

  return (
    <Box as="ul" sx={boxStyles}>
      {commentList.map(comment => (
        <MentoringCommentsListItem
          key={comment.commentId}
          institutionId={institutionId}
          courseId={courseId}
          graduateId={graduateId}
          comment={comment}
        />
      ))}
    </Box>
  );
};

export default MentoringCommentsList;

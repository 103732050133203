import dayjs from 'dayjs';
import Papa from 'papaparse';

import useCreateCourse from './useCreateCourse';

enum CourseColumn {
  COURSE_NAME = 'courseName',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export type CourseData = {[key in CourseColumn]: string};

export const parseCsv = (
  file: File,
  columns: string[] = Object.values(CourseColumn)
) => {
  return new Promise<CourseData[]>((resolve, reject) => {
    Papa.parse<CourseData>(file, {
      header: true,
      comments: false,
      skipEmptyLines: true,
      transformHeader: (_, index) => columns[index],
      transform: (value, field) =>
        ['startDate', 'endDate'].includes(String(field)) && value
          ? dayjs(value).format('YYYY-MM-DD')
          : value,
      complete: results => {
        if (results.errors.length > 0) {
          reject(results.errors);
        } else {
          resolve(results.data);
        }
      },
    });
  });
};

export const formatCourseData = (data: {[key: string]: string}) => {
  return {
    courseName: data[CourseColumn.COURSE_NAME],
    startDate: data[CourseColumn.START_DATE],
    endDate: data[CourseColumn.END_DATE],
  };
};

type Options = Parameters<typeof useCreateCourse>;

const useCreateCourseFromCsv = (...options: Options) => {
  const createCourse = useCreateCourse(...options);

  const upload = async (file: File) => {
    const data = await parseCsv(file);
    const courseData = data.map(formatCourseData);
    return createCourse.mutateAsync(courseData);
  };
  return upload;
};

export default useCreateCourseFromCsv;

import {getData} from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {useQuery, QueryObserverOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';

export interface Course {
  courseId: number;
  courseName: string;
  startDate: string;
  endDate: string;
  trackingEndDate: string;
  createdAt: string;
  graduateCount: number;
}

export interface GetCourseList {
  content: Course[];
  totalPages: number;
  totalElements: number;
}

export const getCourseListQueryKey = (
  institutionId: number,
  params?: {page: number}
) => {
  return [
    RouteUrl.COURSE_MANAGE,
    'GET_COURSE_LIST',
    institutionId,
    ...(params ? [params] : []),
  ];
};

const useGetCourseListQuery = (
  institutionId: number,
  page: number,
  options?: QueryObserverOptions<GetCourseList, AxiosError>
) => {
  return useQuery({
    queryKey: getCourseListQueryKey(institutionId, {page}),
    queryFn: () => {
      return getData<GetCourseList>(
        `/eas/dashboard/institution/${institutionId}/course/list`,
        {
          params: {
            page,
            size: 10,
            sort: 'id,desc',
          },
        }
      );
    },
    ...options,
  });
};

export default useGetCourseListQuery;

import {useState} from 'react';

import {Button} from '@/shared/ui/button';
import {Input} from '@/shared/ui/input';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {ModalTitle} from '@/shared/ui/modal-title';
import calculateTrackingEndDate from '@/shared/utils/calculateTrackingEndDate';
import {useQueryClient} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {useSearchParams} from 'react-router-dom';

import {getCourseListQueryKey} from '../../queries/useGetCourseListQuery';
import useUpdateCourse, {UpdateCourseData} from '../../queries/useUpdateCourse';

import styles from './CourseUpdateModal.module.scss';

export type CourseUpdateModalProps = React.ComponentProps<typeof Modal> & {
  courseDetails: {
    institutionId: number;
    courseId: number;
    courseName: string;
    startDate: string;
    endDate: string;
    createdAt: string;
  };
};

const CourseUpdateModal: React.FC<CourseUpdateModalProps> = ({
  open,
  onClose,
  courseDetails: {institutionId, courseId, courseName, startDate, endDate},
}) => {
  const [searchParam] = useSearchParams();
  const page = parseInt(searchParam.get('page')) || 1;
  const queryClient = useQueryClient();
  const updateCourse = useUpdateCourse(institutionId, courseId);

  const [courseDetails, setCourseDetails] = useState<UpdateCourseData>({
    courseName,
    startDate,
    endDate,
  });

  const handleInputChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCourseDetails(prevState => ({
        ...prevState,
        [name]: event.target.value,
      }));
    };

  const handleSave: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    updateCourse.mutate(courseDetails, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getCourseListQueryKey(institutionId, {page}),
        });
        onClose(null);
      },
    });
  };

  return (
    <Modal open={open} bodyClassName={styles.CourseUpdateModal}>
      <ModalTitle>
        <h2 className={styles.title}>코스 정보 수정</h2>
      </ModalTitle>
      <form onSubmit={handleSave}>
        <ModalContent className={styles.content}>
          <div className={styles.form}>
            <div className={styles.content__item}>
              <label htmlFor="courseName">코스명</label>
              <Input
                required
                id="courseName"
                name="courseName"
                placeholder="코스명을 입력해주세요"
                className={styles.inputCourseName}
                onChange={handleInputChange('courseName')}
                defaultValue={courseName}
              />
            </div>
            <div className={styles.content__item}>
              <label htmlFor="startDate">교육 시작일</label>
              <Input
                required
                type="date"
                name="startDate"
                className={styles.inputCoursePeriod}
                onChange={handleInputChange('startDate')}
                defaultValue={startDate}
              />
            </div>
            <div className={styles.content__item}>
              <label htmlFor="endDate">교육 종료일</label>
              <Input
                required
                type="date"
                name="endDate"
                className={styles.inputCoursePeriod}
                onChange={handleInputChange('endDate')}
                defaultValue={endDate}
              />
            </div>
          </div>
          <div className={styles.content__item}>
            <label>취업 트레킹 종료일</label>
            <span>{calculateTrackingEndDate(courseDetails.endDate)}</span>
          </div>
          <div className={styles.content__item}>
            <label>코스 등록일</label>
            <span>{dayjs().format('YYYY-MM-DD')}</span>
          </div>
        </ModalContent>
        <ModalActions className={styles.actions}>
          <Button
            type="button"
            size="large"
            variant="outlined"
            onClick={onClose}
          >
            취소
          </Button>
          <Button type="submit" size="large" disabled={updateCourse.isPending}>
            저장
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export default CourseUpdateModal;

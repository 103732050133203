import {getData} from '@/shared/api/client';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';

type PassnoteApplyPerPerson = {
  isThisInstitutionGraduateExist: boolean; // 해당 기관 수료생 존재유무
  thisInstitutionApplyCountPerPerson: number; // 해당 기관의 1인당 평균 서류 지원수
  allInstitutionApplyCountPerPerson: number; // 전체 기관의 1인당 평균 서류 지원수
};

export const getPassnoteApplyHirePerPersonQueryKeys = (
  institutionId?: number
) => ['PASSNOTE_APPLY_PER_PERSON', ...(institutionId ? [institutionId] : [])];

const usePassnoteApplyHireListPerPersonQuery = (
  institutionId: number,
  options?: Omit<QueryObserverOptions<PassnoteApplyPerPerson>, 'queryKey'>
) => {
  return useQuery({
    queryKey: getPassnoteApplyHirePerPersonQueryKeys(institutionId),
    queryFn: async ({signal}) =>
      getData<PassnoteApplyPerPerson>(
        `/eas/dashboard/institution/${institutionId}/passnote/apply/per-person`,
        {
          signal,
        }
      ),
    ...options,
  });
};

export default usePassnoteApplyHireListPerPersonQuery;

import {Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {ErrorMessage} from '@/shared/ui/error-message';
import {Loading} from '@/shared/ui/loading';
import {useQueryClient} from '@tanstack/react-query';

import PassnoteApplyList from '../components/PassnoteApplyList';
import {getPassnoteApplyHireListQueryKeys} from '../queries/usePassnoteApplyPassHireQuery';

import PassnoteApplyHireList from './PassnoteApplyHireList';

const PassnoteApplyHireListSuspense = () => {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();

  const onReset = () => {
    queryClient.resetQueries({queryKey: getPassnoteApplyHireListQueryKeys()});
  };

  return (
    <PassnoteApplyList title="최종 취업자 리스트">
      <ApiErrorBoundary fallback={ErrorMessage} onReset={onReset}>
        <Suspense fallback={<Loading />}>
          <PassnoteApplyHireList key={user.institutionId} />
        </Suspense>
      </ApiErrorBoundary>
    </PassnoteApplyList>
  );
};

export default PassnoteApplyHireListSuspense;

import {lazy, Suspense} from 'react';

import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';

const LazyTermsAgree = lazy(() => import('./TermsAgree'));

export default function TermsAgree() {
  return (
    <ApiErrorBoundary>
      <Suspense fallback={<Loading absoluteCenter />}>
        <LazyTermsAgree />
      </Suspense>
    </ApiErrorBoundary>
  );
}

import axios from 'axios';
import {Helmet} from 'react-helmet-async';

const isProd = location.hostname.startsWith('e');

export const WEAVER_URL = `https://${
  isProd ? 'data-www-weaver' : 'data-nw-weaver'
}.wanted.co.kr`;

export type TrackParams = {
  event?: string; // 이벤트명
  user_id?: unknown; // 로그인된 유저 user_id
  event_properties?: Record<string, string>; // 이벤트를 설명하는 정보
  user_properties?: Record<string, string>; // 유저를 설명하는 정보
  client_event_time: string; // 이벤트가 발생한 시간
  context: {
    service: string; // 서비스명
  };
};

function track(params: TrackParams) {
  return axios
    .post('/track', params, {
      withCredentials: true,
      baseURL: WEAVER_URL,
    })
    .catch(() => null);
}

class Weaver {
  private userId: number | null = null;
  private userProperties: Record<string, string> = {};

  setUserId(userId: number): void {
    this.userId = userId;
  }

  setUserProperties(userProperties: Record<string, string>): void {
    this.userProperties = {
      ...this.userProperties,
      ...userProperties,
    };
  }

  sendEvent(eventName: string, eventProperties: Record<string, string> = {}) {
    return track({
      event: eventName,
      user_id: this.userId?.toString(),
      client_event_time: new Date().toISOString(),
      event_properties: {
        pagePath: window.location.pathname,
        ...eventProperties,
      },
      user_properties: this.userProperties,
      context: {
        service: 'eas',
      },
    });
  }
}

export const weaver = new Weaver();

import React from 'react';

import {RouteUrl} from '@/shared/constant';
import {doOneIDlogin} from '@/shared/lib/oneId/oneId';
import {weaver} from '@/shared/lib/weaver';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Button} from '@/shared/ui/button';
import {useMatch, useNavigate} from 'react-router-dom';

import useCourseId from '../../queries/useCourseId';
import useGetGraduateApplyQuery from '../../queries/useGetGraduateApplyQuery';

import CourseInfo from './CourseInfo';

import styles from './StartedSection.module.scss';

const StartedSection: React.FC = () => {
  const match = useMatch(RouteUrl.GRADUATE_COURSE);
  const navigate = useNavigate();
  const {courseId} = useCourseId();
  const {user, isLoading: isLoadingUser} = useCurrentUserQuery();
  const {data, isLoading} = useGetGraduateApplyQuery(courseId);
  const isLoggedIn = Boolean(user);

  const handleOpenApplyModal = () => {
    weaver.sendEvent('eas__registration__register__click');
    const path = `${match.pathname}/apply`;
    isLoggedIn ? navigate(path) : doOneIDlogin('graduate', path);
  };

  const ButtonLabel = () => {
    if (isLoadingUser) return '';
    return isLoggedIn ? '등록 신청' : '로그인 후 등록 신청';
  };

  return (
    <section className={styles.StartedSection}>
      <h2 className={styles.title}>
        원티드 취업지원시스템을 통해
        <br />
        커리어 여정을 시작해보세요!
      </h2>
      <div className={styles.text_1}>
        ‘커리어 시작을 희망하는 누구나’ 등록 신청할 수 있습니다.
      </div>
      <div className={styles.text_2}>
        300만명의 유저와 2만개 기업을 연결하는 AI 매칭 플랫폼 원티드가
        <br />
        이력서 지원부터 최종 합격까지 여러분들의 커리어 코치가 되어드립니다.
      </div>
      {isLoading ? (
        <>로딩 중...</>
      ) : (
        <CourseInfo
          institutionName={data.institutionName}
          courseName={data.courseName}
        />
      )}
      <Button
        className={styles.button}
        size="large"
        onClick={handleOpenApplyModal}
        disabled={isLoadingUser}
      >
        <ButtonLabel />
      </Button>
      <div className={styles.previewImage}>
        <img
          src="https://image.wanted.co.kr/eas/graduate/course/apply/main.png"
          alt="main"
          width="924"
          height="352"
        />
      </div>
    </section>
  );
};

export default StartedSection;

import {PropsWithChildren, useCallback} from 'react';

import useModalManagement from '@/shared/hooks/useModalManagement';
import classNames from 'classnames';
import {createPortal} from 'react-dom';

import styles from './modal.module.scss';

export interface ModalProps extends PropsWithChildren {
  className?: string;
  bodyClassName?: string;
  open: boolean;
  onBackdropClick?: (event: unknown) => void;
  onClose?: (event: unknown, reason?: string) => void;
  disableScrollLock?: boolean;
  // true면 backdrop 클릭시 모달이 닫히지 않는다.
  disableBackdropClick?: boolean;
  // true면 esc키를 눌러도 모달이 닫히지 않는다.
  disableEscapeKeyDown?: boolean;
  backdropBlur?: boolean;
  // true면 backdrop 색이 투명이 된다.
  backdropTransparent?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  scroll?: 'content' | 'body';
}

export const Modal: React.FC<ModalProps> = props => {
  const {
    children,
    className,
    bodyClassName,
    open,
    onBackdropClick,
    onClose,
    disableBackdropClick,
    disableEscapeKeyDown,
    backdropBlur = false,
    backdropTransparent,
    fullScreen,
    fullWidth,
    scroll,
    disableScrollLock,
  } = props;

  const {handleClose} = useModalManagement({
    open,
    disableEscapeKeyDown,
    disableScrollLock,
    onClose,
  });

  const rootClasses = classNames({
    [styles.Modal__root]: true,
    [className || '']: className !== undefined,
    [styles.Modal__root_scrollContent]: scroll === 'content',
  });

  const bodyClasses = classNames({
    [styles.Modal__root__body]: true,
    [styles.Modal__root__body__scrollBody]: scroll === 'body',
    [styles.Modal__root__body__scrollContent]: scroll === 'content',
    [styles.Modal__root__body__fullScreen]: fullScreen === true,
    [styles.Modal__root__body__fullWidth]: fullWidth === true,
    [bodyClassName || '']: bodyClassName !== undefined,
  });

  const backdropClasses = classNames({
    [styles.Modal__root__backdrop]: true,
    [styles.Modal__root__blur]: backdropBlur,
    [styles.Modal__root__transparent]: backdropTransparent,
  });

  const handleBackdropClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onBackdropClick) {
        onBackdropClick(event);
      }

      if (!disableBackdropClick) {
        handleClose(event, 'backdropClick');
      }
    },
    [disableBackdropClick, handleClose, onBackdropClick]
  );

  return (
    <>
      {open &&
        createPortal(
          <div className={rootClasses} role="alertdialog">
            <button
              onClick={handleBackdropClick}
              className={backdropClasses}
              tabIndex={0}
            />
            <div className={bodyClasses}>{children}</div>
          </div>,
          document.body
        )}
    </>
  );
};

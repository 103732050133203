import {QueryClient} from '@tanstack/react-query';

export const generateQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        throwOnError: true,
      },
    },
  });

export const queryClient = generateQueryClient();

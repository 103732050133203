import {lazy, Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Card} from '@/shared/ui/card';
import {ErrorMessage} from '@/shared/ui/error-message';
import {Loading} from '@/shared/ui/loading';
import {useQueryClient} from '@tanstack/react-query';

import {createCourseBriefQueryKey} from '../../queries/useGetCourseBriefQuery';

import styles from './BriefStatistics.module.scss';

const BriefStatistics = lazy(() => import('./BriefStatistics'));

export default function SuspensedBriefStatistics() {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();

  const onReset = () => {
    const queryKey = createCourseBriefQueryKey(user.institutionId);
    queryClient.resetQueries({queryKey});
  };

  return (
    <Card className={styles.container}>
      <ApiErrorBoundary fallback={ErrorMessage} onReset={onReset}>
        <Suspense fallback={<Loading />}>
          <BriefStatistics key={user.institutionId} />
        </Suspense>
      </ApiErrorBoundary>
    </Card>
  );
}

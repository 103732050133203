import {forwardRef} from 'react';

import {Box} from '@wanteddev/wds';
import classNames from 'classnames';

import styles from './modal-actions.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const ModalActions = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {children, className, ...other} = props;

  const rootClasses = classNames({
    [styles.ModalActions__root]: true,
    [className || '']: className !== undefined,
  });

  return (
    <Box as="div" className={rootClasses} ref={ref} {...other}>
      {children}
    </Box>
  );
});

ModalActions.displayName = 'ModalActions';

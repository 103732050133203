import React, {useRef} from 'react';

import classNames from 'classnames';
import lottie from 'lottie-web';
import {useEffectOnce} from 'usehooks-ts';

import lottieLoading from './lottieLoading.json';

import styles from './loading.module.scss';

interface LoadingProps {
  absoluteCenter?: boolean;
}

/**
 * 페이지 로딩 시에 화면에 노출되는 컴포넌트입니다.
 */
const LoadingComponent: React.FC<LoadingProps> = ({absoluteCenter = false}) => {
  const loadedAnimation = useRef(false);
  const loadingRef = useRef<HTMLDivElement>();

  useEffectOnce(() => {
    if (!loadedAnimation.current && loadingRef && loadingRef.current) {
      loadedAnimation.current = true;
      lottie.loadAnimation({
        container: loadingRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieLoading,
      });
    }
  });

  return (
    <div
      className={classNames(styles.container, {
        [styles.absoluteCenter]: absoluteCenter,
      })}
    >
      <div ref={loadingRef} className={styles.logo} />
    </div>
  );
};

export const Loading = React.memo(LoadingComponent);

import {Suspense, lazy} from 'react';

import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';

const DefaultLayout = lazy(() => import('./DefaultLayout'));

export function SuspensedDefaultLayout() {
  return (
    <ApiErrorBoundary>
      <Suspense fallback={<Loading absoluteCenter />}>
        <DefaultLayout />
      </Suspense>
    </ApiErrorBoundary>
  );
}

import {PropsWithRef, ReactElement} from 'react';

import UnauthorizedError from '@/shared/api/errors/UnauthorizedError';
import {RouteUrl} from '@/shared/constant';
import {UnknownError} from '@/shared/ui/unknown-error';
import * as Sentry from '@sentry/react';

type IgnoreErrorType = <ErrorType extends Error = Error>(
  error: ErrorType | unknown
) => boolean;

export type ApiErrorBoundaryFallbackProps = Partial<
  Parameters<Sentry.FallbackRender>[0]
>;

export interface ApiErrorBoundaryProps extends Sentry.ErrorBoundaryProps {
  fallback?: Sentry.FallbackRender;
  ignoreError?: IgnoreErrorType;
}

export function ApiErrorBoundary(
  props: PropsWithRef<ApiErrorBoundaryProps>
): ReactElement {
  const {fallback, ignoreError, children, ...others} = props;

  const fallbackRender: Sentry.FallbackRender = ({
    error,
    componentStack,
    eventId,
    resetError,
  }) => {
    // 접근 권한 없음.
    if (error instanceof UnauthorizedError) {
      window.location.href = RouteUrl.LOGIN;
      return;
    }

    if (ignoreError?.(error)) {
      throw error;
    }

    if (fallback) {
      return fallback({error, componentStack, eventId, resetError});
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <UnknownError resetError={resetError} />;
  };

  return (
    <Sentry.ErrorBoundary fallback={fallbackRender} {...others}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

import {AxiosInstance} from 'axios';

import UnauthorizedError from '../errors/UnauthorizedError';

export const useInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    response => response,
    error => {
      const statusCode = error?.response?.status || error?.status;
      if (statusCode === 401) {
        // 권한 없음
        return Promise.reject(new UnauthorizedError(error.message));
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

import React from 'react';

import {Button} from '@/shared/ui/button';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {Typography} from '@/shared/ui/typography';

import styles from './DeleteConfirmModal.module.scss';

type DeleteConfirmModalProps = React.ComponentProps<typeof Modal> & {
  onDelete: () => void;
};

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  open,
  onClose,
  onDelete,
}) => {
  const handleDelete = () => {
    onClose(null);
    onDelete();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      bodyClassName={styles.DeleteConfirmModal}
      disableBackdropClick={true}
    >
      <ModalContent className={styles.modalContent}>
        <Typography variant="title1" size="small" className={styles.title}>
          선택한 수료생 정보를 삭제하겠습니까?
        </Typography>
        <Typography variant="body1" size="small" className={styles.message}>
          삭제 후 해당 수료생의 취업활동내역은 트래킹할 수 없습니다.
        </Typography>
      </ModalContent>
      <ModalActions className={styles.modalActions}>
        <Button
          onClick={onClose}
          className={styles.button}
          size="large"
          variant="outlined"
        >
          취소
        </Button>
        <Button onClick={handleDelete} className={styles.button} size="large">
          삭제
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteConfirmModal;

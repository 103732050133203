import {CourseGraduateDetail} from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {updateCourseGraduateComment} from '@/shared/api/courseGraduate/updateCourseGraduateComment';
import * as Sentry from '@sentry/react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useToast} from '@wanteddev/wds';

import {getGraduateDetailQueryKey} from '../../../hooks/useGetGraduateDetailQuery';

const useUpdateCourseGraduateCommentMutation = (
  institutionId: string,
  courseId: string,
  graduateId: string
) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const graduateDetailQueryKey = getGraduateDetailQueryKey(
    institutionId,
    courseId,
    graduateId
  );

  return useMutation({
    mutationFn: ({commentId, comment}: {commentId: number; comment: string}) =>
      updateCourseGraduateComment({
        institutionId,
        courseId,
        graduateId,
        commentId,
        comment,
      }),
    onMutate: async ({commentId, comment}) => {
      await queryClient.cancelQueries({queryKey: graduateDetailQueryKey});

      queryClient.setQueryData(
        graduateDetailQueryKey,
        (data: CourseGraduateDetail | undefined) => {
          if (!data) return data; // 혹은 빈 객체 반환
          return {
            ...data,
            comments: data.comments.map(c => {
              if (c.commentId === commentId) {
                return {
                  ...c,
                  comment,
                };
              }
              return c;
            }),
          };
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: graduateDetailQueryKey,
      });
    },
    onError: error => {
      console.error(error);
      Sentry.captureException(error); // TODO: sentry에러 처리 공통화 하기

      toast({
        variant: 'warning',
        content: '시스템 오류가 발생하였습니다.',
      });
    },
  });
};

export default useUpdateCourseGraduateCommentMutation;

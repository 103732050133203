import {Suspense, lazy} from 'react';

import {Loading} from '@/shared/ui/loading';

const InstitutionComponent = lazy(() => import('./Institution'));

const InstitutionSuspense = () => (
  <Suspense fallback={<Loading />}>
    <InstitutionComponent />
  </Suspense>
);

export default InstitutionSuspense;

import {useEffect} from 'react';

import createCourseGraduateComment from '@/shared/api/courseGraduate/createCourseGraduateComment';
import * as Sentry from '@sentry/react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
  Box,
  Button,
  FlexBox,
  SxProp,
  Theme,
  Typography,
  css,
  typographyStyle,
  useToast,
} from '@wanteddev/wds';
import {useForm} from 'react-hook-form';

import {getGraduateDetailQueryKey} from '../../../hooks/useGetGraduateDetailQuery';
import useGraduateDetailParams from '../../../hooks/useGraduateDetailParams';

const boxStyles: SxProp = (theme: Theme) => ({
  position: 'relative',
  padding: '12px 16px 10px',
  borderTopWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.line.solid.neutral,
});

const textStyles = (theme: Theme) => css`
  margin: 4px 0 8px;
  outline: none;
  resize: none;
  width: 100%;
  height: 42px;
  border: none;
  ${typographyStyle('caption1', 'regular')}
  color: ${theme.palette.label.neutral};

  &::placeholder {
    color: ${theme.palette.label.assistive};
  }
`;

interface MentoringCommentsWriteProps {
  graduateName: string;
}

interface FormState {
  comment: string;
}

const MAX_COMMENT_LENGTH = 255;

const MentoringCommentsWrite = ({
  graduateName,
}: MentoringCommentsWriteProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {graduateId, institutionId, courseId} = useGraduateDetailParams();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: {errors, isSubmitting},
  } = useForm<FormState>({
    defaultValues: {
      comment: '',
    },
  });
  const comment = watch('comment');

  const mutation = useMutation<void, Error, FormState>({
    mutationFn: ({comment}) =>
      createCourseGraduateComment({
        institutionId,
        courseId,
        graduateId,
        comment,
      }),
    onSuccess: () => {
      reset();

      queryClient.invalidateQueries({
        queryKey: getGraduateDetailQueryKey(
          institutionId,
          courseId,
          graduateId
        ),
      }); // Adjust the query key as needed
    },
    onError: error => {
      console.error(error);
      Sentry.captureException(error);
      toast({
        variant: 'warning',
        content: '시스템 오류가 발생하였습니다.',
      });
    },
  });

  const onSubmit = (data: FormState) => {
    mutation.mutate(data);
  };

  return (
    <FlexBox flexDirection="column" sx={boxStyles}>
      <Typography
        variant="caption1"
        weight="bold"
        color="palette.label.neutral"
      >
        {graduateName}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          required
          as="textarea"
          sx={textStyles}
          {...register('comment', {
            maxLength: {
              value: MAX_COMMENT_LENGTH,
              message: '글자 수 초과입니다.',
            },
          })}
          placeholder="지원자의 직무 역량, 포지션 적합도, 면접 일정, 진행사항 등에 대한 의견을 남겨 주세요."
          autoFocus
        />
        {errors.comment && (
          <Typography
            variant="caption2"
            color="palette.status.negative"
            sx={{
              position: 'absolute',
              left: '20px',
              bottom: '20px',
            }}
          >
            {errors.comment.message}
          </Typography>
        )}
        <Button
          type="submit"
          size="small"
          sx={{display: 'flex', marginLeft: 'auto'}}
          disabled={
            !comment || !comment.trim() || mutation.isPending || isSubmitting
          }
        >
          등록
        </Button>
      </form>
    </FlexBox>
  );
};

export default MentoringCommentsWrite;

import {deleteCourseGraduateComment} from '@/shared/api/courseGraduate/updateCourseGraduateComment';
import * as Sentry from '@sentry/react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useToast} from '@wanteddev/wds';

import {getGraduateDetailQueryKey} from '../../../hooks/useGetGraduateDetailQuery';

const useDeleteCourseGraduateCommentMutation = (
  institutionId: string,
  courseId: string,
  graduateId: string
) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (commentId: number) =>
      deleteCourseGraduateComment({
        graduateId,
        institutionId,
        courseId,
        commentId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getGraduateDetailQueryKey(
          institutionId,
          courseId,
          graduateId
        ),
      });
    },
    onError: error => {
      console.error(error);
      Sentry.captureException(error);

      toast({
        variant: 'warning',
        content: '시스템 오류가 발생하였습니다.',
      });
    },
  });
};

export default useDeleteCourseGraduateCommentMutation;

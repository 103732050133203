import {EllipsisTooltip} from '@/shared/ui/ellipsis-tooltip';
import {FlexBox} from '@wanteddev/wds';

import styles from './StartedSection.module.scss';

type CourseData = {
  institutionName: string;
  courseName: string;
};

const CourseInfo: React.FC<CourseData> = ({institutionName, courseName}) => (
  <div className={styles.StartedSection__courseInfo}>
    <FlexBox>
      <div className={styles.label}>교육기관명</div>
      <div className={styles.value}>
        <EllipsisTooltip content={institutionName} placement="top-start" />
      </div>
    </FlexBox>
    <FlexBox>
      <div className={styles.label}>코스명</div>
      <div className={styles.value}>
        <EllipsisTooltip content={courseName} placement="bottom-start" />
      </div>
    </FlexBox>
  </div>
);

export default CourseInfo;

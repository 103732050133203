import React, {ChangeEventHandler, useRef} from 'react';

import useMergeRefs from '@/shared/hooks/useMergeRefs';
import capitalize from '@/shared/utils/capitalize';
import {Box, SxProp} from '@wanteddev/wds';
import classNames from 'classnames';

import styles from './radio.module.scss';

interface RadioProps extends React.HTMLAttributes<HTMLInputElement> {
  className?: string;
  labelClassName?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[];
  labelSx?: SxProp;
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (props, ref) => {
    const {
      className,
      labelClassName,
      checked,
      disabled = false,
      label,
      labelPlacement = 'start',
      name,
      onChange,
      value,
      labelSx,
      ...other
    } = props;

    const ownRef = useRef<HTMLInputElement>(null);
    const handleInputRef = useMergeRefs(ref, ownRef);

    const rootClasses = classNames({
      [styles.Radio__root]: true,
      [styles[`Radio__labelPlacement${capitalize(labelPlacement)}`]]: true,
      [styles.Radio__root__disabled]: disabled,
      [className]: className !== undefined,
    });

    return (
      <label className={rootClasses}>
        <span className={styles.Radio__input}>
          <input
            type="radio"
            defaultChecked={checked}
            name={name}
            value={value}
            onChange={onChange}
            ref={handleInputRef}
            disabled={disabled}
            {...other}
          />
        </span>
        <Box
          className={classNames(styles.Radio__label, labelClassName)}
          sx={labelSx}
        >
          {label}
        </Box>
      </label>
    );
  }
);

Radio.displayName = 'Radio';

import {Button} from '@/shared/ui/button';
import {Modal} from '@/shared/ui/modal';
import axios from 'axios';

import styles from './CourseCSVFileDownloadModal.module.scss';

type Props = React.ComponentProps<typeof Modal>;

const CourseCSVFileDownloadModal: React.FC<Props> = ({open, onClose}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      bodyClassName={styles.CourseCSVFileDownloadModal}
      disableBackdropClick={false}
    >
      CSV를 다운로드 하시겠습니까?
      <Button
        component="a"
        href="https://asset.wanted.co.kr/eas/원티드 취업지원시스템 코스 대량 등록.csv"
        download="원티드 취업지원시스템 코스 대량 등록.csv"
        onClick={async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          e.preventDefault();
          // 인코딩 문제로 axios를 이용해서 바이너리 파일을 다운로드한다.
          const {data} = await axios.get(e.currentTarget.href, {
            responseType: 'blob',
          });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(data);
          a.download = '원티드 취업지원시스템 코스 대량 등록.csv';
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          onClose(e);
        }}
      >
        확인
      </Button>
    </Modal>
  );
};

export default CourseCSVFileDownloadModal;

import {PropsWithChildren} from 'react';

import {Card} from '@/shared/ui/card';
import {Title} from '@/shared/ui/typography';
import classNames from 'classnames';

import styles from './PassnoteApplyList.module.scss';

type PassnoteApplyListProps = {
  title: string;
  className?: string;
};

const PassnoteApplyList: React.FC<
  PropsWithChildren<PassnoteApplyListProps>
> = ({title, className, children}) => {
  return (
    <Card className={classNames(styles.PassnoteApplyList, className)}>
      <div className={styles.PassnoteApplyList__head}>
        <Title>{title}</Title>
        <div className={styles.PassnoteApplyList__hint}>*최근 7일간 데이터</div>
      </div>
      <div className={styles.contents}>{children}</div>
    </Card>
  );
};

export default PassnoteApplyList;

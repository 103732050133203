import {getData} from '@/shared/api/client';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';

export type PassnoteApplyHire = {
  courseId: number;
  courseName: string;
  graduateId: number;
  graduateName: string;
  employmentDate: string;
  companyName: string;
  kreditjobId?: string;
};

export const getPassnoteApplyHireListQueryKeys = (institutionId?: number) => [
  'PASSNOTE_APPLY_HIRE',
  ...(institutionId ? [institutionId] : []),
];

const usePassnoteApplyHireListQuery = (
  institutionId: number,
  options?: Omit<QueryObserverOptions<PassnoteApplyHire[]>, 'queryKey'>
) => {
  return useQuery({
    queryKey: getPassnoteApplyHireListQueryKeys(institutionId),
    queryFn: async ({signal}) =>
      getData<PassnoteApplyHire[]>(
        `/eas/dashboard/institution/${institutionId}/passnote/hire`,
        {signal}
      ),
    ...options,
  });
};

export default usePassnoteApplyHireListQuery;

import React, {
  ChangeEvent,
  ChangeEventHandler,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import styles from './dropdown.module.scss';

interface DropDownProps extends PropsWithChildren {
  id?: string;
  label?: ReactNode | string;
  value?: string;
  placeholder?: string;
  optionValue?: string;
  optionTitle?: string;
  className?: string;
  fullWidth?: boolean;
  selectClassName?: string;
  optionClassName?: string;
  dataList?: Array<{id?: string; [k: string]: string}>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  error?: string;
  noBorder?: boolean;
  disabled?: boolean;
}

export const DropDown: React.FC<
  DropDownProps & React.HTMLAttributes<HTMLSelectElement>
> = props => {
  const {
    id = '',
    label,
    placeholder,
    value,
    dataList,
    optionValue = 'value',
    optionTitle = 'name',
    className,
    selectClassName,
    optionClassName,
    fullWidth = false,
    onClick,
    onChange,
    children,
    error,
    style,
    noBorder = false,
    disabled = false,
  } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isShow, setIsShow] = useState(false);

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    onClick?.(e);
    onChange?.(e as unknown as ChangeEvent<HTMLSelectElement>);
    setIsShow(prev => !prev);
  }

  // 드롭박스 이외의 영역 클릭 시 드롭박스 닫기
  useEffect(() => {
    function handleClickOutside(e: Event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setIsShow(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.DropDown, {
        [styles.DropDown_fullWidth]: fullWidth,
        [className]: className !== undefined,
      })}
      style={style}
    >
      {label && (
        <label htmlFor={id} className={styles.DropDown_label}>
          {label}
        </label>
      )}
      <div className={styles.DropDown_selectWrapper}>
        <button
          type="button"
          className={classNames(styles.DropDown_select, {
            [styles.DropDown_select_show]: isShow,
            [styles.DropDown_select_no_border]: noBorder,
            [styles.DropDown_select_placeholder]:
              placeholder !== undefined && !value,
            [selectClassName]: selectClassName !== undefined,
            [styles.DropDown_hasError]: Boolean(error),
          })}
          onClick={() => {
            setIsShow(prev => !prev);
          }}
          disabled={disabled}
        >
          {dataList?.find(data => data[optionValue] === value)?.[optionTitle] ||
            placeholder}
        </button>
        {isShow && (
          <ul
            className={classNames(styles.DropDown_option, optionClassName, {
              [optionClassName]: optionClassName !== undefined,
            })}
          >
            {children}
            {!children &&
              dataList.map((data, idx) => (
                <li key={data.id || idx}>
                  <button
                    type="button"
                    name={data[optionTitle]}
                    value={data[optionValue]}
                    data-selected={data[optionValue] === value}
                    onClick={handleClick}
                  >
                    {data[optionTitle]}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <div className={styles.DropDown_errorMsg}>{error}</div>
    </div>
  );
};

import Base62 from './base62';

const base62 = new Base62();

export function encodeIds(...ids: number[]): string {
  return base62.encode(new TextEncoder().encode([...ids].reverse().join(',')));
}

export function decodeIds(encoded: string): number[] {
  return new TextDecoder()
    .decode(base62.decode(encoded))
    .split(',')
    .map(id => parseInt(id, 10))
    .reverse();
}

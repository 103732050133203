import {PropsWithChildren} from 'react';

import {withSuspense} from '@/shared/lib/react';
import {Loading} from '@/shared/ui/loading';
import {UnknownError} from '@/shared/ui/unknown-error';
import {withErrorBoundary} from '@sentry/react';
import {ThemeProvider} from '@wanteddev/wds';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';

import {TanStackQueryClientProvider} from './QueryClientProvider';

const Providers: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <HelmetProvider>
      <TanStackQueryClientProvider>
        <ThemeProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </ThemeProvider>
      </TanStackQueryClientProvider>
    </HelmetProvider>
  );
};

const SuspenseProvider = withSuspense(Providers, {
  fallback: <Loading absoluteCenter />,
});

export const Provider = withErrorBoundary(SuspenseProvider, {
  fallback: () => <UnknownError />,
});

import {useCallback, useMemo, useState} from 'react';

import {useIsPaidSubscriptionFee} from '@/shared/hooks/usePaidSubscriptionsFee';
import {weaver} from '@/shared/lib/weaver';
import {ClickableLink} from '@/shared/ui/clickable-link';
import {EllipsisTooltip} from '@/shared/ui/ellipsis-tooltip';
import {SearchCompanyNamesButton} from '@/shared/ui/search-compnay-names-button';
import {SortButton} from '@/shared/ui/sort-button';
import {TableBody} from '@/shared/ui/table/table-body';
import {TableHead} from '@/shared/ui/table/table-head';
import {Tooltip} from '@/shared/ui/tooltip';
import {dateFormat} from '@/shared/utils';
import {formatPhoneNumber} from '@/shared/utils/phoneNumberUtils';
import {NopaidCompanyAccessButton} from '@/widgets/nopaid-company-access-button/nopaid-company-access-button';
import {Box, Checkbox, Typography, typographyStyle} from '@wanteddev/wds';
import classNames from 'classnames';
import dayjs from 'dayjs';
import qs from 'qs';

import {HR_PLATFORM} from '../../helpers/constants';
import {type GraduateBrief} from '../../queries/useGetGraduateBriefQuery';

import {GraudateEmpty} from './GraduateEmpty';
import {
  getNoInfoMessage,
  sortDirectionsInitialState,
  sortOrder,
} from './helpers';
import {GraduateBriefType, type SortKey} from './types';

import styles from './GraduateTable.module.scss';

type Props = {
  data: GraduateBrief[];
  viewOnlyEmployedUsers: boolean;
  institutionId: number;
  courseId: string;
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
};

const GraduateTable: React.FC<Props> = ({
  data,
  viewOnlyEmployedUsers,
  institutionId,
  courseId,
  selectedIds,
  setSelectedIds,
}) => {
  const isPaidSubscriptionFee = useIsPaidSubscriptionFee();
  const [sortDirections, setSortDirections] = useState(
    sortDirectionsInitialState
  );

  const handleSort = (key: SortKey, sortKindForWeaver: string) => () => {
    const {[key]: currentSort} = sortDirections;
    const newSortValue = currentSort === 'asc' ? 'desc' : 'asc';
    setSortDirections(() => ({
      ...sortDirectionsInitialState,
      [key]: newSortValue,
    }));

    weaver.sendEvent('eas__user__sort__click', {
      sortKind: sortKindForWeaver,
    });
  };

  const sortFunction: Record<
    SortKey,
    (a: GraduateBriefType, b: GraduateBriefType) => number
  > = {
    applyCount: (a, b) =>
      sortOrder[sortDirections.applyCount](a.applyCount, b.applyCount),
    applyPassCount: (a, b) =>
      sortOrder[sortDirections.applyPassCount](
        a.applyPassCount,
        b.applyPassCount
      ),
    applyPassRate: (a, b) =>
      sortOrder[sortDirections.applyPassRate](a.applyPassRate, b.applyPassRate),
    hiredCount: (a, b) =>
      sortOrder[sortDirections.hiredCount](a.hiredCount, b.hiredCount),
    hiredRate: (a, b) =>
      sortOrder[sortDirections.hiredRate](a.hiredRate, b.hiredRate),
    latestUpdatedDate: (a, b) =>
      sortOrder[sortDirections.latestUpdatedDate](
        a.latestUpdatedDate
          ? dayjs(a.latestUpdatedDate, 'YYYY-MM-DD').toDate().getTime()
          : 0,
        b.latestUpdatedDate
          ? dayjs(b.latestUpdatedDate, 'YYYY-MM-DD').toDate().getTime()
          : 0
      ),
  };

  const sortedData = useMemo(() => {
    const sorted = data?.slice().sort((a, b) => {
      for (const key in sortDirections) {
        const sortKey = key as SortKey;
        const currentSort = sortDirections[sortKey];
        const sortFunc = sortFunction[sortKey];
        if (currentSort && sortFunc) {
          const cmp = sortFunc(a, b);
          if (cmp !== 0) return cmp;
        }
      }
      return 0;
    });

    return viewOnlyEmployedUsers
      ? sorted?.filter?.(user => user.latestUpdatedDate)
      : sorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sortDirections, viewOnlyEmployedUsers]);

  const activeGraduateList = useMemo(() => {
    return data?.filter(
      ({graduateName}) => !graduateName?.toLowerCase().endsWith('_del')
    );
  }, [data]);

  const renderCompanyName = useCallback(
    (graduate: GraduateBrief) => {
      if (!graduate.companyName || graduate.companyName === 'UNKNOWN') {
        return '-';
      }

      if (!isPaidSubscriptionFee) {
        return (
          <NopaidCompanyAccessButton
            companyName={graduate.companyName}
            className={styles.companyName}
          />
        );
      }

      return (
        <SearchCompanyNamesButton
          kreditjobId={graduate.kreditjobId}
          companyName={graduate.companyName}
          institutionId={institutionId}
          wrapperClassName={styles.companyName}
        />
      );
    },
    [institutionId, isPaidSubscriptionFee]
  );

  const isAllSelected = useMemo(
    () =>
      selectedIds?.length > 0 &&
      selectedIds?.length === activeGraduateList?.length,
    [activeGraduateList?.length, selectedIds?.length]
  );

  const handleAllSelect = (checked: boolean) => {
    if (checked) {
      setSelectedIds(activeGraduateList.map(d => d.graduateId) ?? []);
    } else {
      setSelectedIds([]);
    }
  };

  return (
    <>
      <Box as="table" className={styles.GraduateTable}>
        <colgroup>
          <col width="5%" />
          <col width="7%" />
          <col width="15%" />
          <col width="11%" />
          <col width="11%" />
          <col width="11%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <TableHead>
          <Box as="th" align="center">
            <Checkbox
              checked={isAllSelected}
              disabled={!data?.length}
              onCheckedChange={handleAllSelect}
            />
          </Box>
          <Box as="th">수료생</Box>
          <Box as="th">연락처</Box>
          <Box as="th" align="center">
            <SortButton
              text="이력서지원수"
              onClick={handleSort('applyCount', 'applyCount')}
              active={sortDirections.applyCount !== null}
              direction={sortDirections.applyCount}
            />
          </Box>
          <Box as="th" align="center">
            <SortButton
              text="이력서합격수"
              onClick={handleSort('applyPassCount', 'passCount')}
              active={sortDirections.applyPassCount !== null}
              direction={sortDirections.applyPassCount}
            />
          </Box>
          <Box as="th" align="center">
            <SortButton
              text="이력서합격률"
              onClick={handleSort('applyPassRate', 'passRate')}
              active={sortDirections.applyPassRate !== null}
              direction={sortDirections.applyPassRate}
            />
          </Box>
          <Box as="th" align="center">
            <SortButton
              text="면접합격수"
              onClick={handleSort('hiredCount', 'hireCount')}
              active={sortDirections.hiredCount !== null}
              direction={sortDirections.hiredCount}
            />
          </Box>
          <Box as="th" align="center">
            <SortButton
              text="면접합격률"
              onClick={handleSort('hiredRate', 'hireRate')}
              active={sortDirections.hiredRate !== null}
              direction={sortDirections.hiredRate}
            />
          </Box>
          <Box as="th" align="center">
            취업 여부
          </Box>
          <Box as="th" align="center">
            취업 플랫폼
          </Box>
          <Box as="th" align="center">
            합격 회사
          </Box>
          <Box as="th" align="center">
            <SortButton
              text="취업일"
              onClick={handleSort('latestUpdatedDate', 'updatedDate')}
              active={sortDirections.latestUpdatedDate !== null}
              direction={sortDirections.latestUpdatedDate}
            />
          </Box>
        </TableHead>
        <TableBody>
          {sortedData.length === 0 && (
            <GraudateEmpty message="등록된 수료생이 없습니다." />
          )}

          {sortedData?.map(graduate => {
            const isDeleted = /_del$/i.test(graduate.graduateName); // 삭제 예정 유저 여부
            const graduateName =
              graduate.graduateName?.replace(/_del$/i, '').trim() ?? '';
            const noInfoMessage = getNoInfoMessage(graduate);

            return (
              <tr
                key={graduate.graduateId}
                className={classNames({[styles.deleted]: isDeleted})}
              >
                <Box as="td" align="center">
                  <Checkbox
                    checked={selectedIds?.includes(graduate.graduateId)}
                    disabled={!data?.length || isDeleted}
                    onCheckedChange={checked => {
                      if (checked) {
                        setSelectedIds([...selectedIds, graduate.graduateId]);
                      } else {
                        setSelectedIds(
                          selectedIds.filter(id => id !== graduate.graduateId)
                        );
                      }
                    }}
                  />
                </Box>
                <Box as="td">
                  <ClickableLink
                    to={{
                      pathname: String(graduate.graduateId),
                      search: qs.stringify({institutionId, courseId}),
                    }}
                  >
                    {isDeleted ? (
                      <Tooltip content="삭제예정">
                        <span className={styles.graduateName}>
                          {graduateName}
                        </span>
                      </Tooltip>
                    ) : (
                      <EllipsisTooltip
                        content={graduateName}
                        sx={typographyStyle('label1_normal', 'bold')}
                      />
                    )}
                  </ClickableLink>
                </Box>
                <Box as="td">
                  <p>{formatPhoneNumber(graduate.graduateMobile)}</p>
                  <p>{graduate.graduateEmail}</p>
                </Box>

                {noInfoMessage && (
                  <Box
                    as="td"
                    align="center"
                    colSpan={9}
                    className={styles.noInfo}
                  >
                    {noInfoMessage}
                  </Box>
                )}

                {!noInfoMessage && (
                  <>
                    <Box
                      as="td"
                      align="center"
                      className={classNames({
                        [styles.danger]: graduate.applyCount < 50,
                        [styles.zero]: graduate.applyCount === 0,
                      })}
                    >
                      <Typography variant="label1_normal" weight="bold">
                        {graduate.applyCount}
                      </Typography>
                    </Box>
                    <Box as="td" align="center">
                      <Typography variant="label1_normal" weight="bold">
                        {graduate.applyPassCount}
                      </Typography>
                    </Box>
                    <Box
                      as="td"
                      align="center"
                      className={classNames({
                        [styles.danger]: graduate.applyPassRate < 7,
                        [styles.zero]: graduate.applyPassRate === 0,
                      })}
                    >
                      <Typography variant="label1_normal" weight="bold">
                        {graduate.applyPassRate}%
                      </Typography>
                    </Box>
                    <Box as="td" align="center">
                      <Typography variant="label1_normal" weight="bold">
                        {graduate.hiredCount}{' '}
                      </Typography>
                    </Box>
                    <Box as="td" align="center">
                      <Typography variant="label1_normal" weight="bold">
                        {graduate.hiredRate}%
                      </Typography>
                    </Box>
                    <Box as="td" align="center">
                      <Typography variant="label1_normal">
                        {graduate.latestUpdatedDate ? '확인' : '-'}
                      </Typography>
                    </Box>
                    <Box as="td" align="center">
                      <Typography variant="label1_normal">
                        {HR_PLATFORM[graduate.usedHrPlatform] ||
                          HR_PLATFORM.default}
                      </Typography>
                    </Box>
                    <Box as="td" align="center">
                      {renderCompanyName(graduate)}
                    </Box>
                    <Box as="td" align="center">
                      <Typography variant="label1_normal">
                        {graduate.latestUpdatedDate
                          ? dateFormat(graduate.latestUpdatedDate)
                          : '-'}
                      </Typography>
                    </Box>
                  </>
                )}
              </tr>
            );
          })}
        </TableBody>
      </Box>

      <Box
        as="div"
        sx={{display: 'none'}}
        aria-hidden
        title="재활용 가능한 svg symbol 오브젝트"
      >
        <svg id="wanted-text-symbol">
          <path
            fill="currentColor"
            d="M89.8 2.2l-5.6 2.4v4.8h-3.8v5.2h3.8v10.2c0 4.2 2.6 7 6.6 7 1.6 0 2.6-.4 3.2-.6V26c-.2 0-1 .2-1.8.2-1.6 0-2.4-.6-2.4-2.6v-8.8H94V9.6h-4.2V2.2zM28.6 9.6l-4 14-4.6-14h-5.6l-4.6 14L6 9.6H0l6.8 21.8h6l4.4-14.6 4.6 14.6h6l6.8-21.8zM134.4 2.2v8.6c-1.4-1-3-1.6-4.8-1.8h-.4-1.6c-5 .4-8.2 4.2-9.2 9-.2.8-.2 1.4-.2 2.2V22c.6 5.6 4.4 10 10.2 10 2.4 0 4.4-.6 6-1.8v1.4h5.4V0l-5.4 2.2zm-5.2 24.4c-3 0-5.6-2.4-5.6-6.2 0-4 2.6-6.2 5.6-6.2s5.2 2.2 5.2 6c0 4.2-2.2 6.4-5.2 6.4zM116.2 18c-.8-5.2-4.6-9-10-9s-9.2 3.8-10 9c-.2.8-.2 1.6-.2 2.6v1.6c.6 5.6 4.4 10 10.2 10 4.6 0 8-2.8 9.4-6.8l-5-1.2c-.8 1.8-2.4 3.2-4.4 3.2-2.8 0-4.6-2.2-5-5.2h15.2v-1.6c0-1 0-1.8-.2-2.6zm-14.8 0c.8-2.2 2.4-3.6 4.8-3.6s4 1.6 4.8 3.6h-9.6zM50.6 11c-1.4-1-3.2-1.8-5.2-1.8H44.8h-.6c-5.2.4-8.6 4-9.4 9-.2.8-.2 1.4-.2 2.2v1.8c.6 5.6 4.4 10 10.2 10 2.4 0 4.4-.6 6-1.8v1.4h5.6V9.6h-5.6V11zm-5.2 15.6c-3 0-5.6-2.4-5.6-6.2 0-4 2.6-6.2 5.6-6.2s5.2 2.2 5.2 6c0 4.2-2.2 6.4-5.2 6.4zM71.2 9c-2.2 0-4.6 1-6 3.2V9.6h-5.6v21.8h5.6V18.8c0-2.6 1.4-4.6 4-4.6 2.8 0 3.8 2 3.8 4.4v12.8h5.6V17.6c.2-4.8-2.2-8.6-7.4-8.6z"
          />
        </svg>
      </Box>
    </>
  );
};

export default GraduateTable;

import client from '@/shared/api/client';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';

const useGraduateApply = (
  courseId: number,
  options?: UseMutationOptions<
    AxiosResponse,
    AxiosError<{code: string; message: string}>
  >
) => {
  return useMutation({
    mutationFn: () => client.post('/eas/graduate/apply', {courseId}),
    ...options,
  });
};

export default useGraduateApply;

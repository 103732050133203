import React, {PropsWithChildren, ReactElement, useState} from 'react';

import {
  Placement,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';

import styles from './tooltip.module.scss';

export type TooltipProps = {
  content: React.ReactNode;
  placement?: Placement;
};

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  children,
  content,
  placement = 'top',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {x, y, strategy, refs, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
  });

  const hover = useHover(context);

  const {getReferenceProps, getFloatingProps} = useInteractions([hover]);

  return (
    <>
      {React.Children.map(children, child => {
        return React.cloneElement(child as ReactElement, {
          ref: refs.setReference,
          ...getReferenceProps(),
        });
      })}
      {isOpen && (
        <div
          className={styles.Tooltip}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...(getFloatingProps() as object)}
        >
          {content}
        </div>
      )}
    </>
  );
};

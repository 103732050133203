import useSortedData from '@/shared/hooks/useSortedData';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {EllipsisTooltip} from '@/shared/ui/ellipsis-tooltip';
import {SearchCompanyNamesButton} from '@/shared/ui/search-compnay-names-button';
import {SortButton} from '@/shared/ui/sort-button';
import {TableHead} from '@/shared/ui/table/table-head';
import dayjs from 'dayjs';

import GhostImage from '../components/imageComponents/GhostImage';
import PassnoteApplyEmpty from '../components/PassnoteApplyEmpty';
import usePassnoteApplyHireListQuery, {
  PassnoteApplyHire,
} from '../queries/usePassnoteApplyPassHireQuery';

const PassnoteApplyHireList: React.FC = () => {
  const {user} = useCurrentUserQuery();
  const institutionId = user?.institutionId;
  const {sort, toggleSort, getSortedData} = useSortedData<PassnoteApplyHire>(
    'employmentDate',
    'desc'
  );

  const {data} = usePassnoteApplyHireListQuery(institutionId, {suspense: true});
  const sortedData = getSortedData(data);

  if (!data?.length) {
    return (
      <PassnoteApplyEmpty
        image={<GhostImage />}
        message="최종 취업자가 없습니다."
      />
    );
  }

  return (
    <table>
      <colgroup>
        <col width="20%" />
        <col width="32%" />
        <col width="20%" />
        <col width="28%" />
      </colgroup>
      <TableHead>
        <th>이름</th>
        <th>코스명</th>
        <th>
          <SortButton onClick={toggleSort} direction={sort} text="취업일" />
        </th>
        <th>취업 회사 정보</th>
      </TableHead>
      <tbody>
        {sortedData?.map(item => {
          return (
            <tr key={`${item.courseId}_${item.graduateId}`}>
              <td>
                <EllipsisTooltip content={item.graduateName} />
              </td>
              <td>
                <EllipsisTooltip content={item.courseName} />
              </td>
              <td>{dayjs(item.employmentDate).format('YYYY.MM.DD')}</td>
              <td>
                <SearchCompanyNamesButton
                  institutionId={institutionId}
                  kreditjobId={item.kreditjobId}
                  companyName={item.companyName}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PassnoteApplyHireList;

import {useState} from 'react';

import useGetCourseNamesQuery from '@/pages/Dashboard/Course/queries/useGetCourseNames';
import {SERVER_API_URL} from '@/shared/constant';
import {EllipsisTooltip} from '@/shared/ui/ellipsis-tooltip';
import {Radio as RadioLegacy} from '@/shared/ui/radio';
import {
  ActionArea,
  ActionAreaButton,
  Checkbox,
  Divider,
  FlexBox,
  Label,
  Modal,
  ModalContainer,
  ModalContent,
  ModalContentItem,
  ModalNavigation,
  TextField,
  Typography,
  typographyStyle,
} from '@wanteddev/wds';
import {useForm} from 'react-hook-form';

type UserCSVDownloadModalProps = {
  institutionId: number;
  open: boolean;
  onClose: () => void;
};

type FormData = {
  reason: string;
  reasonText?: string;
};

const ResaonValues = [
  '취업 활동 내역 조회',
  '취업 활동 내역을 바탕으로 자체 통계 분석',
  '기타',
];

const UserCSVDownloadModal = ({
  institutionId,
  open,
  onClose,
}: UserCSVDownloadModalProps) => {
  const {data: courseNames} = useGetCourseNamesQuery(institutionId, {
    select: data =>
      data?.sort((a, b) => a.name.localeCompare(b.name, 'ko')) || [],
  });
  const [selectedIds, setSelectedIds] = useState([]);

  const [showNext, setShowNext] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: {isValid, isSubmitting},
  } = useForm<FormData>();

  const reason = watch('reason');

  const onSubmit = (data: FormData) => {
    const downloadUrl = `${SERVER_API_URL}/eas/dashboard/institution/${institutionId}/course/graduate/brief?${selectedIds
      .map(id => `courseIds=${id}`)
      .join('&')}&reason=${data.reasonText || data.reason}`;
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = '수료생_취업정보.csv';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    onClose();
  };

  return (
    <Modal open={open} onOpenChange={isOpen => !isOpen && onClose()}>
      {showNext && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContainer
            sticky
            variant="popup"
            size="medium-fixed"
            aria-description="다운로드 사유 입력"
            sx={{
              '--wds-modal-navigation-padding-x': '51px',
            }}
          >
            <ModalNavigation rightButton={null}>
              다운로드 사유 입력
            </ModalNavigation>
            <ModalContent>
              <ModalContentItem
                gap="0px"
                sx={{
                  minHeight: 350,
                  paddingInline: 51,
                }}
              >
                <RadioLegacy
                  {...register('reason', {required: true})}
                  value={ResaonValues[0]}
                  label={ResaonValues[0]}
                  labelPlacement="end"
                  labelSx={typographyStyle('label1_normal', 'bold')}
                  onChange={() =>
                    reset({reason: ResaonValues[0], reasonText: ''})
                  }
                />
                <RadioLegacy
                  {...register('reason', {required: true})}
                  value={ResaonValues[1]}
                  label={ResaonValues[1]}
                  labelPlacement="end"
                  labelSx={typographyStyle('label1_normal', 'bold')}
                  onChange={() =>
                    reset({reason: ResaonValues[1], reasonText: ''})
                  }
                />
                <RadioLegacy
                  {...register('reason', {required: true})}
                  value="기타"
                  label="기타"
                  labelPlacement="end"
                  labelSx={typographyStyle('label1_normal', 'bold')}
                />
                <TextField
                  {...register('reasonText', {
                    required: reason === ResaonValues[2],
                  })}
                  autoComplete="off"
                  disabled={reason !== ResaonValues[2]}
                  placeholder="내용을 입력해주세요."
                  sx={{margin: '4px 1px 0 24px'}}
                />
                <Typography
                  as="div"
                  variant="caption1"
                  sx={{marginTop: 20, color: '#8a8a8a', letterSpacing: 0}}
                >
                  * 개인정보보호법에 따라 개인정보 다운로드 시 사유를 입력받고
                  있습니다.
                  <br />* 사유 선택 및 입력 시 [다운로드] 버튼이 활성화됩니다.
                </Typography>
              </ModalContentItem>
            </ModalContent>
            <ActionArea sticky priority="neutral" sx={{paddingInline: 134}}>
              <ActionAreaButton variant="sub" onClick={onClose}>
                취소
              </ActionAreaButton>
              <ActionAreaButton
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                다운로드
              </ActionAreaButton>
            </ActionArea>
          </ModalContainer>
        </form>
      )}

      {!showNext && (
        <ModalContainer
          sticky
          variant="popup"
          size="medium-fixed"
          aria-description="코스 선택"
        >
          <ModalNavigation rightButton={null}>
            CSV 다운받을 코스 선택
          </ModalNavigation>
          <ModalContent>
            <ModalContentItem
              sx={{
                minHeight: 350,
                paddingInline: 51,
                '& label': {fontSize: 14, fontWeight: '600 !important'},
              }}
            >
              <FlexBox gap="8px">
                <Checkbox
                  id="all"
                  name="all"
                  size="normal"
                  checked={selectedIds.length === courseNames?.length}
                  onCheckedChange={checked => {
                    if (checked) {
                      setSelectedIds(
                        courseNames.map(course => course.courseId)
                      );
                    } else {
                      setSelectedIds([]);
                    }
                  }}
                />
                <Label htmlFor="all">전체</Label>
              </FlexBox>
              <Divider sx={{marginBlock: 7}} />
              <FlexBox flexDirection="column" gap="10px">
                {courseNames?.map(course => (
                  <FlexBox gap="8px" key={course.courseId}>
                    <Checkbox
                      size="normal"
                      key={course.courseId}
                      id={`${course.courseId}_${course.name}`}
                      checked={selectedIds.includes(course.courseId)}
                      onCheckedChange={checked => {
                        if (checked) {
                          setSelectedIds([...selectedIds, course.courseId]);
                        } else {
                          setSelectedIds(
                            selectedIds.filter(id => id !== course.courseId)
                          );
                        }
                      }}
                    />
                    <Label
                      htmlFor={`${course.courseId}_${course.name}`}
                      weight="bold"
                      sx={{maxWidth: 380}}
                    >
                      <EllipsisTooltip
                        content={course.name}
                        placement="top-start"
                      />
                    </Label>
                  </FlexBox>
                ))}
              </FlexBox>
            </ModalContentItem>
          </ModalContent>
          <ActionArea sticky priority="neutral" sx={{paddingInline: 134}}>
            <ActionAreaButton variant="sub" onClick={onClose}>
              취소
            </ActionAreaButton>
            <ActionAreaButton
              disabled={selectedIds?.length === 0}
              onClick={() => {
                setShowNext(true);
              }}
            >
              선택완료
            </ActionAreaButton>
          </ActionArea>
        </ModalContainer>
      )}
    </Modal>
  );
};

export default UserCSVDownloadModal;

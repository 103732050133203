import {PropsWithChildren} from 'react';

import classNames from 'classnames';

import {Typography} from './typography';

import styles from './title.module.scss';

type Props = {
  className?: string;
};

export const Title: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  return (
    <Typography
      component="h2"
      variant="heading2"
      size="large"
      weight="bold"
      className={classNames(styles.Title, className)}
    >
      {children}
    </Typography>
  );
};

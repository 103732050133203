import {getData} from '@/shared/api/client';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';
import {AxiosRequestConfig} from 'axios';

export type PassnoteApplyPass = {
  courseId: number;
  courseName: string;
  graduateId: number;
  graduateWantedUserId: number;
  graduateName: string;
  applyId: number;
  applyPassTime: string;
};

const chunkSize = 5;

export const getPassnoteApplyPassListQueryKeys = (institutionId?: number) => [
  'PASSNOTE_APPLY_PASS',
  ...(institutionId ? [institutionId] : []),
];

const getPassnoteCourseIds = async (
  institutionId: number,
  config?: AxiosRequestConfig
) => {
  return getData<number[]>(
    `/eas/dashboard/institution/${institutionId}/passnote/course`,
    config
  );
};

const getPassnoteApplyPassListChunk = async (
  institutionId: number,
  courseIds: number[],
  config?: AxiosRequestConfig
) => {
  return getData<PassnoteApplyPass[]>(
    `/eas/dashboard/institution/${institutionId}/passnote/apply/pass`,
    {
      params: {courseIds},
      ...config,
    }
  );
};

async function* generatePassnoteApplyPassListChunk(
  institutionId: number,
  courseIds: number[],
  chunkSize: number,
  config?: AxiosRequestConfig
) {
  for (let i = 0; i < courseIds.length; i += chunkSize) {
    const chunk = courseIds.slice(i, i + chunkSize);
    yield await getPassnoteApplyPassListChunk(institutionId, chunk, config);
  }
}

const getPassnoteApplyPassList = async (
  institutionId: number,
  config?: AxiosRequestConfig
) => {
  const courseIds = await getPassnoteCourseIds(institutionId, config);
  const passnoteApplyPassList: PassnoteApplyPass[] = [];

  for await (const chunk of generatePassnoteApplyPassListChunk(
    institutionId,
    courseIds,
    chunkSize,
    config
  )) {
    passnoteApplyPassList.push(...chunk);
  }

  return passnoteApplyPassList;
};

const usePassnoteApplyPassListQuery = (
  institutionId: number,
  options?: Omit<QueryObserverOptions<PassnoteApplyPass[]>, 'queryKey'>
) => {
  return useQuery({
    queryKey: getPassnoteApplyPassListQueryKeys(institutionId),
    queryFn: async ({signal}) =>
      getPassnoteApplyPassList(institutionId, {signal}),
    ...options,
  });
};

export default usePassnoteApplyPassListQuery;

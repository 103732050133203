import {getData} from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {useQuery, QueryObserverOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';

export interface GraduateApply {
  institutionName: string;
  courseName: string;
  graduateName: string;
  graduateMobile: string;
  graduateEmail: string;
}

export const getGraduateApplyQueryKey = (courseId: number) => {
  return [RouteUrl.GRADUATE_COURSE, 'GET_GRADUATE_APPLY', courseId];
};

const useGetGraduateApplyQuery = (
  courseId: number,
  options?: Omit<
    QueryObserverOptions<GraduateApply, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: getGraduateApplyQueryKey(courseId),
    queryFn: ({signal}) =>
      getData<GraduateApply>('/eas/graduate/apply', {
        params: {courseId},
        signal,
      }),
    ...options,
  });
};

export default useGetGraduateApplyQuery;

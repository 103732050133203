import {CourseGraduateDetail} from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {useQueryClient} from '@tanstack/react-query';

import {getGraduateDetailQueryKey} from '../../hooks/useGetGraduateDetailQuery';
import useGraduateDetailParams from '../../hooks/useGraduateDetailParams';
import MentoringCommentsList from '../MentoringComments/components/MentoringCommentsList';
import MentoringCommentsWrite from '../MentoringComments/components/MentoringCommentsWrite';

export interface MentoringCommentsProps {
  graduate: CourseGraduateDetail;
}

const MentoringComments = () => {
  const queryClient = useQueryClient();
  const {graduateId, institutionId, courseId} = useGraduateDetailParams();
  const graduate = queryClient.getQueryData<CourseGraduateDetail>(
    getGraduateDetailQueryKey(institutionId, courseId, graduateId)
  );

  return (
    <>
      <MentoringCommentsList commentList={graduate?.comments} />
      <MentoringCommentsWrite graduateName={graduate?.graduateName} />
    </>
  );
};

export default MentoringComments;

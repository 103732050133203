import {useState} from 'react';

import {RouteUrl} from '@/shared/constant';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Button} from '@/shared/ui/button';
import {Card} from '@/shared/ui/card';
import {Table} from '@/shared/ui/table';
import {Title} from '@/shared/ui/typography';
import {encodeIds} from '@/shared/utils/idEncoder';
import {useSearchParams} from 'react-router-dom';
import {useCopyToClipboard} from 'usehooks-ts';

import useGetCourseListQuery, {
  Course,
} from '../../queries/useGetCourseListQuery';
import CompleteModal from '../CompleteModal';
import CourseDeleteModal from '../CourseDeleteModal';
import CourseUpdateModal, {CourseUpdateModalProps} from '../CourseUpdateModal';

import styles from './CourseListCard.module.scss';

const CourseListCard: React.FC = () => {
  const [, copyToClipboard] = useCopyToClipboard();
  const [searchParam, setSearchParam] = useSearchParams();
  const page = parseInt(searchParam.get('page')) || 1;

  const {user} = useCurrentUserQuery();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const {data, isLoading} = useGetCourseListQuery(user.institutionId, page);

  const [openCourseDeleteModal, setOpenCourseDeleteModal] = useState(false);
  const [openCourseUpdateModal, setOpenCourseUpdateModal] = useState(false);
  const [courseDetails, setCourseDetails] =
    useState<CourseUpdateModalProps['courseDetails']>();
  const handleOpenCourseUpdateModal = (course: Course) => {
    setCourseDetails({
      institutionId: user.institutionId,
      ...course,
    });
    setOpenCourseUpdateModal(true);
  };

  const generateUrl = (course: Course) => {
    const code = encodeIds(
      user.institutionId,
      course.courseId,
      new Date(course.createdAt).getSeconds()
    );
    const url = `https://${
      window.location.host
    }${RouteUrl.GRADUATE_COURSE.replace(':code', code)}`;
    return url;
  };

  const [copiedUrl, setCopiedUrl] = useState<string>(null);
  const handleCopyUrl = (course: Course) => {
    const url = generateUrl(course);
    copyToClipboard(url);
    setCopiedUrl(url);
  };

  return (
    <>
      <Card className={styles.container}>
        <div className={styles.header}>
          <Title>생성 코스 정보</Title>
          <Button
            size="medium"
            variant="outlined"
            disabled={selectedRowKeys.length === 0}
            onClick={() => setOpenCourseDeleteModal(true)}
          >
            선택 코스 삭제
          </Button>
        </div>
        <Table<Course>
          columns={[
            {key: 'courseId', name: '코스번호', align: 'left'},
            {
              key: 'courseName',
              name: '코스명',
              align: 'left',
              fontWeight: 'bold',
            },
            {key: 'startDate', name: '교육 시작일'},
            {key: 'endDate', name: '교육 종료일'},
            {key: 'trackingEndDate', name: '취업 트레킹 종료일'},
            {
              key: 'createdAt',
              name: '코스 등록일',
              render: date => date?.replace(/T.*/, ''),
            },
            {
              key: 'couserManage',
              name: '코스 관리',
              render(_, record) {
                return (
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    className={styles.modifyButton}
                    onClick={() => handleOpenCourseUpdateModal(record)}
                  >
                    수정
                  </Button>
                );
              },
            },
            {
              key: 'courseApply',
              name: '코스 신청',
              render(_, record) {
                return (
                  <div className={styles.buttons}>
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      onClick={() => {
                        const url = generateUrl(record);
                        window.open(url, '_blank');
                      }}
                    >
                      보기
                    </Button>
                    <div className={styles.division} />
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      onClick={() => handleCopyUrl(record)}
                    >
                      공유
                    </Button>
                  </div>
                );
              },
            },
            {
              key: 'graduateCount',
              name: '등록 수료생수',
            },
          ]}
          data={data?.content}
          rowKey="courseId"
          pagination={{
            current: page,
            pageSize: 10,
            total: data?.totalElements,
            onChange: page => {
              setSearchParam({page: String(page)});
            },
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          loading={isLoading}
        />
      </Card>

      {openCourseUpdateModal && (
        <CourseUpdateModal
          open
          onClose={() => setOpenCourseUpdateModal(false)}
          courseDetails={courseDetails}
        />
      )}

      {openCourseDeleteModal && (
        <CourseDeleteModal
          open
          onClose={() => setOpenCourseDeleteModal(false)}
          institutionId={user.institutionId}
          courseIds={selectedRowKeys.map(key => Number(key))}
          onSuccess={() => {
            setSelectedRowKeys([]);
            setSearchParam({page: '1'});
          }}
        />
      )}

      <CompleteModal
        open={copiedUrl !== null}
        onClose={() => setCopiedUrl(null)}
        onOk={() => {
          window.open(copiedUrl, '_blank');
          setCopiedUrl(null);
        }}
        title="링크가 복사됐습니다. 링크를 공유해 보세요."
        content={
          <>
            수료생들이 신청을 완료해야
            <br />
            수료생들의 취업 활동 내역을 트래킹할 수 있습니다.
          </>
        }
      />
    </>
  );
};

export default CourseListCard;

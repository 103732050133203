import React from 'react';

import {weaver} from '@/shared/lib/weaver';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Button} from '@/shared/ui/button';
import {IconShare} from '@/shared/ui/icon';
import {useToast} from '@wanteddev/wds';
import {Outlet, useLocation} from 'react-router-dom';
import {useEffectOnce, useCopyToClipboard} from 'usehooks-ts';

import GuideSection from './components/GuideSection';
import PrivacySection from './components/PrivacySection';
import StartedSection from './components/StartedSection';
import useCourseId from './queries/useCourseId';

import styles from './GraduateCourseApply.module.scss';

const GraduateCourseApply: React.FC = () => {
  const toast = useToast();
  const location = useLocation();
  const [, copy] = useCopyToClipboard();
  const {user} = useCurrentUserQuery();
  const {institutionId, courseId} = useCourseId();

  useEffectOnce(() => {
    if (user) {
      weaver.setUserId(user.wantedUserId);
      weaver.setUserProperties({
        one_id: user.oneid,
        institutionId: String(institutionId),
        courseId: String(courseId),
      });
    }

    weaver.sendEvent('eas__registration__view');
  });

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}${location.pathname}`;
    try {
      if ('share' in navigator && navigator.share) {
        navigator.share({url: shareUrl});
      } else {
        await copy(shareUrl);
        toast({
          variant: 'success',
          content: '링크가 복사됐습니다. 링크를 공유해 보세요.',
        });
      }
    } catch (error) {
      toast({
        variant: 'warning',
        content: '링크 복사에 실패하였습니다. 다시 시도해보세요.',
      });
    }
  };

  return (
    <div className={styles.GraduateCourseApply}>
      <div className={styles.header}>
        <h1 className={styles.title}>취업지원시스템 신청하기</h1>
      </div>
      <main className={styles.main}>
        <StartedSection />
        <PrivacySection />
        <GuideSection />
        <Button
          variant="text"
          className={styles.shareButton}
          onClick={handleShare}
        >
          <IconShare />
        </Button>
      </main>
      <Outlet />
    </div>
  );
};

export default GraduateCourseApply;

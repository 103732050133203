/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useEffect} from 'react';

type Params = {
  open: boolean;
  disableEscapeKeyDown?: boolean;
  disableRouterPop?: boolean;
  disableScrollLock?: boolean;
  onClose?: (event: any, reason: string) => void;
};

const useModalManagement = ({onClose, disableEscapeKeyDown}: Params) => {
  // 모달 닫기 by keydown, click
  const handleClose = useCallback(
    (
      event:
        | KeyboardEvent
        | MouseEvent
        | React.MouseEvent
        | React.KeyboardEvent
        | undefined,
      reason: string
    ) => {
      onClose?.(event ?? '', reason);
    },
    [onClose]
  );

  useEffect(() => {
    if (disableEscapeKeyDown) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose(event, 'escapeKeyDown');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [disableEscapeKeyDown, handleClose]);

  return {handleClose};
};

export default useModalManagement;

import {Button} from '@/shared/ui/button';
import {IconClose} from '@/shared/ui/icon';
import {Modal, type ModalProps} from '@/shared/ui/modal';
import {Typography} from '@/shared/ui/typography';

import styles from './paid-guide-modal.module.scss';

interface PaidGuideModalProps extends Omit<ModalProps, 'children'> {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  messages?: string[];
}

export const PaidGuideModal: React.FC<PaidGuideModalProps> = ({
  title = '🚀 새롭게 출시된 EAS PRO',
  subtitle = '🎉 EAS PRO가 드디어 출시되었습니다!',
  messages = [
    '지금 바로 EAS PRO를 체험해보세요. 출시를 기념하여 2주간 무료 체험 기회를 제공합니다!',
    '원티드 취업지원시스템과의 통합으로 교육기관의 수료생 관리와 취업 연계가 한층 더 강화되었습니다.',
    'AI 매칭 플랫폼을 통해 수료생과 기업 간의 연결을 최적화하고 실질적으로 지원합니다.',
    'EAS PRO 구독을 통해 기관과 수료생 모두에게 새로운 가능성을 열어줍니다.',
  ],
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose} bodyClassName={styles.PadinGuideModal}>
      <button
        type="button"
        onClick={onClose}
        aria-label="Close modal"
        className={styles.PadinGuideModal__closeButton}
      >
        <IconClose />
      </button>
      <Typography variant="heading1" size="large">
        {title}
      </Typography>
      <div className={styles.PadinGuideModal__message}>
        <Typography variant="body1" size="small">
          {subtitle}
        </Typography>
        {messages && (
          <ul>
            {messages.map(msg => (
              <li key={msg}>{msg}</li>
            ))}
          </ul>
        )}
      </div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        href="https://docs.google.com/forms/d/e/1FAIpQLSfqCf1z5NXjhsnpY66NOxLkJZrgCz6Ni0RLOOltV8qf9mk55A/viewform"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.PassnotePaidGuide__box__button}
      >
        🌐 EAS PRO 구독하기
      </Button>
    </Modal>
  );
};

import client from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {weaver} from '@/shared/lib/weaver';
import {Button} from '@/shared/ui/button';
import {IconClose} from '@/shared/ui/icon';
import {Input} from '@/shared/ui/input';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {ModalTitle} from '@/shared/ui/modal-title';
import {Typography} from '@/shared/ui/typography';
import * as Sentry from '@sentry/react';
import {useMutation} from '@tanstack/react-query';
import {useToast, FlexBox} from '@wanteddev/wds';
import {isAxiosError} from 'axios';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useBoolean} from 'usehooks-ts';

import styles from './RegistrationInstitutionModal.module.scss';

type FormData = {
  institutionName: string;
  companyRegistrationNumber: string;
};

const RegistrationInstitutionModal: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const {value: complete, setTrue: setComplete} = useBoolean(false);
  const {register, handleSubmit} = useForm<FormData>();

  const hanleClose = () => {
    navigate(RouteUrl.HOME, {replace: true});
  };

  const mutation = useMutation({
    mutationFn: (data: FormData) => {
      return client.post('/eas/manager/free-start', data);
    },
    onSuccess: () => {
      weaver.sendEvent('eas__onboarding__freestart__done');
      setComplete();
    },
    onError: error => {
      console.error(error);

      if (!isAxiosError(error)) {
        Sentry.captureException(error);
        toast({
          variant: 'warning',
          content: '시스템 오류가 발생하였습니다.',
        });
        return;
      }

      const status = error.response?.status;
      const errorData = error.response?.data;

      if (status === 409) {
        toast({
          variant: 'warning',
          content: '이미 등록된 기관입니다.',
        });
        return;
      }

      if (errorData.code === 'INVALID_COMPANY_REGISTRATION_NUMBER') {
        toast({
          variant: 'warning',
          content: '유효하지 않은 사업자 등록 번호입니다.',
        });
        return;
      }

      toast({
        variant: 'warning',
        content: '시스템 오류가 발생하였습니다.',
      });
    },
  });

  const onSubmit = (data: FormData) => {
    mutation.mutate(data);
  };

  return (
    <Modal
      open
      onClose={hanleClose}
      disableBackdropClick
      bodyClassName={styles.RegistrationInstitutionModal}
    >
      {!complete && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
          <button
            type="button"
            onClick={hanleClose}
            aria-label="Close modal"
            className={styles.closeButton}
          >
            <IconClose />
          </button>
          <ModalTitle>
            <Typography
              variant="heading1"
              size="small"
              weight="bold"
              className={styles.heading1}
            >
              기관 정보를 입력해주세요
            </Typography>
          </ModalTitle>
          <ModalContent className={styles.modalContent}>
            <FlexBox flexDirection="column" flex="1">
              <Typography
                component="label"
                variant="label1"
                size="small"
                weight="bold"
                className={styles.label}
              >
                기관/기업명
              </Typography>
              <Input
                {...register('institutionName', {
                  required: true,
                  validate: value => value.trim().length > 0,
                })}
                required
                autoComplete="off"
                placeholder="기관 또는 기업명을 입력해주세요."
                className={styles.input}
              />
            </FlexBox>
            <FlexBox flexDirection="column" flex="1" sx={{marginTop: 20}}>
              <Typography
                component="label"
                variant="label1"
                size="small"
                weight="bold"
                className={styles.label}
              >
                사업자 등록번호
              </Typography>
              <Input
                {...register('companyRegistrationNumber', {
                  required: true,
                  validate: value => value.trim().length > 0,
                })}
                required
                autoComplete="off"
                placeholder="'-'를 제외한 사업자 등록번호를 입력해주세요."
                className={styles.input}
                pattern="\d{10}"
                onInput={e => {
                  e.currentTarget.setCustomValidity('');
                }}
                onInvalid={e => {
                  e.currentTarget.setCustomValidity(
                    '사업자 등록번호는 10자리 숫자입니다.'
                  );
                }}
              />
            </FlexBox>
            <ul className={styles.message}>
              <li>
                정보 확인을 위해 담당자님이 속해있는 기관 및 기업명을 입력해
                주세요.
              </li>
              <li>
                승인 받은 사용자만 원티드 취업지원시스템을 사용할 수 있습니다.
              </li>
              <li>
                24시간 내에 원티드 담당자가 확인 및 승인 처리 후
                연락드리겠습니다.
              </li>
            </ul>
          </ModalContent>
          <ModalActions>
            <FlexBox justifyContent="center" flex="1">
              <Button
                type="sumbit"
                disabled={mutation.isPending}
                className={styles.okButton}
              >
                확인
              </Button>
            </FlexBox>
          </ModalActions>
        </form>
      )}

      {complete && (
        <>
          <ModalContent className={styles.completeModalContent}>
            <button
              type="button"
              onClick={hanleClose}
              aria-label="Close modal"
              className={styles.closeButton}
            >
              <IconClose />
            </button>
            <Typography
              variant="heading2"
              size="large"
              weight="bold"
              className={styles.heading2}
            >
              시작 신청이 완료되었습니다.
            </Typography>
            <Typography
              variant="body1"
              size="small"
              weight="regular"
              className={styles.message}
            >
              24시간 내에 원티드 담당자가 확인 및 승인 처리 후<br />
              연락드리겠습니다.
            </Typography>
          </ModalContent>
          <ModalActions>
            <FlexBox justifyContent="center" flex="1">
              <Button
                type="button"
                onClick={hanleClose}
                className={styles.okButton}
              >
                확인
              </Button>
            </FlexBox>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};

export default RegistrationInstitutionModal;

import client from '@/shared/api/client';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

const useDeleteCourse = (
  institutionId: number,
  options?: UseMutationOptions<AxiosResponse, unknown, number[], unknown>
) => {
  return useMutation({
    mutationFn: courseIds =>
      client.delete(`/eas/dashboard/institution/${institutionId}/course`, {
        params: {courseIds},
      }),
    ...options,
  });
};

export default useDeleteCourse;

import {useEffect} from 'react';

import CompleteModal from '@/pages/Dashboard/CoursesManage/components/CompleteModal';
import {weaver} from '@/shared/lib/weaver';
import {useGetMeQuery} from '@/shared/queries/useGetMeQuery';
import {Loading} from '@/shared/ui/loading';
import {useNavigate, useLocation} from 'react-router-dom';

import useCourseId from '../CourseApply/queries/useCourseId';
import useGraduateApply from '../CourseApply/queries/useGraduateApplyMutate';

const CourseApplyCompleteModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {courseId} = useCourseId();
  const {data: user} = useGetMeQuery({suspense: true});
  const {mutate, isPending, isError, error} = useGraduateApply(courseId, {
    throwOnError: false,
    onSuccess: () => weaver.sendEvent('eas__registration__registerPopup__done'),
  });

  const navigateToPreviousPage = () => {
    navigate(location.pathname.replace('/complete', ''), {replace: true});
  };

  const renderCompleteModalContent = () => {
    if (error?.response?.data?.code === 'DUPLICATED') {
      return '원티드 취업지원시스템에 이미 신청 완료되었습니다.';
    }
    return '원티드 취업지원시스템에 신청 완료되었습니다.';
  };

  useEffect(() => {
    if (user) {
      weaver.setUserId(user.wantedUserId);
      weaver.setUserProperties({one_id: user.oneid});
      mutate();
    }
  }, [user, mutate]);

  if (isPending) {
    return (
      <CompleteModal open>
        <Loading />
      </CompleteModal>
    );
  }

  if (isError && error?.response?.data?.code !== 'DUPLICATED') {
    return (
      <CompleteModal open title="시스템 에러" onClose={navigateToPreviousPage}>
        <p>알 수 없는 오류가 발생했습니다.</p>
      </CompleteModal>
    );
  }

  return (
    <CompleteModal
      open
      title={renderCompleteModalContent()}
      onClose={navigateToPreviousPage}
    >
      {user?.name}님의 즐거운 커리어 여정을 위해
      <br />
      취업 상황에 맞는 도움 되는 가이드를 제공해 드리겠습니다.
    </CompleteModal>
  );
};

export default CourseApplyCompleteModal;

import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';

import useGetCourseNamesQuery, {CourseName} from './queries/useGetCourseNames';

export const StateContext = createContext<{
  courseNames: CourseName[];
  selectedCourseIds: string[];
}>({
  courseNames: [],
  selectedCourseIds: [],
});
export const DispatchContext = createContext<
  React.Dispatch<React.SetStateAction<string[]>> | undefined
>(undefined);

export const useCourseNames = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedCourseIds must be used within a CourseProvider'
    );
  }
  return context.courseNames;
};

export const useSelectedCourseIds = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedCourseIds must be used within a CourseProvider'
    );
  }
  return context.selectedCourseIds;
};

export const useSetSelectedCourseIds = () => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error(
      'useSetSelectedCourseIds must be used within a CourseProvider'
    );
  }
  return context;
};

export const CourseProvider = ({children}: PropsWithChildren) => {
  const {user} = useCurrentUserQuery();
  const {data: courseNames} = useGetCourseNamesQuery(user.institutionId, {
    suspense: true,
  });

  const getDefaultSelectedCourseIds = (courseNames: CourseName[]): string[] => {
    // 1. 등록된 전체 코스가 5개 이하인 경우 => 모든 코스 선택.
    if (courseNames.length <= 5) {
      return courseNames.map(course => String(course.courseId));
    }

    // 수료생 데이터가 있는 코스만 필터링
    const graduatedCourseNames = courseNames.filter(
      course => course.calculatedGraduateCount > 0
    );

    // 2. 수료생 데이터가 있는 코스가 5개 이상인 경우 => 수료생 데이터가 있는 코스 중에서 최근 종료된 코스 순으로 상위 5개 선택.
    if (graduatedCourseNames.length >= 5) {
      return graduatedCourseNames
        .slice(0, 5)
        .map(course => String(course.courseId));
    }

    // 수료생 데이터가 없는 코스만 필터링
    const ungraduatedCourseNames = courseNames.filter(
      course => course.calculatedGraduateCount === 0
    );

    // 3. 수료생 데이터가 있는 코스가 5개 이하인 경우 => 수료생 데이터가 있는 코스 순 + 최근 종료된 코스 순으로 상위 5개 선택.
    return [...graduatedCourseNames, ...ungraduatedCourseNames]
      .slice(0, 5)
      .map(course => String(course.courseId));
  };

  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>(
    getDefaultSelectedCourseIds(courseNames)
  );
  const contextValue = useMemo(
    () => ({selectedCourseIds, courseNames}),
    [selectedCourseIds, courseNames]
  );

  return (
    <StateContext.Provider value={contextValue}>
      <DispatchContext.Provider value={setSelectedCourseIds}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

import {decodeIds} from '@/shared/utils/idEncoder';
import {useParams} from 'react-router-dom';

const useCourseId = () => {
  const params = useParams();
  const [institutionId, courseId] = decodeIds(params['code']);
  return {
    institutionId,
    courseId,
  };
};

export default useCourseId;

import {getData} from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';

export type ComparisonByCourse = {
  /** 코스 고유 아이디 */
  courseId: number;
  /** 코스명 */
  courseName: string;

  /** 수료 후 n주차 */
  weeksAfterEnd: number;
  /** 등록된 수료생 수 */
  graduateCount: number;
  /** 이력서 지원 수 */
  applyCount: number;
  /** 1인당 이력서 지원수 */
  applyCountPerPerson: number;
  /** 이력서 합격 수 */
  applyPassCount: number;
  /** 이력서 합격률 */
  applyPassRate: number;
  /** 면접 합격 수 */
  hiredCount: number;
  /** 면접 합격률 */
  hiredRate: number;

  /** 구직자 수 */
  jobSeekerCount: number;
  /** 구직비율 */
  jobSeekerRate: number;
  /** 최종 취업자 수 */
  employedCount: number;
  /** 취업까지 평균 소요일 */
  averageDaysHoHired: number;
  /** 최종 취업률 */
  employedRate: number;
  /** 최대 연봉 */
  maxSalary: number;
  /** 최소 연봉 */
  minSalary: number;

  /** 코스 시작일 */
  startDate: string;
};

export function getComparisonByCourseQueryKeys(
  institutionId: number,
  courseIds?: string[]
) {
  const baseKeys = [RouteUrl.BY_COURSE, 'COMPARISON_BY_COURSE', institutionId];
  if (courseIds?.length) {
    return [
      ...baseKeys,
      ...[...courseIds].sort((a, b) => parseInt(a) - parseInt(b)),
    ];
  }
  return baseKeys;
}

/** 코스 별 비교 */
export default function useGetComparisonByCourseQuery(
  institutionId: number,
  courseIds?: string[],
  options?: Omit<
    QueryObserverOptions<ComparisonByCourse[]>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery({
    queryKey: getComparisonByCourseQueryKeys(institutionId, courseIds),
    queryFn: ({signal}) =>
      getData(`/eas/dashboard/institution/${institutionId}/course`, {
        params: {courseIds},
        signal,
      }),
    ...options,
  });
}

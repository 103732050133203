import {useState} from 'react';

import {weaver} from '@/shared/lib/weaver';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Button} from '@/shared/ui/button';
import {Card} from '@/shared/ui/card';
import {Input} from '@/shared/ui/input';
import {Title, Typography} from '@/shared/ui/typography';

import CourseCreateModal from '../CourseCreateModal';
import CourseCSVFileDownloadModal from '../CourseCSVFileDownloadModal';
import CourseCSVFileUploadModal from '../CourseCSVFileUploadModal';

import styles from './CourseCreateCard.module.scss';

const CourseCreateCard: React.FC = () => {
  const {user} = useCurrentUserQuery();

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const handleAttachCsvFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = event => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        setCsvFile(file);
        openModal('fileUpload');
      }
    };
    input.click();
  };

  const [courseDetails, setCourseDetails] = useState({
    institutionId: user.institutionId,
    courseName: '',
    startDate: '',
    endDate: '',
  });
  const isDisabled = Object.values(courseDetails).some(detail => !detail);
  const handleInputChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCourseDetails(prevState => ({
        ...prevState,
        [name]: event.target.value,
      }));
    };

  const [modalState, setModalState] = useState({
    create: false,
    fileUpload: false,
    fileDownload: false,
  });
  const openModal = (name: keyof typeof modalState) => {
    setModalState(prev => ({...prev, [name]: true}));
  };
  const handleCloseModal = () => {
    setModalState({create: false, fileUpload: false, fileDownload: false});
  };

  return (
    <>
      <Card className={styles.CourseCreateCard}>
        <Title className={styles.title}>코스 생성하기</Title>

        <div className={styles.form}>
          <div className={styles.formItem}>
            <label htmlFor="courseName">코스명</label>
            <Input
              required
              id="courseName"
              name="courseName"
              placeholder="코스명을 입력해주세요"
              className={styles.inputCourseName}
              value={courseDetails.courseName}
              onChange={handleInputChange('courseName')}
            />
          </div>
          <div className={styles.formItem}>
            <label>교육일 설정</label>
            <Input
              required
              type="date"
              name="startDate"
              className={styles.inputCoursePeriod}
              value={courseDetails.startDate}
              onChange={handleInputChange('startDate')}
            />
            <span className={styles.tilde}>~</span>
            <Input
              required
              type="date"
              name="endDate"
              className={styles.inputCoursePeriod}
              value={courseDetails.endDate}
              onChange={handleInputChange('endDate')}
            />
          </div>
          <Button
            type="submit"
            size="large"
            disabled={isDisabled}
            onClick={() => {
              weaver.sendEvent('eas__course__create__click');
              openModal('create');
            }}
          >
            코스 생성
          </Button>
        </div>

        <div className={styles.createMultipleCourses}>
          <Typography component="h4" variant="body1" size="small" weight="bold">
            한 번에 여러 코스 생성
          </Typography>
          <Button
            type="button"
            variant="text"
            onClick={() => {
              weaver.sendEvent('eas__course__csvDownload__click');
              openModal('fileDownload');
            }}
          >
            CSV 다운로드
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={() => {
              weaver.sendEvent('eas__course__csvUpload__click');
              handleAttachCsvFile();
            }}
          >
            CSV 업로드
          </Button>
        </div>
      </Card>

      {/* 코스 생성 모달  */}
      {modalState.create && (
        <CourseCreateModal
          open
          onClose={handleCloseModal}
          courseDetails={courseDetails}
          reset={() => {
            setCourseDetails({
              institutionId: user.institutionId,
              courseName: '',
              startDate: '',
              endDate: '',
            });
          }}
        />
      )}

      {/* CSV 파일 업로드 모달  */}
      {modalState.fileUpload && (
        <CourseCSVFileUploadModal
          open
          onClose={handleCloseModal}
          file={csvFile}
        />
      )}

      {/* CSV 파일 다운로드 모달  */}
      {modalState.fileDownload && (
        <CourseCSVFileDownloadModal open onClose={handleCloseModal} />
      )}
    </>
  );
};

export default CourseCreateCard;

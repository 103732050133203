import {CourseGraduateDetail} from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {formatPhoneNumber} from '@/shared/utils/phoneNumberUtils';
import {FlexBox, ModalContentItem, Typography} from '@wanteddev/wds';

interface GraduateProps {
  graduate: CourseGraduateDetail;
}

const GraduateInfo = ({graduate}: GraduateProps) => {
  return (
    <ModalContentItem gap="8px">
      <Typography variant="headline1" weight="bold">
        {graduate.graduateName}
      </Typography>
      <FlexBox flexDirection="column" gap="2px">
        <FlexBox gap="8px">
          <Typography
            variant="label2"
            weight="regular"
            color="palette.label.neutral"
          >
            연락처
          </Typography>
          <Typography
            variant="label2"
            weight="bold"
            color="palette.label.neutral"
          >
            {formatPhoneNumber(graduate.graduateMobile)}
          </Typography>
        </FlexBox>
        <FlexBox gap="8px">
          <Typography
            variant="label2"
            weight="regular"
            color="palette.label.neutral"
          >
            이메일
          </Typography>
          <Typography
            variant="label2"
            weight="bold"
            color="palette.label.neutral"
          >
            {graduate.graduateEmail}
          </Typography>
        </FlexBox>
      </FlexBox>
    </ModalContentItem>
  );
};

export default GraduateInfo;

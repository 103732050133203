import {useState} from 'react';

import {useIsPaidSubscriptionFee} from '@/shared/hooks/usePaidSubscriptionsFee';
import {Button} from '@/shared/ui/button';
import {Card} from '@/shared/ui/card';
import {Typography} from '@/shared/ui/typography';

import CSVDownloadModal from '../CSVDownloadModal';

import styles from './download-panel.module.scss';

interface CourseCompletionDataDownloadPanelProps {
  institutionId: number;
}

export const CourseCompletionDataDownloadPanel: React.FC<
  CourseCompletionDataDownloadPanelProps
> = ({institutionId}) => {
  const isPaidSubscriptionFee = useIsPaidSubscriptionFee();
  const [openCSVDownloadModal, setOpenCSVDownloadModal] = useState(false);

  const handleOpenDownloadModal = () => {
    setOpenCSVDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setOpenCSVDownloadModal(false);
  };

  return (
    <Card className={styles.CourseCompletionDataDownloadPanel}>
      <Typography component="h4" variant="body1" size="small" weight="bold">
        코스 별 수료생 데이터
      </Typography>
      {isPaidSubscriptionFee && (
        <Button
          variant="text"
          onClick={handleOpenDownloadModal}
          endIcon={
            <svg width="12" height="12" fill="#36F">
              <path d="M3.345 9.72a.75.75 0 1 0 1.06 1.06l4.25-4.25a.75.75 0 0 0 0-1.06l-4.25-4.25a.75.75 0 0 0-1.06 1.06L7.065 6l-3.72 3.72z" />
            </svg>
          }
        >
          다운받기
        </Button>
      )}

      {openCSVDownloadModal && (
        <CSVDownloadModal
          key={institutionId}
          institutionId={institutionId}
          open={openCSVDownloadModal}
          onClose={handleCloseDownloadModal}
        />
      )}
    </Card>
  );
};

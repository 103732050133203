import {lazy, Suspense} from 'react';

import UnauthorizedError from '@/shared/api/errors/UnauthorizedError';
import {doOneIDlogin} from '@/shared/lib/oneId/oneId';
import {Loading} from '@/shared/ui/loading';
import {UnknownError} from '@/shared/ui/unknown-error';
import * as Sentry from '@sentry/react';

const LazyTermsAgreeTrainee = lazy(() => import('./TermsAgreeTrainee'));

export default function TermsAgreeTrainee() {
  const handleError: Sentry.FallbackRender = ({error, componentStack}) => {
    // 접근 권한 없음.
    if (error instanceof UnauthorizedError) {
      doOneIDlogin('graduate'); // 로그인 페이지로 이동
      return;
    }

    // 기타 에러
    console.error(error, componentStack);
    return <UnknownError />;
  };

  return (
    <Sentry.ErrorBoundary fallback={handleError}>
      <Suspense fallback={<Loading absoluteCenter />}>
        <LazyTermsAgreeTrainee />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

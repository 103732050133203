import {Fragment, MouseEventHandler} from 'react';

import {CourseGraduateDetail} from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {EllipsisTooltip} from '@/shared/ui/ellipsis-tooltip';
import {SearchCompanyNamesButton} from '@/shared/ui/search-compnay-names-button';
import {NopaidCompanyAccessButton} from '@/widgets/nopaid-company-access-button/nopaid-company-access-button';
import {Box, SxProp, Theme} from '@wanteddev/wds';
import classNames from 'classnames';

import {generateGraduateApplicationStatusLists} from '../../helpers';

import CompanyAndPosition from './CompanyAndPosition';
import JobAnnouncementLink from './JobAnnouncementLink';
import {TableHeaderColumn} from './TableHeaderColumn';

import styles from './GraduateHistoryTable.module.scss';

interface GraduateHistoryTableProps {
  graduate: CourseGraduateDetail;
  institutionId: string;
  isPaidSubscriptionFee: boolean;
  openPaidGuideModal: MouseEventHandler;
}

const tableStyles: SxProp = (theme: Theme) => ({
  width: 800,
  height: 400,
  tableLayout: 'fixed',
  borderCollapse: 'separate',
  borderSpacing: 0,
  borderWidth: 1,
  borderColor: theme.palette.line.solid.neutral,
  borderStyle: 'solid',
  borderRadius: 12,
  overflow: 'hidden',
});

const tbodyStyles: SxProp = {
  height: 322,
  overflowY: 'scroll',
};

const tdStyle: SxProp = (theme: Theme) => ({
  verticalAlign: 'top',
  textAlign: 'left',
  height: '100%',

  '&:nth-child(n+2)': {
    borderLeftWidth: 1,
    borderColor: theme.palette.line.solid.neutral,
    borderStyle: 'solid',
  },
});

const GraduateHistoryTable = ({
  institutionId,
  graduate,
  isPaidSubscriptionFee = false,
  openPaidGuideModal: handleOpenPaidGuideModal,
}: GraduateHistoryTableProps) => {
  const {employedList, interviewPassedList, applyPassedList, applyList} =
    generateGraduateApplicationStatusLists(graduate);

  return (
    <Box as="table" sx={tableStyles} className={styles.GraduateHistoryTable}>
      <thead>
        <tr>
          <TableHeaderColumn title="이력서 지원" count={graduate.applyCount} />
          <TableHeaderColumn
            title="이력서 합격"
            count={graduate.applyPassCount}
            subtitle={`이력서 합격률 ${graduate.applyPassRate}%`}
          />
          <TableHeaderColumn
            title="원티드 합격"
            count={graduate.interviewPassCount}
            subtitle={`원티드 합격률 ${graduate.interviewPassRate}%`}
          />
          <TableHeaderColumn title="취업" count={employedList.length} />
        </tr>
      </thead>
      <Box as="tbody" sx={tbodyStyles}>
        <tr>
          <Box as="td" sx={tdStyle}>
            <ul className={styles.list}>
              {applyList.map(item => (
                <li key={`${item.wdId}_${item.applyId}_${item.kreditjobId}`}>
                  <CompanyAndPosition
                    company={item.companyName}
                    position={item.position}
                  />
                </li>
              ))}
            </ul>
          </Box>
          <Box as="td" sx={tdStyle}>
            <ul className={styles.list}>
              {applyPassedList.map(item => {
                const companyName = isPaidSubscriptionFee
                  ? item.companyName
                  : item.companyName.replace(/(?<=.)./g, '*'); // 무료플랜의 경우에는 회사명 마스킹 처리
                return (
                  <li key={`${item.wdId}_${item.applyId}_${item.kreditjobId}`}>
                    <JobAnnouncementLink
                      wdId={item.wdId}
                      onClick={handleOpenPaidGuideModal}
                    >
                      <CompanyAndPosition
                        company={companyName}
                        position={item.position}
                        isPaid={isPaidSubscriptionFee}
                      />
                    </JobAnnouncementLink>
                  </li>
                );
              })}
            </ul>
          </Box>
          <Box as="td" sx={tdStyle}>
            <ul className={styles.list}>
              {interviewPassedList.map(item => {
                const companyName = isPaidSubscriptionFee
                  ? item.companyName
                  : item.companyName.replace(/(?<=.)./g, '*');
                return (
                  <li key={`${item.wdId}_${item.applyId}_${item.kreditjobId}`}>
                    <CompanyAndPosition
                      company={companyName}
                      position={item.position}
                      isPaid={isPaidSubscriptionFee}
                    />
                  </li>
                );
              })}
            </ul>
          </Box>
          <Box as="td" sx={tdStyle} className={styles.employedList}>
            {employedList.map(item => {
              return (
                <Fragment
                  key={`${item.wdId}_${item.applyId}_${item.kreditjobId}`}
                >
                  {isPaidSubscriptionFee ? (
                    <SearchCompanyNamesButton
                      kreditjobId={item.kreditjobId}
                      companyName={item.companyName}
                      institutionId={Number(institutionId)}
                      wrapperClassName={styles.CompanyListModalButton}
                      className={classNames(
                        styles.companyName,
                        styles.CompanyListModalButton__button
                      )}
                    />
                  ) : (
                    <NopaidCompanyAccessButton companyName={item.companyName} />
                  )}
                  <EllipsisTooltip
                    content={item.position}
                    className={styles.positionName}
                  />
                </Fragment>
              );
            })}
          </Box>
        </tr>
      </Box>
    </Box>
  );
};

export default GraduateHistoryTable;

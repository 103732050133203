import {Card} from '@/shared/ui/card';
import {Typography} from '@wanteddev/wds';

import PassnoteInfoMessage from './components/PassnoteInfoMessage';

import styles from './PassnoteInfo.module.scss';

const PassnoteInfoDummy: React.FC = () => {
  return (
    <Card className={styles.PassnoteInfo}>
      <PassnoteInfoMessage>
        <Typography as="p" variant="headline1" weight="bold">
          평균 1인당 지원 수가 높은 편입니다. 이력서 합격한 수료생을 대상으로
          면접 코칭을 진행해 보세요.
        </Typography>
        <Typography as="p" variant="headline1">
          👉 원티드의 평균 1인당 지원 수는 34.4건입니다.
        </Typography>
        <Typography as="p" variant="headline1">
          👉 원티드 취업지원시스템 등록된 기관들의 평균 1인당 지원수는
          21.2건입니다.
        </Typography>
        <Typography as="p" variant="headline1">
          👉 면접 코칭을 통해 더 많은 최종 취업자를 배출해 보세요.
        </Typography>
      </PassnoteInfoMessage>
    </Card>
  );
};

export default PassnoteInfoDummy;

import {getData} from '@/shared/api/client';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';

import {ManagerUser} from './useGetManagerUserSuspenseQuery';

export const GET_ME_QUERY_KEYS = ['GET_ME_QUERY'];

export function useGetMeQuery(
  options?: Omit<QueryObserverOptions<ManagerUser>, 'queryKey' | 'queryFn'>
) {
  return useQuery({
    queryKey: GET_ME_QUERY_KEYS,
    queryFn: ({signal}) => getData<ManagerUser>('/eas/manager/me', {signal}),
    ...options,
  });
}

type Props = {
  className?: string;
};

const NoteImage: React.FC<Props> = ({className}) => {
  return (
    <svg
      width="120"
      height="120"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_56_1006)">
        <path
          d="M76.8366 63.4714C76.8366 63.4714 74.1917 74.9379 67.7799 74.9379C65.9685 74.9379 63.9969 74.2012 60.8391 69.1246L43.4149 38.9689L17.9919 82.9453C14.6097 88.8066 18.8255 96.1414 25.606 96.1414C42.6775 96.1414 64.7503 96.1414 65.1671 96.1414C65.1671 96.1414 67.6677 96.0933 68.8859 95.9172C70.1523 95.741 71.3866 95.4848 72.5888 95.1645C87.2559 91.1768 92.8662 74.9539 94.6776 68.2918L76.8526 63.4714H76.8366Z"
          fill="#44A5FF"
        />
        <path
          d="M24.5802 48.081C24.5321 46.6717 24.9649 45.6628 25.526 44.9261C25.3977 44.3336 27.145 42.9403 27.0648 42.3157C25.6542 30.9933 31.6172 26.4452 41.9884 24.8277C52.4397 23.8348 59.4606 27.1338 60.8873 38.4562C60.9674 39.0808 62.875 39.6413 62.907 40.2498C63.6283 40.8264 64.3016 41.6912 64.6062 43.0684C65.039 45.0382 64.3978 46.6717 63.3238 47.9209C62.1216 49.3142 61.0155 50.7555 60.0858 52.3569C57.2967 57.1774 52.6 60.6686 46.6049 61.5814C40.5778 62.174 35.1598 59.9479 31.2646 55.9603C29.9822 54.6471 28.5556 53.51 27.0328 52.4691C25.6863 51.5242 24.6443 50.0989 24.5802 48.081Z"
          fill="#FED3C6"
        />
        <path
          d="M51.029 46.5433C50.2276 46.5754 49.5383 45.9027 49.5223 45.054C49.4902 44.2052 50.1314 43.5005 50.9329 43.4685C51.7343 43.4365 52.4236 44.1091 52.4396 44.9579C52.4717 45.8067 51.8305 46.5113 51.029 46.5433Z"
          fill="#20242D"
        />
        <path
          d="M42.4532 47.6169C41.6518 47.649 40.9625 46.9763 40.9465 46.1276C40.9144 45.2788 41.5556 44.5741 42.3571 44.5421C43.1586 44.5101 43.8478 45.1827 43.8639 46.0315C43.8959 46.8803 43.2547 47.5849 42.4532 47.6169Z"
          fill="#20242D"
        />
        <path
          d="M25.2572 44.4372C24.2954 34.9885 28.8681 26.7816 39.2874 24.3634C40.1209 24.1712 40.9865 24.0111 41.8842 23.8829C42.7818 23.7548 43.6154 23.7228 44.4649 23.7068C55.1727 23.4505 61.6055 30.43 63.0001 39.8307C63.0322 40.0229 63.0642 40.215 63.0963 40.4072L58.7634 40.9546C54.4835 41.4831 49.1296 39.1129 45.2985 34.4847C43.0864 39.7695 36.7547 43.7092 29.6856 44.59L25.3747 45.1345C25.3426 44.9423 25.2755 44.6172 25.2572 44.4372Z"
          fill="url(#paint0_linear_56_1006)"
        />
        <path
          d="M83.5546 64.3203C86.2956 68.388 83.8751 68.7538 89.5015 69.4104C99.8727 70.6275 100.12 59.9465 100.12 59.0232C100.087 54.2028 90.3511 49.6188 87.1452 49.4907C85.8308 49.4426 85.6384 45.487 84.324 46.6881C79.7716 50.868 79.0663 57.6902 83.5546 64.3043V64.3203Z"
          fill="#FF9B7C"
        />
        <path
          d="M98.129 51.003L90.3657 53.0566L89.139 48.4277C88.5728 46.2913 89.8447 44.0828 91.9986 43.5131C94.137 42.9474 96.3475 44.2181 96.9178 46.37L98.1445 50.9989L98.129 51.003Z"
          fill="#FF9B7C"
        />
        <path
          d="M63.0604 67.4836C63.25 67.1689 63.0531 66.7736 62.7064 66.7446L59.3221 66.4219L61.3 70.4944L63.0604 67.4836Z"
          fill="#E60066"
        />
        <path
          d="M60.5275 69.9844L58.5487 65.9135L52.3934 65.3128C51.9385 65.2625 51.4669 65.3521 51.0466 65.5514C50.7466 66.1326 50.345 66.7367 50.6847 67.4029C51.39 68.8657 53.3846 67.8858 54.1041 69.3455C54.8236 70.8052 52.8728 71.8714 53.5923 73.3312C54.3118 74.7909 56.2975 73.6864 57.0199 75.1606C57.097 75.3251 57.179 75.4939 57.1936 75.642C57.1936 75.642 57.319 75.4949 57.3388 75.4451L60.5559 69.9781L60.5275 69.9844Z"
          fill="#FFCDD2"
        />
        <path
          d="M52.4015 72.2295C51.6745 70.8494 53.8083 69.863 53.0663 68.4858C52.3243 67.1086 50.2056 68.0921 49.4636 66.7149C49.128 66.0834 49.4154 65.5129 49.7225 64.9691C49.6926 64.9749 49.6494 64.9997 49.6073 65.0222L31.0647 73.5571C29.6974 74.1802 29.1272 75.7002 29.7776 76.9388L32.8537 82.7395C33.5041 83.9781 35.1519 84.4668 36.5193 83.8437L55.0618 75.3088C55.4886 75.1115 55.8474 74.8137 56.1169 74.4622C56.0882 74.3255 56.0566 74.1752 55.98 74.0337C55.2531 72.6535 53.1464 73.6204 52.4015 72.2295Z"
          fill="#E60066"
        />
        <path
          d="M89.057 69.0365C89.0551 69.0442 89.0471 69.0487 89.0396 69.0463L70.0389 63.1139C68.3746 62.5943 67.7274 60.537 68.6516 59.073C70.4885 56.163 71.7499 53.1546 73.0536 49.6798C73.8981 47.4399 75.9664 41.6259 77.0829 38.4765C77.5052 37.2851 78.7607 36.6327 79.9817 36.969L95.8625 41.3423C97.249 41.7241 98.0361 43.2187 97.5645 44.5745L89.057 69.0365Z"
          fill="#41454F"
        />
        <path
          d="M77.3124 38.508C76.9079 37.7424 76.7903 36.8441 76.9886 35.9944C77.3988 34.2364 79.0324 33.2098 80.6133 33.6888L80.1381 35.7251C79.5669 35.552 78.9823 35.9239 78.8353 36.5538C78.7635 36.8615 78.8048 37.172 78.9528 37.4521L77.3026 38.4894L77.3124 38.508Z"
          fill="url(#paint1_linear_56_1006)"
        />
        <path
          d="M85.1007 39.4266C84.6961 38.661 84.5786 37.7627 84.7769 36.913C85.1871 35.155 86.8206 34.1284 88.4016 34.6073L87.9264 36.6436C87.3551 36.4706 86.7705 36.8425 86.6235 37.4724C86.5517 37.78 86.593 38.0906 86.741 38.3707L85.0908 39.4079L85.1007 39.4266Z"
          fill="url(#paint2_linear_56_1006)"
        />
        <path
          d="M92.8888 40.3465C92.4842 39.5809 92.3667 38.6826 92.565 37.8329C92.9752 36.0749 94.6087 35.0483 96.1897 35.5273L95.7145 37.5636C95.1432 37.3905 94.5586 37.7624 94.4116 38.3923C94.3398 38.7 94.3812 39.0105 94.5292 39.2906L92.8789 40.3279L92.8888 40.3465Z"
          fill="url(#paint3_linear_56_1006)"
        />
        <path
          d="M97.275 41.0139L81.129 36.3547C79.8283 35.9793 78.5136 36.7412 78.1925 38.0564L72.132 62.8811C71.8109 64.1963 72.605 65.5667 73.9058 65.942L90.0517 70.6012C91.3524 70.9766 92.6672 70.2147 92.9882 68.8995L99.0488 44.0748C99.3698 42.7596 98.5757 41.3892 97.275 41.0139Z"
          fill="#ECF0FD"
        />
        <path
          d="M83.313 37.4638C83.7394 35.769 82.7228 34.0222 81.0389 33.556C80.1408 33.3073 79.1853 33.4658 78.4359 33.9985L79.6036 35.6815C79.8745 35.4996 80.2223 35.4298 80.545 35.5191C81.1484 35.6862 81.5152 36.3165 81.3625 36.9238C81.2097 37.5311 80.6159 37.6839 80.0681 37.7135C79.7661 37.7206 79.365 38.0023 79.2264 38.5531C79.0879 39.1039 79.4092 39.6763 79.9987 39.7791C81.5427 40.0858 82.8938 39.1304 83.313 37.4638Z"
          fill="url(#paint4_linear_56_1006)"
        />
        <path
          d="M91.1012 38.384C91.5275 36.6892 90.511 34.9424 88.827 34.4761C87.9289 34.2275 86.9735 34.3859 86.224 34.9187L87.3917 36.6016C87.6626 36.4198 88.0104 36.3499 88.3332 36.4393C88.9366 36.6064 89.3034 37.2367 89.1506 37.844C88.9979 38.4513 88.404 38.6041 87.8562 38.6337C87.5542 38.6407 87.1532 38.9225 87.0146 39.4733C86.876 40.0241 87.1974 40.5965 87.7869 40.6993C89.3309 41.0059 90.6819 40.0506 91.1012 38.384Z"
          fill="url(#paint5_linear_56_1006)"
        />
        <path
          d="M98.8888 39.3041C99.3151 37.6092 98.2986 35.8624 96.6146 35.3962C95.7165 35.1475 94.7611 35.306 94.0116 35.8387L95.1793 37.5217C95.4502 37.3398 95.798 37.27 96.1208 37.3593C96.7242 37.5264 97.091 38.1567 96.9382 38.764C96.7854 39.3713 96.1916 39.5241 95.6438 39.5538C95.3418 39.5608 94.9408 39.8425 94.8022 40.3933C94.6636 40.9441 94.985 41.5165 95.5745 41.6193C97.1185 41.926 98.4695 40.9706 98.8888 39.3041Z"
          fill="url(#paint6_linear_56_1006)"
        />
        <path
          d="M39.0387 73.5181H49.2656C49.4419 73.5181 49.6022 73.5662 49.7785 73.5822L45.0498 70.075C42.5492 68.2173 38.9906 68.9539 37.4357 71.6444L35.4961 75.0075C36.3938 74.0947 37.6441 73.5181 39.0387 73.5181Z"
          fill="#FF9B7C"
        />
        <path
          d="M49.2083 71.8792C49.0319 71.8632 48.8716 71.8152 48.6953 71.8152H38.4684C37.0899 71.8152 35.8235 72.4398 34.9259 73.4487C34.3648 74.0893 33.9481 74.874 33.7237 75.7548C33.5954 76.2513 33.4992 76.7477 33.4992 77.2922V84.8031C33.4992 87.8299 35.7274 90.2801 38.4684 90.2801H47.0122C49.2884 90.2801 51.2761 88.5666 51.8371 86.1163L53.5202 78.6054C54.2576 75.3384 52.1577 72.1995 49.2083 71.8632V71.8792Z"
          fill="#FED3C6"
        />
        <path
          d="M91.4689 63.5013L95.7862 63.4687C98.2366 63.4501 100.218 61.2241 100.194 58.4919L100.143 51.4149C100.125 48.302 97.5277 45.9699 94.7895 46.6066L90.4908 47.5917C88.4139 48.0628 86.931 50.1112 86.961 52.4628L87.0082 58.5917C87.0324 61.3239 89.0339 63.5242 91.4998 63.5099L91.4689 63.5013Z"
          fill="#FED3C6"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_56_1006"
          x1="44.1142"
          y1="20.5167"
          x2="45.0826"
          y2="32.6609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41454F" />
          <stop offset="1" stopColor="#20242D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_56_1006"
          x1="78.5944"
          y1="35.1647"
          x2="77.6181"
          y2="36.0565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44A5FF" />
          <stop offset="1" stopColor="#0487FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_56_1006"
          x1="86.3826"
          y1="36.0833"
          x2="85.4064"
          y2="36.9751"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44A5FF" />
          <stop offset="1" stopColor="#0487FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_56_1006"
          x1="94.1707"
          y1="37.0032"
          x2="93.1945"
          y2="37.895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44A5FF" />
          <stop offset="1" stopColor="#0487FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_56_1006"
          x1="80.0992"
          y1="37.7344"
          x2="79.2028"
          y2="38.5199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44A5FF" />
          <stop offset="1" stopColor="#148EFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_56_1006"
          x1="87.8874"
          y1="38.6545"
          x2="86.9909"
          y2="39.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44A5FF" />
          <stop offset="1" stopColor="#148EFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_56_1006"
          x1="95.675"
          y1="39.5746"
          x2="94.7785"
          y2="40.3601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44A5FF" />
          <stop offset="1" stopColor="#148EFF" />
        </linearGradient>
        <clipPath id="clip0_56_1006">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoteImage;

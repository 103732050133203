import {useState} from 'react';

import {
  FlexBox,
  TextArea,
  Button,
  css,
  typographyStyle,
  type Theme,
} from '@wanteddev/wds';

export interface MentoringCommentModifyFormProps {
  value: string;
  onModify: (newValue: string) => void;
  onCancel: () => void;
}

const formStyles = (theme: Theme) => ({
  marginTop: 6,
  padding: '12px 16px',
  border: 'none',
  borderRadius: 4,
  boxShadow: `inset 0 0 0 1px ${theme.palette.line.normal.normal}`,
  ':has(textarea:focus)': {
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.normal}`,
  },
});

const textAreaStyles = {
  outline: 'none',
  boxShadow: 'none',
  borderRadius: 0,
  '&:has(textarea:focus)': {
    boxShadow: 'none',
    outline: 'none',
  },
};

const textAreaXsStyles = css`
  padding: 0;
  ${typographyStyle('caption1', 'regular')}
`;

const MentoringCommentModifyForm: React.FC<MentoringCommentModifyFormProps> = ({
  value,
  onModify,
  onCancel,
}) => {
  const [newValue, setNewValue] = useState(value);

  const handleModifyComment = () => {
    onModify(newValue);
  };

  const isDisabled = !newValue.trim();

  return (
    <FlexBox as="form" flexDirection="column" gap="8px" sx={formStyles}>
      <TextArea
        value={newValue}
        width="100%"
        autoFocus
        sx={textAreaStyles}
        xs={{sx: textAreaXsStyles}}
        onChange={e => setNewValue(e.target.value)}
      />
      <FlexBox gap="8px" justifyContent="flex-end">
        <Button
          size="small"
          variant="outlined"
          color="assistive"
          onClick={onCancel}
        >
          취소
        </Button>
        <Button
          type="button"
          size="small"
          onClick={handleModifyComment}
          disabled={isDisabled}
        >
          수정
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default MentoringCommentModifyForm;

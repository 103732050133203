import {
  ActionArea,
  ActionAreaButton,
  Modal,
  ModalContainer,
  ModalContent,
  ModalContentItem,
  ModalDescription,
  ModalHeading,
  ModalNavigation,
  typographyStyle,
} from '@wanteddev/wds';

export interface MentoringCommentDeleteModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const MentoringCommentDeleteModal = ({
  open,
  onClose,
  onDelete,
}: MentoringCommentDeleteModalProps) => {
  return (
    <Modal open={open} onOpenChange={flag => !flag && onClose()}>
      <ModalContainer size="medium" variant="popup">
        <ModalNavigation rightButton={null} />
        <ModalContent
          sx={{
            '--wds-modal-content-margin': 0,
          }}
        >
          <ModalContentItem alignItems="center">
            <ModalHeading sx={typographyStyle('body1_normal', 'bold')}>
              코멘트를 삭제하시겠습니까?
            </ModalHeading>
            <ModalDescription sx={typographyStyle('body1_normal', 'medium')}>
              코멘트를 삭제해도 로그에는 기록됩니다.
            </ModalDescription>
          </ModalContentItem>
        </ModalContent>
        <ActionArea
          priority="neutral"
          sx={{alignItems: 'center', '--wds-action-area-margin-y': '44px'}}
        >
          <ActionAreaButton variant="sub" sx={{width: 120}} onClick={onClose}>
            취소
          </ActionAreaButton>
          <ActionAreaButton variant="main" sx={{width: 120}} onClick={onDelete}>
            삭제
          </ActionAreaButton>
        </ActionArea>
      </ModalContainer>
    </Modal>
  );
};

export default MentoringCommentDeleteModal;

import Message from '@/shared/ui/message';

interface GraudateEmptyProps {
  message: string;
}

export const GraudateEmpty: React.FC<GraudateEmptyProps> = ({message}) => {
  return (
    <tr>
      <td colSpan={12} align="center">
        <Message message={message} />
      </td>
    </tr>
  );
};

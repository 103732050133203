import {Card} from '../card';

import styles from './message.module.scss';

interface MessageProps {
  message?: string | React.ReactNode;
}

const Message: React.FC<MessageProps> = ({message}) => {
  return <Card className={styles.Empty}>{message}</Card>;
};

export default Message;

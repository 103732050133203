import {lazy, Suspense} from 'react';

import {getBriefQueryKeys} from '@/pages/Dashboard/Institution/queries/useGetBriefQuery';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Card} from '@/shared/ui/card';
import {ErrorMessage} from '@/shared/ui/error-message';
import {Loading} from '@/shared/ui/loading';
import {useQueryClient} from '@tanstack/react-query';

import styles from './BriefStatistics.module.scss';

const LazyBriefStatistics = lazy(() => import('./BriefStatistics'));

export default function BriefStatistics() {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();

  const onReset = () => {
    queryClient.resetQueries({
      queryKey: getBriefQueryKeys(user.topInstitutionId),
    });
  };

  return (
    <Card className={styles.BriefStatistics}>
      <ApiErrorBoundary fallback={ErrorMessage} onReset={onReset}>
        <Suspense fallback={<Loading />}>
          <LazyBriefStatistics key={user.topInstitutionId} />
        </Suspense>
      </ApiErrorBoundary>
    </Card>
  );
}

import {Suspense, lazy} from 'react';

import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';

const DashboardLayout = lazy(() => import('./DashboardLayout'));

export function SuspensedDashboardLayout() {
  return (
    <ApiErrorBoundary>
      <Suspense fallback={<Loading absoluteCenter />}>
        <DashboardLayout />
      </Suspense>
    </ApiErrorBoundary>
  );
}

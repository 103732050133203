import classNames from 'classnames';

import styles from './GuideSection.module.scss';

const items = [
  {
    title: '나에게 맞는 기업 탐색을 위한',
    descriptions: [
      '5백만건 이상 매칭 데이터 분석 및 양질의 일자리 선별, 직군별 큐레이션 페이지를 제공합니다.',
      '주니어 TO가 살아있는 기업의 인사 및 기술 담당자들과 채용설명회를 진행합니다.',
    ],
    image: 'https://image.wanted.co.kr/eas/graduate/course/apply/image_1.png',
  },
  {
    title: '통과하는 이력서 작성을 위한',
    descriptions: [
      '프리온보딩 이력서, 면접 특강에 참여하고 경력을 뛰어넘는 이력서 작성 전략을 수립합니다.',
      '이력서 합격률 175% 상승, 원티드 인증 뱃지가 부여되어 이력서 합격 률을 높일 수 있습니다.',
    ],
    image: 'https://image.wanted.co.kr/eas/graduate/course/apply/image_2.png',
  },
  {
    title: '최종 합격에 도달하는 면접을 위한',
    descriptions: [
      'AI 모의 면접 훈련, 채용공고 별 예상 질문 제공 및 답변에 대한 상세한 피드백을 드립니다.',
      '재능 & 강점 진단 검사를 통해 스스로를 면밀하게 파악하여 면접 자신감을 높일 수 있습니다.',
    ],
    image: 'https://image.wanted.co.kr/eas/graduate/course/apply/image_3.png',
  },
  {
    title: '커리어 시작 이후의 꾸준한 성장을 위한',
    descriptions: [
      '원티드 AI Agent <커리어맵>을 통해 커리어 시작 이후의 방향성을 수립할 수 있습니다.',
      '원티드가 제공하는 다양한 교육과 커뮤니티 활동을 통해 꾸준히 커리어를 성장시킬 수 있습니다.',
    ],
    image: 'https://image.wanted.co.kr/eas/graduate/course/apply/image_4.png',
  },
];

const GuideSection = () => {
  return (
    <section className={styles.GuideSection}>
      <h2 className={styles.title}>
        이력서 지원부터 최종 합격까지,
        <br />
        단계별로 필요한 가이드를 제공해 드립니다.
      </h2>
      {items.map((item, index) => (
        <article
          key={item.title}
          className={classNames(styles.article, {
            [styles.reverse]: index % 2 === 1,
          })}
        >
          <div>
            <h3>
              <strong>{index + 1}단계.</strong>
              <br />
              {item.title}
            </h3>
            <ul>
              {item.descriptions.map(description => (
                <li key={description}>{description}</li>
              ))}
            </ul>
          </div>
          <div className={styles.image}>
            <img src={item.image} width="429" height="317" />
          </div>
        </article>
      ))}
    </section>
  );
};

export default GuideSection;

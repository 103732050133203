import {getData} from '@/shared/api/client';
import {weaver} from '@/shared/lib/weaver';
import * as Sentry from '@sentry/react';
import {
  QueryFunction,
  QueryKey,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
} from '@tanstack/react-query';

export enum InstitutionType {
  NORMAL = 'NORMAL',
  TOP_LEVEL = 'TOP_LEVEL',
}

export interface InstitutionName {
  institutionId: number;
  name: string;
  type: InstitutionType;
  isPaidSubscriptionFee: boolean;
}

export interface ManagerUser {
  oneid: string;
  wantedUserId: number;
  name: string;
  email: string;
  profileImageUrl: string;
  isAgreeUse: boolean;
  isAgreePrivacy: boolean;
  isAgreeEmail: boolean;
  isAgreeSms: boolean;
  institutionNames: InstitutionName[];
  topInstitutionId?: number;
  institutionId?: number;
  roles: InstitutionType[];
}

export const MANAGER_USER_QUERY_KEYS = ['MANAGER_USER_PROFILE'];

const findInstitution = (
  institutionNames: InstitutionName[],
  institutionId: number,
  type: InstitutionType
) =>
  institutionNames.find(({institutionId: id}) => id === institutionId) ||
  institutionNames.find(({type: t}) => t === type);

const getManagerUser: QueryFunction<ManagerUser, QueryKey> = async ({
  signal,
}) => {
  try {
    const user = await getData<ManagerUser>('/eas/manager/me', {signal});

    const lastAccessedInstitutionId = Number(
      window.localStorage.getItem('eas_last_accessed_institutionId')
    );
    const lastAccessedTopInstitutionId = Number(
      window.localStorage.getItem('eas_last_accessed_topInstitutionId')
    );

    const topInstitution = findInstitution(
      user.institutionNames,
      lastAccessedTopInstitutionId,
      InstitutionType.TOP_LEVEL
    );
    const institution = findInstitution(
      user.institutionNames,
      lastAccessedInstitutionId,
      InstitutionType.NORMAL
    );

    const roles = [topInstitution, institution]
      .filter(Boolean)
      .map(({type}) => type);

    if (user) {
      Sentry.setUser({
        ...user,
        id: String(user.wantedUserId),
        email: user.email,
        username: user.name,
      });

      weaver.setUserId(user.wantedUserId);
      weaver.setUserProperties({
        one_id: user.oneid,
        institutionId: user.institutionNames
          ?.map(item => item.institutionId)
          .join(','),
        institutionName: user.institutionNames
          ?.map(item => item.name)
          .join(','),
      });
    }

    return {
      ...user,
      topInstitutionId: topInstitution?.institutionId,
      institutionId: institution?.institutionId,
      roles,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default function useGetManagerUserSuspenseQuery(
  options?: Omit<UseSuspenseQueryOptions<ManagerUser>, 'queryKey' | 'queryFn'>
) {
  return useSuspenseQuery({
    queryKey: MANAGER_USER_QUERY_KEYS,
    queryFn: getManagerUser,
    ...options,
  });
}

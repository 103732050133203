import {useState} from 'react';

import {weaver} from '@/shared/lib/weaver';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Button} from '@/shared/ui/button';
import {Modal} from '@/shared/ui/modal';
import {isAxiosError} from 'axios';

import useCreateCourse from '../../queries/useCreateCourse';
import {parseCsv} from '../../queries/useCreateCourseFromCsv';

import styles from './CourseCSVFileUploadModal.module.scss';

type Props = React.ComponentProps<typeof Modal> & {
  file?: File;
};

const CourseCSVFileUploadModal: React.FC<Props> = ({file, open, onClose}) => {
  const {user} = useCurrentUserQuery();
  const [error, setError] = useState<string>();
  const createCourse = useCreateCourse(user.institutionId);

  const handleUpload = async () => {
    try {
      setError(undefined);
      const data = await parseCsv(file);
      await createCourse.mutateAsync(data);
      weaver.sendEvent('eas__course__csvUpload__done');
    } catch (error) {
      if (
        isAxiosError(error) &&
        error?.response?.data?.message?.includes('java.time.LocalDate')
      ) {
        setError('날짜는 YYYY-MM-DD 형식으로 작성해 주세요.');
      } else if (
        error instanceof Error &&
        error?.message?.includes(
          'variables is undefined or contains empty values'
        )
      ) {
        setError('비어 있는 값이 포함되어 있습니다.');
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick={false}
      bodyClassName={styles.CourseCSVFileUploadModal}
    >
      {(createCourse.isIdle || createCourse.isPending) && (
        <DefaultMessage file={file} />
      )}
      {createCourse.isSuccess && <SuccessMessage />}
      {createCourse.isError && <ErrorMessag error={error} />}
      <Button
        type="button"
        onClick={createCourse.isIdle ? handleUpload : onClose}
        disabled={createCourse.isPending}
      >
        확인
      </Button>
    </Modal>
  );
};

export default CourseCSVFileUploadModal;

const DefaultMessage: React.FC<{file: File}> = ({file}) => {
  return (
    <div>
      <h4 className={styles.title}>선택하신 파일이 업로드 됩니다.</h4>
      <p className={styles.fileName}>파일명 : {file?.name}</p>
    </div>
  );
};

const SuccessMessage: React.FC = () => (
  <div className={styles.completed}>
    <h4 className={styles.title}>파일이 업로드 되었습니다.</h4>
    <p className={styles.message}>
      생성된 코스 신청 페이지를 확인하고
      <br />
      링크를 공유해 보세요.
    </p>
  </div>
);

const ErrorMessag: React.FC<{error: string}> = ({error}) => {
  return (
    <div className={styles.error}>
      <h4 className={styles.title}>CSV에 오류가 있습니다. 수정해 주세요.</h4>
      <p className={styles.message}>
        {error && (
          <>
            {error} <br />
          </>
        )}
        오류 파악이 어려운 경우 원티드 담당자에게 연락해 주세요.
      </p>
    </div>
  );
};

import {Suspense} from 'react';

import NotFound from '@/pages/NotFound';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';
import {UnknownError} from '@/shared/ui/unknown-error';
import {isAxiosError} from 'axios';

import CourseApply from './CourseApply';

import type {FallbackRender} from '@sentry/react';

const ErrorFallback: FallbackRender = ({error}) => {
  if (isAxiosError(error)) {
    switch (error.response?.status) {
      case 404:
      case 400:
        return <NotFound />;
    }
  }
  return <UnknownError />;
};

export default function ExperimentSuspenseComponent() {
  return (
    <ApiErrorBoundary fallback={ErrorFallback}>
      <Suspense fallback={<Loading absoluteCenter />}>
        <CourseApply />
      </Suspense>
    </ApiErrorBoundary>
  );
}

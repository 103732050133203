import classNames from 'classnames';

import styles from './CircleCheckBox.module.scss';

type CircleCheckBoxProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLLabelElement
> & {
  checked: boolean;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

const CircleCheckBox: React.FC<CircleCheckBoxProps> = ({
  label,
  checked,
  onChange,
  disabled,
  className,
  ...props
}) => {
  return (
    <label
      className={classNames(
        styles.CircleCheckBox,
        {[styles.disabled]: disabled},
        className
      )}
      {...props}
    >
      {checked && (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="8.25"
            fill="#3366FF"
          />
          <rect
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="8.25"
            stroke="#3366FF"
            strokeWidth="1.5"
          />
          <path
            d="M13.4741 5.83893C13.7702 6.13508 13.7702 6.61523 13.4741 6.91138L8.22405 12.1614C7.9279 12.4575 7.44775 12.4575 7.1516 12.1614L4.52659 9.53638C4.23044 9.24023 4.23044 8.76008 4.52659 8.46393C4.82274 8.16778 5.30289 8.16778 5.59904 8.46393L7.68782 10.5527L12.4016 5.83893C12.6978 5.54278 13.1779 5.54278 13.4741 5.83893Z"
            fill="white"
          />
        </svg>
      )}
      {!checked && (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="8.25"
            stroke="#E1E2E4"
            strokeWidth="1.5"
          />
        </svg>
      )}
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      {label}
    </label>
  );
};

export default CircleCheckBox;

import {useEffect, useState} from 'react';

import useCurrentUserQuery from '../queries/useCurrentUserQuery';

export function useIsPaidSubscriptionFee() {
  const [isPaidSubscriptionFee, setIsPaidSubscriptionFee] = useState(false);
  const user = useCurrentUserQuery();

  useEffect(() => {
    if (user) {
      setIsPaidSubscriptionFee(user.currentInstitution.isPaidSubscriptionFee);
    }
  }, [user]);

  return isPaidSubscriptionFee;
}

import {getData} from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {
  UseQueryResult,
  QueryObserverOptions,
  useQuery,
} from '@tanstack/react-query';

export interface GraduateBrief {
  /** 수료생 고유 아이디 */
  graduateId: number;
  /** 수료생 이름 */
  graduateName: string;
  /** 수료생 연락처 */
  graduateMobile: string;
  /** 수료생 이메일주소 */
  graduateEmail: string;
  /** 이력서 지원 수 */
  applyCount: number;
  /** 이력서 합격 */
  applyPassCount: number;
  /** 이력서 합격 률 */
  applyPassRate: number;
  /** 면접 합격 수 */
  hiredCount: number;
  /** 면접 합격 률 */
  hiredRate: number;
  /** EAS 개인정보 이용약관 동의 여부 */
  isAgreePrivacy: boolean;
  /** 원티드 회원가입 유무 */
  isWantedUser: boolean;
  /** 최종합격한 기업에 사용한 HR 플랫폼 */
  usedHrPlatform: string;
  /** 최종합격 기업명 */
  companyName: string;
  /** 정보 업데이트 일자 */
  latestUpdatedDate: string;

  kreditjobId?: string;
}

interface GetGraduateBriefResponse {
  /** 코스 교육 시작일 */
  startDate: string;
  /** 코스 교육 종료일 */
  endDate: string;
  /** 취업 트래킹 종료일 */
  trackingEndDate: string;
  graduateBriefs: GraduateBrief[];
}

export function getGraduateBriefQueryKeys(
  institutionId: unknown,
  courseId?: unknown
) {
  const baseKeys = [RouteUrl.BY_GRADUATES, 'GRADUATE_BRIEF', institutionId];
  const keys = courseId ? [...baseKeys, courseId] : baseKeys;
  return keys;
}

export default function useGetGraduateBriefQuery(
  institutionId: string | number,
  courseId: string | number,
  options?: Omit<
    QueryObserverOptions<GetGraduateBriefResponse>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<GetGraduateBriefResponse> {
  return useQuery({
    queryKey: getGraduateBriefQueryKeys(institutionId, courseId),
    queryFn: ({signal}) =>
      getData(
        `/eas/dashboard/institution/${institutionId}/course/${courseId}/graduate/brief`,
        {
          signal,
        }
      ),
    ...options,
  });
}

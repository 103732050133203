import {AxiosRequestConfig} from 'axios';

import client from '../client';

import {CreateCourseGraduateCommentParams} from './createCourseGraduateComment';

export interface PutCourseGraduateCommentParams
  extends Omit<CreateCourseGraduateCommentParams, 'comment'> {
  commentId: number;
  actionType: 'EDIT' | 'DELETE';
  comment?: string;
}

const putCourseGraduateComment = async (
  {
    institutionId,
    courseId,
    graduateId,
    commentId,
    actionType,
    comment,
  }: PutCourseGraduateCommentParams,
  config?: AxiosRequestConfig
): Promise<void> => {
  await client.put(
    `/eas/dashboard/institution/${institutionId}/course/${courseId}/graduate/${graduateId}/detail/comment/${commentId}`,
    {
      actionType,
      comment,
    },
    config
  );
};

export const updateCourseGraduateComment = async (
  {
    institutionId,
    courseId,
    graduateId,
    commentId,
    comment,
  }: Omit<PutCourseGraduateCommentParams, 'actionType'>,
  config?: AxiosRequestConfig
) => {
  await putCourseGraduateComment(
    {
      institutionId,
      courseId,
      graduateId,
      commentId,
      actionType: 'EDIT',
      comment,
    },
    config
  );
};

export const deleteCourseGraduateComment = async (
  {
    institutionId,
    courseId,
    graduateId,
    commentId,
  }: Omit<PutCourseGraduateCommentParams, 'actionType' | 'comment'>,
  config?: AxiosRequestConfig
) => {
  await putCourseGraduateComment(
    {
      institutionId,
      courseId,
      graduateId,
      commentId,
      actionType: 'DELETE',
    },
    config
  );
};

import {useEffect} from 'react';

import {weaver} from '@/shared/lib/weaver';
import Message from '@/shared/ui/message';

import SuspensedBriefStatistics from './components/BriefStatistics';
import ComparisonByCourseChart from './components/ComparisonByCourseChart';
import CourseFilter from './components/CourseFilter';
import CourseMonthlyAggregateChart from './components/CourseMonthlyAggregateChart';
import {useCourseNames} from './context';

export const Courses: React.FC = () => {
  const courseNames = useCourseNames();

  useEffect(() => {
    weaver.sendEvent('eas__dashboard__view');
  }, []);

  if (!courseNames?.length) {
    return <Message message="등록된 코스가 없습니다." />;
  }

  return (
    <>
      <SuspensedBriefStatistics />
      <CourseFilter />
      <ComparisonByCourseChart />
      <CourseMonthlyAggregateChart />
    </>
  );
};

import classNames from 'classnames';
import {Link} from 'react-router-dom';

import styles from './clickable-link.module.scss';

export type ClickableLinkProps = Parameters<typeof Link>[0];

export const ClickableLink = ({
  className,
  children,
  ...props
}: ClickableLinkProps) => {
  return (
    <Link {...props} className={classNames(styles.ClickableLink, className)}>
      {children}
    </Link>
  );
};

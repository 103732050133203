interface HRPlatform extends Record<string, string | JSX.Element> {
  readonly WANTED: JSX.Element;
  readonly ETC: string;
  readonly default: string;
}

export const HR_PLATFORM: HRPlatform = {
  WANTED: (
    <svg height={10} viewBox="0 0 140 32">
      <use xlinkHref="#wanted-text-symbol" />
    </svg>
  ),
  ETC: '원티드외',
  default: '-',
};

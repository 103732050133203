import {ReactElement, Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Loading} from '@/shared/ui/loading';
import {useQueryClient} from '@tanstack/react-query';
import {Outlet} from 'react-router-dom';

import Graduate from './Graduate';
import {getGraduateBriefQueryKeys} from './queries/useGetGraduateBriefQuery';

export default function SuspenseUsers(): ReactElement {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();

  const onReset = () => {
    queryClient.resetQueries({
      queryKey: getGraduateBriefQueryKeys(user.institutionId),
    });
  };

  return (
    <ApiErrorBoundary onReset={onReset}>
      <Suspense fallback={<Loading absoluteCenter />}>
        <Graduate key={user.institutionId} />
        <Outlet />
      </Suspense>
    </ApiErrorBoundary>
  );
}

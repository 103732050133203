import {getData} from '@/shared/api/client';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

export type CourseName = {
  courseId: number;
  name: string;
  calculatedGraduateCount: number; // 수료생 수
  order?: number;
};

export const getCourseNamesQueryKeys = (institutionId: number) => [
  'COURSE_NAMES',
  institutionId,
]; // 쿼리 키

/**
 * 기관의 코스 이름 목록을 가져옵니다.
 * @param institutionId required 기관 아이디
 * @param options optional QueryObserverOptions
 * @returns QueryObserverOptions<CourseName[]>
 */
export default function useGetCourseNamesQuery(
  institutionId: number,
  options?: Omit<
    QueryObserverOptions<CourseName[], AxiosError>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery({
    queryKey: getCourseNamesQueryKeys(institutionId),
    queryFn: ({signal}) =>
      getData<CourseName[]>(
        `/eas/dashboard/institution/${institutionId}/course/name`,
        {signal}
      ).then(data =>
        data.map((course, index) => ({
          ...course,
          order: index,
        }))
      ),
    staleTime: Infinity,
    gcTime: Infinity,
    ...options,
    select(data) {
      // 데이터가 50건 이상인 경우 react-query에서 순서가 보장되지 않는 문제가 있어서 직접 정렬합니다.
      return data
        .sort((a, b) => a.order - b.order)
        .map(course => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {order, ...rest} = course;
          return rest;
        });
    },
  });
}

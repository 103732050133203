import {getData} from '@/shared/api/client';
import {useSuspenseQuery, UseSuspenseQueryOptions} from '@tanstack/react-query';

export type CourseBrief = {
  /** 선택된 코스들의 수료생 수 */
  selectedCourseGraduateCount: number;
  /** 선택된 코스 수료생들의 이력서 지원 수 */
  selectedCourseApplyCount: number;
  /** 선택된 코스 수료생들의 이력서 합격 */
  selectedCourseApplyPassCount: number;
  /** 선택된 코스 수료생들의 면접 합격 수 */
  selectedCourseHiredCount: number;
  /** 선택된 코스 수료생들의 1인당 평균 이력서 지원 수 */
  selectedCourseAverageApplyCountPerPerson: number;
  /** 선택된 코스 수료생들의 평균 이력서 합격률 */
  selectedCourseApplyPassRate: number;
  /** 선택된 코스 수료생들의 평균 면접 합격률 */
  selectedCourseInterviewPassRate: number;
  /** 전체 코스의 1인당 지원 수 */
  allCourseApplyCountPerPerson: number;
  /** 전체 코스의 이력서 합격률 */
  allCourseApplyPassRate: number;
  /** 전체 코스의 면접 합격률 */
  allCourseInterviewPassRate: number;
  /** 선택된 코스들의 구직활동자 수 */
  selectedCourseJobSeekerCount: number;
  /** 선택된 코스의 구직활동자 비율 */
  selectedCourseJobSeekerRate: number;
  /** 전체 코스의 구직활동자 수 */
  allCourseJobSeekerCount: number;
  /** 전체 코스의 구직활동자 비율 */
  allCourseJobSeekerRate: number;
  /** 전체 코스 취업자 수 */
  allCourseEmployedCount: number;
  /** 전체 코스 취업률  */
  allCourseEmployedRate: number;
  /** 선택 코스 취업자 수  */
  selectedCourseEmployedCount: number;
  /** 선택 코스 취업률   */
  selectedCourseEmployedRate: number;
};

export function createCourseBriefQueryKey(
  institutionId: number,
  courseIds?: string[]
) {
  const baseKeys = ['COURSE_BRIEF', institutionId];
  if (courseIds?.length) {
    return [
      ...baseKeys,
      ...[...courseIds].sort((a, b) => parseInt(a) - parseInt(b)),
    ];
  }
  return baseKeys;
}

export default function useGetCourseBriefSuspenseQuery(
  institutionId: number,
  courseIds?: string[],
  options?: UseSuspenseQueryOptions<CourseBrief>
) {
  return useSuspenseQuery({
    queryKey: createCourseBriefQueryKey(institutionId, courseIds),
    queryFn: ({signal}) =>
      getData<CourseBrief>(
        `/eas/dashboard/institution/${institutionId}/course/brief`,
        {
          params: {courseIds},
          signal,
        }
      ),
    ...options,
  });
}

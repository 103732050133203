import CompleteModal from '@/pages/Dashboard/CoursesManage/components/CompleteModal';
import {weaver} from '@/shared/lib/weaver';
import {Button} from '@/shared/ui/button';
import {Loading} from '@/shared/ui/loading';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {ModalTitle} from '@/shared/ui/modal-title';
import {formatPhoneNumber} from '@/shared/utils/phoneNumberUtils';
import {useToast} from '@wanteddev/wds';
import {useLocation, useNavigate} from 'react-router-dom';

import useCourseId from '../CourseApply/queries/useCourseId';
import useGetGraduateApplyQuery from '../CourseApply/queries/useGetGraduateApplyQuery';
import useGraduateApply from '../CourseApply/queries/useGraduateApplyMutate';

import styles from './CourseApplyModal.module.scss';

type CourseApplyModalProps = Partial<React.ComponentProps<typeof Modal>>;

const CourseApplyModal: React.FC<CourseApplyModalProps> = props => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const {courseId} = useCourseId();

  const {data} = useGetGraduateApplyQuery(courseId, {
    suspense: true,
    throwOnError: true,
  });

  const {mutate, isPending, isSuccess} = useGraduateApply(courseId, {
    throwOnError: false,
    onError(error) {
      if (error?.response?.data?.code === 'DUPLICATED') {
        toast({
          variant: 'warning',
          content: '이미 신청 완료했습니다.',
        });
      } else {
        console.error(error);
        toast({
          variant: 'warning',
          content: '시스템 오류가 발생하였습니다.',
        });
      }
    },
    onSuccess() {
      weaver.sendEvent('eas__registration__registerPopup__done');
    },
  });

  const handleClose = () => {
    navigate(location.pathname.replace('/apply', ''), {
      replace: true,
    });
  };

  const handleApply = () => {
    weaver.sendEvent('eas__registration__registerPopup__click');
    mutate();
  };

  if (!data) return <Loading absoluteCenter />;

  if (isSuccess) {
    return (
      <CompleteModal
        open
        title="원티드 취업지원시스템에 신청 완료되었습니다."
        content={
          <>
            {data.graduateName}님의 즐거운 커리어 여정을 위해
            <br />
            취업 상황에 맞는 도움 되는 가이드를 제공해 드리겠습니다.
          </>
        }
        onClose={handleClose}
      />
    );
  }

  return (
    <>
      <Modal open bodyClassName={styles.CourseApplyModal} {...props}>
        <ModalTitle className={styles.modalTitle}>
          아래 정보 확인 후 신청 버튼을 눌러주세요
        </ModalTitle>
        <ModalContent className={styles.modalContent}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>교육기관명</div>
            <div className={styles.itemContent}>{data.institutionName}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>코스명</div>
            <div className={styles.itemContent}>{data.courseName}</div>
          </div>
          <div className={styles.division} />
          <div className={styles.item}>
            <div className={styles.itemTitle}>이름</div>
            <div className={styles.itemContent}>{data.graduateName}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>연락처</div>
            <div className={styles.itemContent}>
              {formatPhoneNumber(data.graduateMobile)}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>이메일</div>
            <div className={styles.itemContent}>{data.graduateEmail}</div>
          </div>
        </ModalContent>
        <ModalActions className={styles.modalActions}>
          <Button size="large" variant="outlined" onClick={handleClose}>
            취소
          </Button>
          <Button size="large" disabled={isPending} onClick={handleApply}>
            신청
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default CourseApplyModal;

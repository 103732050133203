import client from '@/shared/api/client';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {useSearchParams} from 'react-router-dom';

import {getCourseListQueryKey} from './useGetCourseListQuery';

export interface CreateCourseData {
  courseName: string;
  startDate: string;
  endDate: string;
}

type CreateCourseResponse = {
  createdAt: string;
  endDate: string;
  id: number;
  institutionId: number;
  name: string;
  startDate: string;
  trackingEndDate: string;
  updatedAt: string;
}[];

const useCreateCourse = (
  institutionId: number,
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<CreateCourseResponse>,
      AxiosError<{code: string; message: string}>,
      CreateCourseData[]
    >,
    'onSuccess' | 'onMutate'
  >
) => {
  const [, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: data =>
      client.post(`/eas/dashboard/institution/${institutionId}/course`, data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: getCourseListQueryKey(institutionId),
      });
      setSearchParams(prev => {
        prev?.has('page') && prev?.get('page') && prev?.set('page', '1');
        return prev;
      });
    },
    onMutate(variables) {
      // 변수가 정의되지 않았거나 빈 변수가 있는지 확인
      const isEmpty =
        !variables ||
        variables.length === 0 ||
        variables.some(variable =>
          Object.values(variable).some(value => !value)
        );

      if (isEmpty) {
        throw new Error('variables is undefined or contains empty values');
      }
    },
    ...options,
  });
};

export default useCreateCourse;

import {RouteUrl} from '@/shared/constant';
import {weaver} from '@/shared/lib/weaver';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Button} from '@/shared/ui/button';
import {Modal} from '@/shared/ui/modal';
import {ModalActions} from '@/shared/ui/modal-actions';
import {ModalContent} from '@/shared/ui/modal-content';
import {ModalTitle} from '@/shared/ui/modal-title';
import calculateTrackingEndDate from '@/shared/utils/calculateTrackingEndDate';
import {encodeIds} from '@/shared/utils/idEncoder';
import dayjs from 'dayjs';

import useCreateCourse from '../../queries/useCreateCourse';
import CompleteModal from '../CompleteModal';

import styles from './CourseCreateModal.module.scss';

type Props = React.ComponentProps<typeof Modal> & {
  courseDetails: {
    institutionId: number;
    courseName: string;
    startDate: string;
    endDate: string;
  };
  reset: () => void;
};

const CourseCreateModal: React.FC<Props> = ({
  open,
  onClose,
  courseDetails: {courseName, startDate, endDate},
  reset,
}) => {
  const {user} = useCurrentUserQuery();
  const createCourse = useCreateCourse(user.institutionId);

  const handleSave = () => {
    createCourse.mutate([{courseName, startDate, endDate}], {
      onSuccess: () => {
        weaver.sendEvent('eas__course__create__done');
      },
    });
  };

  const handleClose = () => {
    reset();
    onClose(null);

    const {id: courseId, institutionId, createdAt} = createCourse.data.data[0];
    const code = encodeIds(
      institutionId,
      courseId,
      new Date(createdAt).getSeconds()
    );
    const url = `https://${
      window.location.host
    }${RouteUrl.GRADUATE_COURSE.replace(':code', code)}`;
    window.open(url, '_blank');
  };

  if (createCourse.isSuccess) {
    return (
      <CompleteModal
        open={open}
        onClose={handleClose}
        title="코스 생성이 완료되었습니다."
        content={
          <>
            <div>
              생성된 코스 신청 페이지를 확인하고
              <br /> 링크를 공유해 보세요.
            </div>
            <div className={styles.completeTextColor}>
              수료생들이 신청을 완료해야
              <br /> 수료생들의 취업 활동 내역을 트래킹할 수 있습니다.
            </div>
          </>
        }
      />
    );
  }

  return (
    <Modal open={open} bodyClassName={styles.CourseCreateModal}>
      <ModalTitle>
        <h2 className={styles.title}>코스 생성 정보</h2>
      </ModalTitle>
      <ModalContent className={styles.content}>
        <div className={styles.content__item}>
          <span>코스명</span>
          <span>{courseName}</span>
        </div>
        <div className={styles.content__item}>
          <span>교육 시작일</span>
          <span>{startDate}</span>
        </div>
        <div className={styles.content__item}>
          <span>교육 종료일</span>
          <span>{endDate}</span>
        </div>
        <div className={styles.content__item}>
          <span>취업 트레킹 종료일</span>
          <span>{calculateTrackingEndDate(endDate)}</span>
        </div>
        <div className={styles.content__item}>
          <span>코스 등록일</span>
          <span>{dayjs().format('YYYY-MM-DD')}</span>
        </div>
      </ModalContent>
      <ModalActions className={styles.actions}>
        <Button size="large" variant="outlined" onClick={onClose}>
          취소
        </Button>
        <Button
          size="large"
          onClick={handleSave}
          disabled={createCourse.isPending}
        >
          생성
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default CourseCreateModal;

import classNames from 'classnames';

import styles from './table-head.module.scss';

type TableHeadProps = React.HTMLAttributes<HTMLTableSectionElement>;

export const TableHead = ({children, className, ...rest}: TableHeadProps) => {
  return (
    <thead className={classNames(styles.TableHead, className)} {...rest}>
      <tr>{children}</tr>
    </thead>
  );
};

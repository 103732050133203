import React, {useEffect} from 'react';

import {weaver} from '@/shared/lib/weaver';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Outlet} from 'react-router-dom';

import PassnoteApplyHireList from './PassnoteApplyHireList';
import PassnoteApplyPassList from './PassnoteApplyPassList';
import PassnoteInfo from './PassnoteInfo';
import PassnotePaidGuide from './PassnotePaidGuide';

import styles from './Passnote.module.scss';

const Passnote: React.FC = () => {
  const {currentInstitution} = useCurrentUserQuery();

  useEffect(() => {
    weaver.sendEvent('eas__dashboard__view');
  }, []);

  if (!currentInstitution?.isPaidSubscriptionFee) {
    return <PassnotePaidGuide />;
  }

  return (
    <>
      <PassnoteInfo />

      <div className={styles.Passnote__list}>
        <PassnoteApplyPassList />
        <PassnoteApplyHireList />
      </div>

      <Outlet />
    </>
  );
};

export default Passnote;

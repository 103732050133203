import {ElementType, forwardRef, Ref} from 'react';

import capitalize from '@/shared/utils/capitalize';
import classNames from 'classnames';

import styles from './typography.module.scss';

type VariantType =
  | 'display'
  | 'title1'
  | 'title2'
  | 'heading1'
  | 'heading2'
  | 'body1'
  | 'body1-reading'
  | 'body2'
  | 'body2-reading'
  | 'label1'
  | 'label1-reading'
  | 'label2'
  | 'caption1'
  | 'caption2';

type TypographyBaseProps = {
  variant: VariantType;
  size: 'small' | 'large';
  children?: React.ReactNode;
  component?: React.ElementType | string;
  display?: 'initial' | 'inline' | 'block';
  align?: 'left' | 'right' | 'center' | 'justify';
  weight?: 'regular' | 'medium' | 'bold';
  noWrap?: boolean;
  className?: string;
};

// eslint-disable-next-line no-undef
type TypographyProps<T extends ElementType> = OverridableProps<
  T,
  TypographyBaseProps
>;

const variantMapping: Partial<Record<VariantType, string>> = {
  display: 'h1',
  title1: 'h2',
  title2: 'h3',
  heading1: 'h4',
  heading2: 'h5',
  body1: 'p',
  'body1-reading': 'p',
  body2: 'p',
  'body2-reading': 'p',
  label1: 'span',
  'label1-reading': 'span',
  label2: 'span',
  caption1: 'span',
  caption2: 'span',
};

function ForwardTypography<T extends ElementType = 'span'>(
  props: TypographyProps<T>,
  ref: Ref<unknown>
) {
  const {
    children,
    variant,
    size,
    component,
    weight = 'medium',
    noWrap = undefined,
    display = 'initial',
    align = 'inherit',
    className = undefined,
    ...other
  } = props;

  const rootClasses = classNames({
    [styles.Typography__root]: true,
    [styles[`Typography__${variant}__size${capitalize(size)}`]]: true,
    [styles[`Typography__${variant}__weight${capitalize(weight)}`]]: true,
    [styles[`Typography__weight${capitalize(weight)}`]]: true,
    [styles['Typography__noWrap']]: noWrap,
    [styles[`Typography__display${capitalize(display)}`]]:
      display !== 'initial',
    [styles[`Typography__align${capitalize(align)}`]]: align !== 'inherit',
    [className || '']: className !== undefined,
  });

  const Component = component || variantMapping[variant] || 'p';

  return (
    <Component className={rootClasses} {...other} ref={ref}>
      {children}
    </Component>
  );
}

export const Typography = forwardRef(
  ForwardTypography
) as typeof ForwardTypography;

import {forwardRef} from 'react';

import classNames from 'classnames';

import styles from './modal-content.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const ModalContent = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {children, className, ...other} = props;

  const rootClasses = classNames(styles.ModalContent__root, {
    [className || '']: className !== undefined,
  });

  return (
    <div className={rootClasses} ref={ref} {...other}>
      {children}
    </div>
  );
});

ModalContent.displayName = 'ModalContent';

import {lazy, Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {Card} from '@/shared/ui/card';
import {ErrorMessage} from '@/shared/ui/error-message';
import {useQueryClient} from '@tanstack/react-query';

import {getBusinessCourseQueryKey} from '../../queries/useGetBusinessCourseQuery';

import styles from './CourseComparisonTable.module.scss';

const LazyCourseComparisonTable = lazy(() => import('./CourseComparisonTable'));

export default function CourseComparisonTable() {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();

  const onReset = () => {
    queryClient.resetQueries({
      queryKey: getBusinessCourseQueryKey(user.topInstitutionId),
    });
  };

  return (
    <Card className={styles.container}>
      <ApiErrorBoundary fallback={ErrorMessage} onReset={onReset}>
        <Suspense fallback={<Card loading />}>
          <LazyCourseComparisonTable key={user.topInstitutionId} />
        </Suspense>
      </ApiErrorBoundary>
    </Card>
  );
}

import {useState} from 'react';

import {CourseGraduateComment} from '@/shared/api/courseGraduate/getCourseGraduateDetail';
import {Box, Divider, FlexBox, Typography} from '@wanteddev/wds';
import dayjs from 'dayjs';

import useDeleteCourseGraduateCommentMutation from '../hooks/useDeleteCourseGraduateCommentMutation';
import useUpdateCourseGraduateCommentMutation from '../hooks/useUpdateCourseGraduateCommentMutation';

import MentoringCommentDeleteModal from './MentoringCommentDeleteModal';
import MentoringCommentModifyForm from './MentoringCommentModifyForm';

interface MentoringCommentsListItemProps {
  institutionId: string;
  courseId: string;
  graduateId: string;
  comment: CourseGraduateComment;
}

const MentoringCommentsListItem = ({
  institutionId,
  courseId,
  graduateId,
  comment,
}: MentoringCommentsListItemProps) => {
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isModifyMode, setModifyMode] = useState(false);

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const mutateDeleteComment = useDeleteCourseGraduateCommentMutation(
    institutionId,
    courseId,
    graduateId
  );

  const mutateModifyComment = useUpdateCourseGraduateCommentMutation(
    institutionId,
    courseId,
    graduateId
  );

  const handleDeleteComment = async () => {
    try {
      await mutateDeleteComment.mutateAsync(comment.commentId);
      handleCloseDeleteModal();
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  };

  const handleSaveModifiedComment = async (newComment: string) => {
    if (newComment !== comment.comment) {
      try {
        await mutateModifyComment.mutateAsync({
          commentId: comment.commentId,
          comment: newComment,
        });
      } catch (error) {
        console.error('Failed to update comment:', error);
      }
    }
    setModifyMode(false);
  };

  return (
    <>
      <Box
        as="li"
        sx={{
          padding: '8px 16px',
        }}
      >
        <FlexBox gap="4px" alignItems="center">
          <Typography
            variant="caption1"
            weight="bold"
            color="palette.label.neutral"
          >
            {comment.authorName}
          </Typography>
          <Typography
            variant="caption2"
            weight="regular"
            color="palette.label.assistive"
          >
            {dayjs.utc(comment.createdAt).local().format('YYYY-MM-DD HH:mm')}
          </Typography>
          <Typography
            as="a"
            variant="caption2"
            weight="regular"
            color="palette.label.alternative"
            onClick={() => setModifyMode(true)}
            sx={{
              marginLeft: 'auto',
            }}
          >
            수정
          </Typography>
          <Divider
            vertical
            size="10px"
            sx={{
              margin: '0 4px',
            }}
          />
          <Typography
            as="a"
            variant="caption2"
            weight="regular"
            color="palette.label.alternative"
            onClick={handleOpenDeleteModal}
          >
            삭제
          </Typography>
        </FlexBox>

        {!isModifyMode && (
          <Typography
            variant="caption1"
            weight="regular"
            color="palette.label.neutral"
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
          >
            {comment.comment}
          </Typography>
        )}

        {isModifyMode && (
          <MentoringCommentModifyForm
            value={comment.comment}
            onModify={handleSaveModifiedComment}
            onCancel={() => setModifyMode(false)}
          />
        )}
      </Box>

      <MentoringCommentDeleteModal
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteComment}
      />
    </>
  );
};

export default MentoringCommentsListItem;

import {getData} from '@/shared/api/client';
import {RouteUrl} from '@/shared/constant';
import {QueryObserverOptions, useQuery} from '@tanstack/react-query';

export interface CourseSynthesisCounts {
  /** 코스 고유 아이디 */
  courseId: number;
  /** 코스명 */
  courseName: string;
  /** 등록 수료생 수 */
  graduateCount: number;
  /** 이력서 지원 수 */
  applyCount: number;
  /** 1인당 이력서 지원 수 */
  applyCountPerPerson: number;
  /** 이력서 합격 수 */
  applyPassCount: number;
  /** 이력서 합격률 */
  applyPassRate: number;
  /** 면접 합격 수 */
  interviewPassCount: number;
  /** 면접 합격률 */
  hiredRate: number;
}

export interface BusinessCourse {
  /** 교육기관 고유 아이디 */
  institutionId: number;
  /** 교육기관명 */
  institutionName: string;
  /** 사업 고유 아이디 */
  businessId: number;
  /** 사업명 */
  businessName: string;
  /** 소속 코스 수 */
  subCourseCount: number;
  /** 등록 수료생 수 */
  totalGraduateCount: number;
  /** 이력서 지원 수 */
  totalApplyCount: number;
  /** 1인당 이력서 지원 수 */
  applyCountPerPersonAverage: number;
  /** 이력서 합격 수 */
  totalApplyPassCount: number;
  /** 이력서 합격률 */
  applyPassRateAverage: number;
  /** 면접 합격 수 */
  totalInterviewPassCount: number;
  /** 면접 합격률 */
  hiredRateAverage: number;
  /** 사업 소속 코스 종합 취업활동 지표 목록 */
  courseSynthesisCounts: CourseSynthesisCounts[];
}

export function getBusinessCourseQueryKey(institutionId: number) {
  return [RouteUrl.SUPREME_HOME, 'BUSINESS_COURSE', institutionId];
}

export default function useGetBusinessCourseQuery(
  institutionId: number,
  options?: Omit<QueryObserverOptions<BusinessCourse[]>, 'queryKey'>
) {
  return useQuery({
    queryKey: getBusinessCourseQueryKey(institutionId),
    queryFn: ({signal}) =>
      getData(`/eas/dashboard/institution/${institutionId}/business/course`, {
        signal,
      }),
    ...options,
  });
}

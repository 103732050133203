const GhostBoxImage: React.FC = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="ghost-box-image"
    >
      <g opacity="0.5" clipPath="url(#clip0_56_867)">
        <path
          d="M159.225 114.646C159.368 114.57 159.405 114.382 159.302 114.258L142.421 93.9542C141.033 92.3816 139.028 91.4794 136.922 91.4794H65.0071C62.9018 91.4794 60.8965 92.3816 59.5082 93.9542L42.3071 113.515C41.6957 114.211 42.0065 115.305 42.8921 115.575L70.5024 123.999C72.7245 123.999 82.1834 124.348 82.7671 126.479C84.3763 132.377 93.5487 134.749 100.002 134.749C106.456 134.749 124.795 138.507 122.502 123.999C123.082 121.867 134.7 126.479 136.922 126.479L159.225 114.646Z"
          fill="#41454F"
        />
        <path
          d="M138.604 116.333C137.1 102.891 134.937 92.7584 134.398 87.3987C133.49 78.6077 133.663 70.9999 131.476 62.2384C127.646 46.8966 116.504 35.1985 100.248 35C83.9632 35.1985 72.8208 46.8966 68.9908 62.2384C67.178 69.4999 67.4765 78.6077 66.5687 87.3987C66.0296 92.7868 63.3665 102.92 61.8629 116.333C60.8983 124.897 61.5792 134.028 67.1681 142.309H133.327C138.916 134.057 139.597 124.925 138.632 116.333H138.604Z"
          fill="url(#paint0_radial_56_867)"
          fillOpacity="0.5"
        />
        <path
          d="M106.289 64.8937L125.223 137.917L106.009 140.329L106.289 64.8937Z"
          fill="url(#paint1_radial_56_867)"
          fillOpacity="0.2"
        />
        <path
          d="M91.245 64.6621L95.2081 139.994L75.8991 138.522L91.245 64.6621Z"
          fill="url(#paint2_radial_56_867)"
          fillOpacity="0.2"
        />
        <path
          d="M93.6209 63.2423C94.9254 63.1181 95.8985 61.897 95.7743 60.531C95.6501 59.1444 94.4699 58.1302 93.1654 58.2544C91.861 58.3786 90.8879 59.5997 91.0121 60.9657C91.1363 62.3523 92.3165 63.3664 93.6209 63.2423Z"
          fill="#20242D"
        />
        <path
          d="M107.74 63.2423C109.044 63.1181 110.017 61.897 109.893 60.531C109.769 59.1444 108.589 58.1302 107.284 58.2544C105.98 58.3786 105.007 59.5997 105.131 60.9657C105.255 62.3523 106.435 63.3664 107.74 63.2423Z"
          fill="#20242D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.113 68.8971H95.7751V67.5781H105.113V68.8971Z"
          fill="#20242D"
        />
        <path
          d="M117.386 112.805C115.701 112.805 114.257 113.992 113.915 115.64C112.604 121.993 106.982 126.764 100.248 126.764C93.5138 126.764 87.887 121.989 86.5801 115.64C86.2389 113.992 84.7947 112.805 83.1091 112.805H44.0293C42.0815 112.805 40.5 114.387 40.5 116.335V161.775C40.5 163.723 42.0815 165.305 44.0293 165.305H156.47C158.418 165.305 160 163.723 160 161.775V116.335C160 114.387 158.418 112.805 156.47 112.805H117.39H117.386Z"
          fill="url(#paint3_linear_56_867)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_56_867"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(100.498 84.7288) rotate(90) scale(83.2145 60.361)"
        >
          <stop offset="0.130989" stopColor="#60C5FF" stopOpacity="0" />
          <stop offset="0.720729" stopColor="#60C5FF" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_56_867"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(112.507 114.38) rotate(-7.1561) scale(7.54675 24.7923)"
        >
          <stop stopColor="#39CDFF" />
          <stop offset="1" stopColor="#39CDFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_56_867"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(87.4507 114.393) rotate(4.35934) scale(7.54678 24.7921)"
        >
          <stop stopColor="#39CDFF" />
          <stop offset="1" stopColor="#39CDFF" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_56_867"
          x1="100.25"
          y1="112.805"
          x2="100.25"
          y2="165.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1" stopColor="#C2C5CC" />
        </linearGradient>
        <clipPath id="clip0_56_867">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GhostBoxImage;

import useSortedData from '@/shared/hooks/useSortedData';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {EllipsisTooltip} from '@/shared/ui/ellipsis-tooltip';
import {SortButton} from '@/shared/ui/sort-button';
import {TableHead} from '@/shared/ui/table/table-head';
import dayjs from 'dayjs';
import qs from 'qs';
import {Link} from 'react-router-dom';

import GhostBoxImage from '../components/imageComponents/GhostBoxImage';
import PassnoteApplyEmpty from '../components/PassnoteApplyEmpty';
import usePassnoteApplyPassListQuery, {
  PassnoteApplyPass,
} from '../queries/usePassnoteApplyPassListQuery';

import styles from './PassnoteApplyPassList.module.scss';

const PassnoteApplyPassList: React.FC = () => {
  const {user} = useCurrentUserQuery();
  const institutionId = user?.institutionId;
  const {sort, toggleSort, getSortedData} = useSortedData<PassnoteApplyPass>(
    'applyPassTime',
    'desc'
  );

  const {data} = usePassnoteApplyPassListQuery(institutionId, {suspense: true});
  const sortedData = getSortedData(data);

  if (!data?.length) {
    return (
      <PassnoteApplyEmpty
        image={<GhostBoxImage />}
        message="이력서 합격자가 없습니다."
      />
    );
  }

  return (
    <table>
      <colgroup>
        <col width="20%" />
        <col width="40%" />
        <col width="23%" />
        <col width="17%" />
      </colgroup>
      <TableHead>
        <th>이름</th>
        <th>코스명</th>
        <th>
          <SortButton
            onClick={toggleSort}
            direction={sort}
            text="이력서 합격일"
          />
        </th>
        <th>상세</th>
      </TableHead>
      <tbody>
        {sortedData?.map(item => {
          return (
            <tr key={`${item.courseId}_${item.graduateId}_${item.applyId}`}>
              <td>
                <EllipsisTooltip content={item.graduateName} />
              </td>
              <td>
                <EllipsisTooltip content={item.courseName} />
              </td>
              <td>{dayjs(item.applyPassTime).format('YYYY.MM.DD')}</td>
              <td>
                <Link
                  to={{
                    pathname: String(item.graduateId),
                    search: qs.stringify({
                      institutionId,
                      courseId: item.courseId,
                    }),
                  }}
                  className={styles.linkButton}
                >
                  확인하기
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PassnoteApplyPassList;

import React from 'react';

import AppRoutes from './AppRoutes';
import {Provider} from './providers';

export const App: React.FC = () => {
  return (
    <Provider>
      <AppRoutes />
    </Provider>
  );
};

import {Button} from '@/shared/ui/button';

import type {FallbackRender} from '@sentry/react';

import styles from './error-message.module.scss';

export const ErrorMessage: FallbackRender = ({resetError}) => {
  return (
    <div className={styles.ErrorMessage}>
      <div>데이터를 불러오는 중에 에러가 발생했습니다.</div>
      <Button className={styles.button} size="small" onClick={resetError}>
        재시도
      </Button>
    </div>
  );
};

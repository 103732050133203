import classNames from 'classnames';

import {IconChevronDown} from '../icon';

import styles from './sort-button.module.scss';

type Props = {
  onClick: () => void;
  active?: boolean;
  direction?: 'desc' | 'asc';
  text: string;
};

export const SortButton: React.FC<Props> = ({
  text,
  onClick,
  active,
  direction,
  ...props
}) => {
  return (
    <button
      className={classNames(styles.SortButton, {
        [styles.active]: active,
        [styles.desc]: direction === 'desc',
        [styles.asc]: direction === 'asc',
      })}
      onClick={onClick}
      {...props}
    >
      {text}
      <IconChevronDown />
    </button>
  );
};

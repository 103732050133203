import {EllipsisTooltip} from '@/shared/ui/ellipsis-tooltip';

interface CompanyAndPositionProps {
  company: string;
  position: string;
  isPaid?: boolean;
}

import styles from './CompanyAndPosition.module.scss';

const CompanyAndPosition: React.FC<CompanyAndPositionProps> = ({
  company,
  position,
  isPaid,
}) => {
  const companyName =
    isPaid === false ? company.replace(/(?<=.)./g, '*') : company;
  return (
    <>
      <EllipsisTooltip content={companyName} className={styles.companyName} />
      <EllipsisTooltip content={position} />
    </>
  );
};

export default CompanyAndPosition;

import {useEffect} from 'react';

import {BASE_URL} from '@/shared/constant';
import {doOneIDlogin} from '@/shared/lib/oneId/oneId';
import {weaver} from '@/shared/lib/weaver';
import {Button} from '@/shared/ui/button';
import {Typography} from '@/shared/ui/typography';

import styles from './Login.module.scss';

export const Login: React.FC = () => {
  useEffect(() => {
    weaver.sendEvent('eas__signupLogin__view');
  }, []);

  const handleLogin = async () => {
    await weaver.sendEvent('eas__signupLogin__start');
    doOneIDlogin('manager');
  };

  return (
    <main className={styles.Login}>
      <div className={styles.Login__box}>
        <img
          alt="Wanted"
          src={`${BASE_URL}images/login-logo.svg`}
          width="78"
          height="84"
        />
        <Typography
          variant="body1"
          size="small"
          weight="bold"
          className={styles.Login__title}
        >
          원티드 취업지원시스템
        </Typography>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          onClick={handleLogin}
          className={styles.Login__loginButton}
        >
          로그인
        </Button>
      </div>
    </main>
  );
};

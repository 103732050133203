/* eslint-disable react-hooks/rules-of-hooks */
import {
  ONEID_AUTH_URL,
  ONEID_CLIENT_ID,
  SERVER_API_URL,
} from '@/shared/constant';
import {RouteUrl} from '@/shared/constant';
import qs from 'qs';

const origin = window.location.origin;

/**
 * 원아이디 로그인, 로그아웃 url 생성
 * @description https://wantedlab.atlassian.net/wiki/spaces/platform/pages/2618065102/OneID+Login+Logout+URL
 * @param path 로그인 또는 로그아웃 여부(login, logout)
 * @param param1 { redirectUrl, beforeUrl }
 * @returns 로그인 또는 로그아웃 url
 */
export const createOndIdAuthUrl = (
  path: 'login' | 'logout',
  {redirectUrl = origin, beforeUrl = window.location.href} = {}
) => {
  const queryString = qs.stringify({
    client_id: ONEID_CLIENT_ID,
    redirect_url: encodeURI(redirectUrl),
    before_url: encodeURI(beforeUrl),
    service: 'eas', // 각 서비스 정보 (kreditjob, dashboard, spotlight, …)
  });
  return `${ONEID_AUTH_URL}/${path}?${queryString}`;
};

/**
 * 원아이디 로그인 페이지로 리다이렉트
 * @param type 교육 수료생 또는 교육기관 담당자(manager, graduate)
 */
export const doOneIDlogin = (
  type: 'manager' | 'graduate',
  beforeUrl?: string
) => {
  if (beforeUrl && !beforeUrl?.startsWith('http')) {
    beforeUrl = `${origin}${beforeUrl}`;
  }

  if (type === 'graduate') {
    // 교육 수료생 로그인
    window.location.href = createOndIdAuthUrl('login', {
      redirectUrl: `${SERVER_API_URL}/eas/graduate/one_id/callback/login`,
      beforeUrl: beforeUrl || window.location.href,
    });
    return;
  }

  // 교육기관 담당자 로그인
  window.location.href = createOndIdAuthUrl('login', {
    redirectUrl: `${SERVER_API_URL}/eas/manager/one_id/callback/login`,
    beforeUrl: beforeUrl || `${origin}${RouteUrl.DASHBOARD}`,
  });
};

/**
 * 원아이디 로그아웃 페이지로 리다이렉트
 */
export const doOneIDlogout = (beforeUrl = RouteUrl.LOGIN) => {
  if (beforeUrl && !beforeUrl?.startsWith('http')) {
    beforeUrl = `${origin}${beforeUrl}`;
  }

  window.location.href = createOndIdAuthUrl('logout', {beforeUrl});
};

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import {ForwardedRef, Ref, useMemo} from 'react';

export function setRef<T>(ref: ForwardedRef<T>, value: T): void {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

export default function useMergeRefs<T>(
  refA: ForwardedRef<T>,
  refB: ForwardedRef<T>
): Ref<T> {
  return useMemo(() => {
    if (refA === null && refB === null) {
      return null;
    }
    return refValue => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}

import {useEffect} from 'react';

import {weaver} from '@/shared/lib/weaver';

import CourseListCard from './components/CourseListCard';
import CourseCreateCard from './components/CreateCourseCard';

const CourseCreate: React.FC = () => {
  useEffect(() => {
    weaver.sendEvent('eas__dashboard__view');
  }, []);

  return (
    <>
      <CourseCreateCard />
      <CourseListCard />
    </>
  );
};

export default CourseCreate;

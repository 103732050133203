import {useQueryClient} from '@tanstack/react-query';

import useGetManagerUserSuspenseQuery, {
  MANAGER_USER_QUERY_KEYS,
  ManagerUser,
} from './useGetManagerUserSuspenseQuery';

export default function useCurrentUserQuery() {
  const queryClient = useQueryClient();

  const {data: user, ...results} = useGetManagerUserSuspenseQuery({
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const updateInstitutionId = (institutionId: string | number) => {
    window.localStorage.setItem(
      'eas_last_accessed_institutionId',
      String(institutionId)
    );

    return queryClient.setQueryData<ManagerUser>(
      MANAGER_USER_QUERY_KEYS,
      prev => {
        return {
          ...prev,
          institutionId: Number(institutionId),
        };
      }
    );
  };

  const updateTopInstitutionId = (institutionId: string | number) => {
    window.localStorage.setItem(
      'eas_last_accessed_topInstitutionId',
      String(institutionId)
    );

    return queryClient.setQueryData<ManagerUser>(
      MANAGER_USER_QUERY_KEYS,
      prev => {
        return {
          ...prev,
          topInstitutionId: Number(institutionId),
        };
      }
    );
  };

  const currentInstitution = user?.institutionNames?.find(
    ({institutionId}) => institutionId === user.institutionId
  );

  return {
    ...results,
    user,
    currentInstitution,
    updateInstitutionId,
    updateTopInstitutionId,
  };
}

export const BASE_URL = import.meta.env.BASE_URL ?? '/';
export const SERVER_API_URL = import.meta.env.VITE_SERVER_API_URL;
export const ONEID_CLIENT_ID = import.meta.env.VITE_ONEID_CLIENT_ID;
export const ONEID_AUTH_URL = import.meta.env.VITE_ONEID_AUTH_URL;

export const DEFAULT_AVATAR =
  'https://static.wanted.co.kr/images/profile_default.png';

export const MODE = import.meta.env.MODE ?? 'dev';
export const SERVER_NAME = MODE.startsWith('dev') ? 'dev' : MODE;

export const AMPLITUDE_EXPERIMENT_KEY = import.meta.env
  .VITE_AMPLITUDE_EXPERIMENT_KEY;
